import React, { ComponentProps } from "react";
import { bool, object, oneOfType, string } from "prop-types";

import { CpHeader } from "../../";

export type CardHeaderProps = ComponentProps<"div">;

export const CardHeader = ({ children, ...rest }: CardHeaderProps) => {
  return <CpHeader {...rest}>{children}</CpHeader>;
};

CardHeader.propTypes = {
  border: bool,
  className: oneOfType([object, string]),
};
