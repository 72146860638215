import React from "react";
import { CpCheckbox } from "@components";
import { useController } from "react-hook-form";
import { any, object, string } from "prop-types";

export function CpCheckboxField(props) {
  const { fieldName, control, controllerOptions, rules, ...cpToggleProps } =
    props;
  const {
    field: { value, onBlur, onChange },
  } = useController({ name: fieldName, control, rules, ...controllerOptions });

  return (
    <CpCheckbox
      {...cpToggleProps}
      checked={value}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
}

CpCheckboxField.propTypes = {
  control: any.isRequired,
  controllerOptions: object,
  fieldName: string.isRequired,
  rules: object,
};
