import React, { useState } from "react";
import { useCss, a } from "kremling";
import ReactDOM from "react-dom";
import { LazyMotion, m, AnimatePresence, domAnimation } from "framer-motion";

import { durationInMillis, ModalContext } from "../cp-modal/modal.utils";
import { DrawerMount } from "./drawer.utils";
import { useClickEventStack } from "@hooks";

const transition = {
  duration: durationInMillis / 1000,
  ease: "easeInOut",
};

export function DrawerContainerComponent(props) {
  const {
    animScale,
    children,
    className,
    onAfterClose,
    onAfterOpen,
    onClose,
    show,
    position,
    styles = {},
  } = props;
  const scope = useCss(css);
  useClickEventStack(() => {}, !show);
  const [openAnimationDone, setOpenAnimationDone] = useState(false);

  return ReactDOM.createPortal(
    <LazyMotion features={domAnimation}>
      <ModalContext.Provider value={{ onClose }}>
        <AnimatePresence onExitComplete={() => onAfterClose?.()}>
          {show && (
            <m.div
              {...scope}
              className="cp-drawer-container"
              transition={transition}
              onAnimationComplete={() => {
                if (!openAnimationDone) {
                  setOpenAnimationDone(true);
                  onAfterOpen?.();
                }
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <m.div
                className={a("cp-drawer cp-card-l3").a(className)}
                transition={transition}
                style={styles}
                initial={{
                  translateX: position === "right" ? "100%" : "-100%",
                  scale: animScale,
                }}
                animate={{ translateX: "0%", scale: 1 }}
                exit={{
                  translateX: position === "right" ? "100%" : "-100%",
                  scale: animScale,
                }}
              >
                {children}
              </m.div>
              <DrawerMount />
            </m.div>
          )}
        </AnimatePresence>
      </ModalContext.Provider>
    </LazyMotion>,
    document.body,
  );
}

DrawerContainerComponent.propTypes = {};

// language=scss
const css = `
  .cp-drawer-container {
    pointer-events: none;
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100001;
    perspective: 20rem;
    transform-style: preserve-3d;
    transform: translate(0);
  }

  .cp-drawer {
    pointer-events: auto;
    position: relative;
    background-color: var(--cp-color-card-bg);
    margin: 3.2rem auto;
    z-index: 1;
    box-shadow: var(--cp-box-shadow-l3);
    transform-origin: center center;
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 3.2rem);
  }

  .cp-drawer__body {
    padding: 1.6rem;
    min-height: 6.4rem;
    overflow: auto;
    flex-grow: 1;
  }
`;
