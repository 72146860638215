import { FloatingTree } from "@floating-ui/react";
import * as React from "react";

import {
  CpFloatingMenuInner,
  CpFloatingMenuInnerProps,
} from "./cp-floating-menu-inner";
import { CpFloatingMenuItem } from "./sub-components/cp-floating-menu-item";
import { CpFloatingMenuDivider } from "./sub-components/cp-floating-menu-divider";
import { ForwardRefExoticComponent } from "react";

type CpFloatingMenuComponent = ForwardRefExoticComponent<
  CpFloatingMenuInnerProps & React.HTMLProps<HTMLButtonElement>
> & {
  Item: typeof CpFloatingMenuItem;
  Divider: typeof CpFloatingMenuDivider;
};

export const CpFloatingMenu = React.forwardRef<
  HTMLButtonElement,
  CpFloatingMenuInnerProps & React.HTMLProps<HTMLButtonElement>
>((props, ref) => {
  return (
    <FloatingTree>
      <CpFloatingMenuInner {...props} ref={ref} />
    </FloatingTree>
  );
}) as CpFloatingMenuComponent;

CpFloatingMenu.Item = CpFloatingMenuItem;
CpFloatingMenu.Divider = CpFloatingMenuDivider;

CpFloatingMenu.displayName = "Menu";
