import React, { useMemo } from "react";
import {
  array,
  bool,
  func,
  object,
  oneOf,
  oneOfType,
  string,
  number,
} from "prop-types";

import { deprecatedOneOf } from "@prop-types";
import { dropdownPositionOptions } from "../cp-dropdown/cp-dropdown.utils";
import { CpSelectInner } from "./cp-select-inner.component";
import { TriggerMultiInput } from "./triggers/trigger-multi-input.component";
import { TriggerSingle } from "./triggers/trigger-single.component";
import { ItemMulti } from "./items/item-multi.component";
import { ItemSingle } from "./items/item-single.component";
import { Group } from "./common/group.component";
import { buildData, buildGroupData, filterAlpha } from "./common/utils";
import { TriggerPills } from "./triggers/trigger-pills.component";
import { TriggerMulti } from "./triggers/trigger-multi.component";
import { Footer } from "./common/footer.component";

export function CpSelect({
  autoSelectOnSearch,
  data: _data,
  isGroupData,
  groupType = "header",
  preventKeyOpen,
  searchFilter,
  searchValue,
  clearOnSelect,
  transformData,
  value: rawValue,
  renderEmptyState,
  emptyStateAction,
  triggerRef,
  ...rest
}) {
  const isSearch = !!(searchFilter && searchValue);

  const { isMulti, valueTransformed } = useMemo(() => {
    const isMulti = Array.isArray(rawValue);
    return {
      isMulti,
      valueTransformed: isMulti
        ? transformData
          ? rawValue.map(transformData)
          : rawValue
        : rawValue && (transformData ? transformData(rawValue) : rawValue),
    };
    /* Add missing deps and verify it doesn't break: transformData (needs to be memoized or stored in a ref) */
  }, [rawValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const { data, dataMap } = useMemo(() => {
    return isGroupData
      ? buildGroupData(_data, transformData, searchFilter, searchValue)
      : buildData(_data, transformData, searchFilter, searchValue);
    /* Add missing deps and verify it doesn't break: isGroupData */
  }, [_data, searchValue, transformData, searchFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CpSelectInner
      autoSelectOnSearch={autoSelectOnSearch}
      data={data}
      dataMap={dataMap}
      isGroupData={!!isGroupData}
      groupType={groupType}
      isMulti={isMulti}
      isSearch={isSearch}
      preventKeyOpen={preventKeyOpen}
      searchFilter={searchFilter}
      searchValue={searchValue}
      clearOnSelect={clearOnSelect}
      transformData={transformData}
      value={rawValue}
      valueTransformed={valueTransformed}
      renderEmptyState={renderEmptyState}
      emptyStateAction={emptyStateAction}
      triggerRef={triggerRef}
      {...rest}
    />
  );
}

CpSelect.propTypes = {
  // CpDropdown props
  allowContentClicks: bool,
  appendTo: oneOfType([object, oneOf(["parent"])]),
  contentHeight: oneOfType([string, number]),
  contentWidth: oneOfType([number, oneOf(["sm", "md", "lg", "block", "auto"])]),
  cover: bool,
  position: deprecatedOneOf(
    dropdownPositionOptions, // valid options
    [
      "top-left",
      "top-right",
      "right-top",
      "right-bottom",
      "bottom-right",
      "bottom-left",
      "left-bottom",
      "left-top",
    ], // deprecated options
  ),
  preventOutsideClickUntilClosed: bool,

  // CpSelect props
  autoSelectOnSearch: bool,
  className: oneOfType([object, string]),
  data: array,
  disabled: bool,
  disableSelection: bool,
  insertSearch: bool,
  isGroupData: bool,
  groupType: oneOf(["header", "border"]),
  loading: bool,
  onChange: func,
  onClose: func,
  onOpen: func,
  placeholder: string,
  preventKeyOpen: bool,
  renderFooter: func,
  renderGroup: func,
  renderItem: func,
  renderTrigger: func,
  searchFilter: oneOfType([func, bool]),
  searchOnChange: func,
  searchValue: string,
  clearOnSelect: bool,
  stopPropagation: bool,
  showResultsInContent: bool,
  style: object,
  transformData: func,
  triggerIsBlock: bool,
  value: oneOfType([array, object, string, number]),

  renderEmptyState: func,
  emptyStateAction: func,
};

CpSelect.defaultProps = {
  allowContentClicks: true,
  autoSelectOnSearch: false,
  contentWidth: "lg",
  position: "bottom",
  renderGroup: Group,
  preventKeyOpen: false,
};

// triggers
CpSelect.TriggerMulti = TriggerMulti;
CpSelect.TriggerMultiInput = TriggerMultiInput;
CpSelect.TriggerSingle = TriggerSingle;
CpSelect.TriggerPills = TriggerPills;

// items
CpSelect.ItemMulti = ItemMulti;
CpSelect.ItemSingle = ItemSingle;

// group
CpSelect.Group = Group;

// footer
CpSelect.Footer = Footer;

// utils
CpSelect.filterAlpha = filterAlpha;
