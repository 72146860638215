import React from "react";
import { CpSelectMultiInput } from "@components";
import { useController } from "react-hook-form";
import { any, bool, object, string } from "prop-types";

export function CpSelectMultiInputField(props) {
  const {
    fieldName,
    control,
    controllerOptions,
    required,
    rules = {},
    ...cpInputMultiProps
  } = props;

  const mergedRules = {
    ...rules,
    validate: {
      ...rules?.validate,
    },
  };
  if (required) {
    mergedRules.validate.isRequired = (v) => {
      return v.length || "Field is required";
    };
  }

  const {
    field: { value, onBlur, onChange, ref },
    fieldState: { error },
  } = useController({
    name: fieldName,
    control,
    rules: mergedRules,
    ...controllerOptions,
  });

  return (
    <CpSelectMultiInput
      {...cpInputMultiProps}
      error={error?.message}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      triggerRef={ref}
    />
  );
}

CpSelectMultiInputField.propTypes = {
  control: any.isRequired,
  controllerOptions: object,
  fieldName: string.isRequired,
  rules: object,
  required: bool,
};
