import React from "react";
import { SelectFilter } from "./select-filter/select-filter.component";

export function BoolFilter({
  columnSchema,
  onClose,
  filterControl,
  trueLabel,
  falseLabel,
}) {
  return (
    <SelectFilter
      columnSchema={columnSchema}
      onClose={onClose}
      filterControl={filterControl}
      getSelectionOptions={() => {
        return [
          { id: "true", name: trueLabel || "True" },
          { id: "false", name: falseLabel || "False" },
        ];
      }}
      isBool
    />
  );
}
