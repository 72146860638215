import React from "react";
import styles from "./tooltip-popup.styles.css";
import { sanitize } from "./html-sanitization/html-sanitizer";

const themeMap = {
  light: {
    caretBottom: styles.lightCaretBottom,
    caretTop: styles.lightCaretTop,
    tooltip: styles.lightTooltip,
  },
  dark: {
    caretBottom: styles.caretBottom,
    caretTop: styles.caretTop,
    tooltip: styles.tooltip,
  },
};

export default class TooltipPopup extends React.Component {
  static defaultProps = {
    htmlIsTrusted: false,
  };

  state = {
    top: window.innerHeight,
    left: window.innerWidth,
    waitingForDelayTime: true,
    showAbove: this.props.showAbove || false,
  };
  componentDidMount() {
    this.setState(this.getPositionStyles());
    this.timeoutId = setTimeout(
      () => {
        this.setState({ waitingForDelayTime: false }, () => {
          this.props.tooltipShown(this.el);
        });
      },
      Number(this.props.delayTime || 0),
    );
  }
  render() {
    let theme = this.props.theme || "dark";
    if (themeMap[theme] === undefined) {
      console.warn(`${theme} is not a supported theme, defaulting to dark`);
      theme = "dark";
    }

    const targetEl = this.props.customElement;
    let caretLeft = targetEl.offsetLeft + targetEl.offsetWidth / 2 - 5;
    // default is caretMiddle, unless they pass in caretLeft or caretRight
    // (width / 2) almost centers it, but then you have to subtract 5 more because .caretTop has border left & right of 5px and this will put the tip of the caret in the middle

    if (this.props.tooltipContainer) {
      caretLeft = caretLeft + this.props.startingLeft;
    }

    if (this.el && this.props.caretRight) {
      caretLeft = this.state.left + this.el.clientWidth - 20;
    }
    if (this.props.caretLeft) {
      caretLeft = this.state.left + 10;
    }

    const style = { top: `${this.state.top}px`, left: `${this.state.left}px` };
    if (this.props.useFixedPosition) {
      style.position = "fixed";
    }

    return this.state.waitingForDelayTime ? null : (
      <div>
        {this.props.caretOnBottom || this.state.showAbove ? (
          <div
            className={themeMap[theme].caretBottom}
            style={{
              top: `${this.state.top + (this.el && this.el.clientHeight)}px`,
              left: `${caretLeft}px`,
            }}
          />
        ) : (
          <div
            className={themeMap[theme].caretTop}
            style={{ top: `${this.state.top - 5}px`, left: `${caretLeft}px` }}
          />
        )}

        <div
          className={themeMap[theme].tooltip}
          style={style}
          ref={this.handleRef}
          dangerouslySetInnerHTML={{
            __html: this.props.htmlIsTrusted
              ? this.props.html
              : sanitize(this.props.html),
          }}
        />
      </div>
    );
  }
  componentDidUpdate() {
    const newPosition = this.getPositionStyles();
    const tolerance = 3; // Num pixels to not care about updating for
    if (
      this.state.showAbove !== newPosition.showAbove ||
      Math.abs(newPosition.top - this.state.top) > tolerance ||
      Math.abs(newPosition.left - this.state.left) > tolerance
    ) {
      this.setState(newPosition);
    }
  }
  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  handleRef = (el) => {
    this.el = el;
    if (this.props.allowInteraction && !this.mouseEventsAdded) {
      this.el.addEventListener("mouseover", this.props.keepTooltipOpen);
      this.el.addEventListener("mouseleave", this.props.closeTooltipNow);
      this.mouseEventsAdded = true;
    }
  };
  getPositionStyles = () => {
    const verticalMargin = 8;

    const targetEl = this.props.customElement;

    const tooltipCenter = targetEl.offsetLeft + targetEl.offsetWidth / 2;
    let left;
    if (this.el) {
      left = tooltipCenter - this.el.offsetWidth / 2;
      const numPixelsTooFarRight =
        left + this.el.offsetWidth - window.innerWidth;
      if (numPixelsTooFarRight > 0) {
        left -= numPixelsTooFarRight;
      }
    } else {
      left = targetEl.offsetLeft;
    }
    left = Math.max(0, left + this.props.startingLeft);

    let top;
    if (this.state.showAbove) {
      // Show tooltip above target
      top = targetEl.offsetTop - verticalMargin;
      if (this.el) {
        top = top - this.el.offsetHeight;
      }
    } else {
      // Show tooltip below target
      top = targetEl.offsetTop + targetEl.offsetHeight + verticalMargin;
    }

    top += this.props.startingTop;
    if (this.props.scrollContainerRef) {
      top -= this.props.scrollContainerRef.scrollTop;
    }
    top = typeof this.props.top === "number" ? this.props.top : top;
    left = typeof this.props.left === "number" ? this.props.left : left;

    const showAbove =
      this.state.showAbove ||
      Boolean(
        this.el &&
          !this.showAbove &&
          this.el.getBoundingClientRect().bottom > window.innerHeight,
      );

    return { top, left, showAbove };
  };
}
