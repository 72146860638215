import React from "react";
import { CpButton, CpSelectSingle } from "@components";

export function FilterCondition({ options, value, onChange, onRemove }) {
  return (
    <div>
      <label className="cp-body-sm">Select a condition</label>
      <div className="cp-flex">
        <CpSelectSingle
          className="cp-mb-16 flex-1"
          data={options}
          value={value}
          onChange={onChange}
          placeholder="Select condition"
          triggerIsBlock
          contentWidth="block"
        />
        {onRemove && (
          <CpButton
            aria-label="Remove condition"
            className="cp-ml-8"
            btnType="icon"
            icon="close-small"
            onClick={onRemove}
            type="button"
          />
        )}
      </div>
    </div>
  );
}
