import React, { DragEvent, useEffect, useState } from "react";
import styles from "./header-container.styles.css";
import { m } from "kremling";
import { ColumnId } from "../types";
import { Selection } from "../hooks/use-table-selection.hook";

type HeaderContainerProps = {
  filterControl: any;
  HeaderComponent: React.ComponentType<any>;
  columnSchema: any;
  columnWidth: number;
  draggable: boolean;
  selection?: Selection;
  sticky: boolean;
  isDragTarget: boolean;
  dragStyle: string;
  onColumnWidthReset: (columnId: ColumnId) => void;
  onDragStart: (e: DragEvent<HTMLTableCellElement>, columnId: ColumnId) => void;
  onDragOver: (e: DragEvent<HTMLTableCellElement>, columnId: ColumnId) => void;
  onDrop: (e: DragEvent<HTMLTableCellElement>) => void;
  onDragEnd: (e: DragEvent<HTMLTableCellElement>) => void;
  onDrag: (e: DragEvent<HTMLTableCellElement>) => void;
  onResizeStart: (
    columnId: ColumnId,
    screenX: number,
    columnWidth: number,
  ) => void;
  onResize: (e: MouseEvent) => void;
  onResizeEnd: () => void;
  canChangeWidth: boolean;
  aggregatedWidth: number;
};

function HeaderContainerBase({
  filterControl,
  HeaderComponent,
  columnSchema,
  columnWidth,
  draggable,
  selection,
  sticky,
  isDragTarget,
  dragStyle,
  onDragStart,
  onDragOver,
  onDrop,
  onDragEnd,
  onDrag,
  onResizeStart,
  onResize,
  onResizeEnd,
  onColumnWidthReset,
  canChangeWidth,
  aggregatedWidth,
}: HeaderContainerProps) {
  const columnId = columnSchema.id;
  const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    if (isResizing) {
      const handleMouseMove = (e: MouseEvent) => {
        onResize(e);
      };

      const handleMouseUp = () => {
        onResizeEnd();
        setIsResizing(false);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);

      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };
    }
  }, [isResizing, onResize, onResizeEnd]);

  function isFilterApplied() {
    if (!filterControl) return false;
    const filter = filterControl.filters[columnSchema.id];
    const sort = filterControl.sortData[columnSchema.id];
    return !!filter || !!sort;
  }

  const canDrag = draggable && !sticky;

  return (
    <th
      style={{
        width: `${columnWidth / 10}rem`,
        left: sticky ? `${(aggregatedWidth - columnWidth) / 10}rem` : "0",
      }}
      draggable={canDrag}
      className={m(styles.draggable, canDrag)
        .m(styles.stickyHeader, !!columnSchema.sticky)
        .m(dragStyle, isDragTarget)
        .m(styles.nonStickyColumn, !sticky)
        .m(styles.filtersApplied, isFilterApplied())
        .m(styles.forceVerticalBorder, columnSchema.showVerticalBorder === true)
        .m(
          styles.forceNoVerticalBorder,
          columnSchema.showVerticalBorder === false,
        )}
      onDrag={onDrag}
      onDragStart={(e) => {
        if (sticky) return;
        e.dataTransfer.effectAllowed = "move";
        onDragStart(e, columnSchema.id);
      }}
      onDragOver={(e) => {
        e.preventDefault();
        if (!sticky && !isDragTarget) {
          onDragOver(e, columnSchema.id);
        }
      }}
      onDrop={onDrop}
      onDragEnd={onDragEnd}
    >
      <div
        className={`flex items-center ${
          columnSchema.header?.props?.align === "right" ? "justify-end" : ""
        }`}
      >
        <HeaderComponent
          columnSchema={columnSchema}
          filterControl={filterControl}
          selection={selection}
        />
      </div>
      {canChangeWidth && (
        <div
          className={styles.resizeHandle}
          onMouseDown={(e) => {
            e.preventDefault();
            setIsResizing(true);
            onResizeStart(columnId, e.screenX, columnWidth);
          }}
          onDoubleClick={() => onColumnWidthReset(columnId)}
        />
      )}
    </th>
  );
}

export const HeaderContainer = React.memo(HeaderContainerBase);
