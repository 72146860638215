// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cp-label {\n  font-size: 1.3rem;\n  display: block;\n  padding-bottom: 0.1rem;\n}\n\n.cp-label-styles__inline--PNd1h {\n  display: inline-block;\n  padding-right: 0.8rem;\n  padding-bottom: 0;\n  align-self: center;\n}\n", "",{"version":3,"sources":["webpack://src/components/cp-label/cp-label.styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[":global(.cp-label) {\n  font-size: 1.3rem;\n  display: block;\n  padding-bottom: 0.1rem;\n}\n\n.inline {\n  display: inline-block;\n  padding-right: 0.8rem;\n  padding-bottom: 0;\n  align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inline": "cp-label-styles__inline--PNd1h"
};
export default ___CSS_LOADER_EXPORT___;
