import React from "react";
import { findIndex, some } from "lodash";
import { customElementToReact } from "../custom-element-to-react.js";
import { reactToCustomElement } from "../react-to-custom-element.js";
import styles from "./cps-select.styles.css";
import { CprIcon } from "./../cps-icon/cps-icon.component";

let searchString = "";
let keyTimeout;

function nearest(element, el) {
  if (!element) return false;
  return element === el || nearest(element.parentElement, el);
}

export default class CpsSelect extends React.Component {
  constructor(props) {
    super(props);
    this.ULRef = React.createRef();
  }

  state = {
    dialogDisplayed: false,
    selectedIndex: 0,
    top: 0,
    focused: false,
    close: (e) => {
      if (!nearest(e.target, this.el)) {
        this.setState({ dialogDisplayed: false, focused: false });
      }
    },
  };

  componentWillUnmount() {
    document.body.removeEventListener("click", this.state.close);
  }

  componentDidMount() {
    document.body.addEventListener("click", this.state.close);
    let index = this.getIndex(this.props.selected);
    this.setState(() => ({
      selectedIndex: index === -1 ? 0 : index,
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.ULRef.current) {
      // if the bottom of the <UL> is beyond viewport, display up with `top: auto` and `bottom: 100%`
      const elementSpillsOver =
        this.ULRef.current.getBoundingClientRect().bottom > window.innerHeight;
      if (elementSpillsOver && !prevState.top) {
        this.setState({ top: "auto" });
      }
    } else if (prevState.top) {
      this.setState({ top: 0 });
    }

    if (prevProps.options.length !== this.props.options.length) {
      const myIndex = this.getIndex(this.props.selected);
      this.setState({ selectedIndex: myIndex < 0 ? 0 : myIndex });
    }
  }

  displayDialog = () => {
    if (this.disabled()) return;

    this.setState({ dialogDisplayed: true });
  };

  getIndex = (key) => {
    return findIndex(this.props.options, { key: key });
  };

  onKeyDown = (e) => {
    if (this.disabled()) return;

    const key = e.which;
    let selectedIndex = this.state.selectedIndex;

    if (key !== 9) {
      // tab key
      e.preventDefault();
    }

    if (key === 13) {
      // enter key
      this.selectItem(selectedIndex);
    } else if (key === 38) {
      // up key
      if (selectedIndex <= 0) {
        this.setState({ dialogDisplayed: true });
      } else {
        this.setState({
          dialogDisplayed: true,
          selectedIndex: selectedIndex - 1,
        });
      }
    } else if (key === 40) {
      // down key

      if (selectedIndex === this.props.options.length - 1) {
        this.setState({ dialogDisplayed: true });
      } else {
        this.setState({
          dialogDisplayed: true,
          selectedIndex: selectedIndex + 1,
        });
      }
    } else if (key === 27) {
      // escape key
      this.setState({ dialogDisplayed: false });
    } else {
      // all other keys
      this.highlightByText(e.which);
    }
  };

  triggerItemChange = () => {
    if (this.props.changeField) {
      this.props.changeField.call(
        null,
        this.props.options[this.state.selectedIndex].key,
        this.props.options[this.state.selectedIndex],
        this.state.selectedIndex,
      );
    }
  };

  selectItem = (index) => {
    if (this.disabled()) return;

    setTimeout(() => {
      this.setState({
        selectedIndex: index,
        focused: true,
        dialogDisplayed: false,
      });
      setTimeout(this.triggerItemChange);
    });
  };

  positionDialogAndGetTop = (options, index, maxHeight) => {
    const nonNegativeIndex =
      this.props.displayDirection === "down" ? 0 : Math.max(index, 0);
    const distanceFromEnd = options.length - nonNegativeIndex;
    const numVisibleOptions = Math.floor(maxHeight / 36);
    const numSurroundingOptions = Math.floor((numVisibleOptions - 1) / 2);

    if (
      nonNegativeIndex > numSurroundingOptions &&
      distanceFromEnd < numSurroundingOptions + 1
    ) {
      // Bottom 5
      if (options.length < numVisibleOptions) {
        // Dialog doesn't have a scroll
        return -2 + (36 * nonNegativeIndex * -1 - 10) + "px";
      } else {
        // Dialog has a scroll
        this.positionDialog(nonNegativeIndex, maxHeight);
        const start = maxHeight / -2 - 15;
        return start - (numSurroundingOptions - distanceFromEnd) * 36 + "px";
      }
    } else if (nonNegativeIndex > numSurroundingOptions) {
      // Middle
      this.positionDialog(nonNegativeIndex, maxHeight);
      return maxHeight / -2 - 0.0075 * maxHeight;
    } else {
      // Top 5
      return -1 + (36 * nonNegativeIndex * -1 - 10) + "px";
    }
  };

  positionDialog = (index, maxHeight) => {
    setTimeout(() => {
      let menuDialog = this.el.querySelector(`${styles.cpSelectMenu}`);
      if (menuDialog) {
        const dialogHeightImpact = maxHeight / 2 - 8;
        menuDialog.scrollTop = 36 * index - dialogHeightImpact;
      }
    });
  };

  focusSelect = () => {
    if (!this.state.focused) {
      this.setState({ focused: true });
    }
  };

  blur = () => {
    this.setState(
      {
        focused: false,
        displayDialog: false,
      },
      () => {
        if (this.props.blur) {
          this.props.blur.call(
            null,
            this.props.options[this.state.selectedIndex].key,
            this.props.options[this.state.selectedIndex],
            this.state.selectedIndex,
          );
        }
      },
    );
  };

  highlightByText = (charCode) => {
    searchString += String.fromCharCode(charCode);
    let i = this.getIndexFromString(searchString);

    if (i > -1) {
      this.selectItem(i);
    }

    clearTimeout(keyTimeout);

    keyTimeout = setTimeout(function () {
      searchString = "";
    }, 1000);
  };

  getIndexFromString = (searchString) => {
    searchString = searchString.toLowerCase();
    return findIndex(this.props.options, (option) => {
      return this.getViewValue(option) !== null
        ? this.getViewValue(option)?.toLowerCase()?.indexOf(searchString) === 0
        : false;
    });
  };

  getViewValue = (option) => {
    if (option.value === null || option.value === undefined) return null;
    return option.value || option;
  };

  getDialog = (dialogDisplayed, options) => {
    if (dialogDisplayed) {
      let selectedIndex = this.state.selectedIndex;
      let hasIcons = some(options, "icon");

      let optionElements = options.map((option, index) => {
        if (option.separator) {
          return (
            <li key={`separator${index}`} className={`${styles.separator}`} />
          );
        } else {
          return (
            <li
              key={option.key}
              className={selectedIndex === index ? `${styles.selected}` : ""}
              onMouseDown={this.selectItem.bind(this, index)}
            >
              <a className={styles.cpSelectRow}>
                {hasIcons && option.icon ? (
                  <div
                    className="cps-padding-right-8"
                    style={{ color: option.icon.color }}
                  >
                    <CprIcon name={option.icon.name} />
                  </div>
                ) : (
                  <div style={{ width: hasIcons ? "32px" : "0px" }} />
                )}
                <span
                  style={
                    option.value !== null ? {} : { color: "rgba(0,0,0,0)" }
                  }
                >
                  {option.value !== null ? option.value : "null"}
                </span>
              </a>
            </li>
          );
        }
      });

      setTimeout(() => {
        try {
          this.el.querySelector(".cp-select__hidden-input").focus();
        } catch (e) {
          // It is okay if the element does not exist anymore
          if (e.message.indexOf("Invariant Violation") === -1) {
            throw new Error(e.message);
          }
        }
      }, 100);

      const maxHeight = this.props.maxHeight || 400;
      const zIndex = this.props.zIndex || 1000;
      return (
        <div>
          <ul
            ref={this.ULRef}
            className={`${styles.cpSelectMenu} cps-dropdown-menu ${
              this.state.top ? `${styles.bottom}` : ""
            }`}
            style={{
              top:
                this.state.top ||
                this.positionDialogAndGetTop(options, selectedIndex, maxHeight),
              maxHeight: maxHeight + "px",
              zIndex,
            }}
          >
            {optionElements}
          </ul>
        </div>
      );
    }
  };

  disabled = () => {
    return (
      !this.props.options ||
      this.props.options.length === 0 ||
      this.props.disabled
    );
  };

  render = () => {
    const {
      options = [],
      selected,
      outerClass,
      selectClass,
      placeholder,
    } = this.props;
    let cpSelectClasses = `${styles.cpSelect}`;
    let selectedItem = options[this.getIndex(selected)];
    let hasIcons = some(options, "icon");

    if (this.disabled()) cpSelectClasses += ` ${styles.disabled}`;
    if (this.state.focused) cpSelectClasses += ` ${styles.focus}`;

    return (
      <div
        ref={(el) => {
          if (el) this.el = el;
        }}
        className={`${styles.cpSelectOuter} ${outerClass ? outerClass : ""}`}
        role="select"
      >
        <input
          className={`${styles.cpSelectHiddenInput} cp-select__hidden-input`}
          onFocus={this.focusSelect}
          onBlur={this.blur}
          onKeyDown={this.onKeyDown}
        />
        <div
          className={`${cpSelectClasses} ${selectClass ? selectClass : ""}`}
          onClick={this.displayDialog}
        >
          <div className={styles.cpSelectRow}>
            {selectedItem ? (
              <>
                {hasIcons && selectedItem.icon && (
                  <div
                    className="cps-padding-right-8"
                    style={{ color: selectedItem.icon.color || "inherit" }}
                  >
                    <CprIcon name={selectedItem.icon.name} />
                  </div>
                )}
                <div className={`${styles.cpSelectSelected}`}>
                  {selectedItem.value}
                </div>
              </>
            ) : (
              <div
                className={`${styles.cpSelectSelected}`}
                style={{
                  color: "#afafaf",
                }}
              >
                {placeholder}
              </div>
            )}
            {options.length > 0 && (
              <div className={`${styles.cpSelectIcon}`}></div>
            )}
          </div>
        </div>
        {this.getDialog(this.state.dialogDisplayed, options)}
      </div>
    );
  };
}

if (typeof window !== "undefined" && window && !window.CpsSelect)
  window.CpsSelect = CpsSelect;

const cpsSelectProps = [
  "selected",
  "options",
  "changeField",
  "blur",
  "disabled",
  "placeholder",
  "selectClass",
  "outerClass",
  "maxHeight",
  "zIndex",
  "displayDirection",
];

const customElement = reactToCustomElement(CpsSelect, {
  parentClass: HTMLElement,
  properties: cpsSelectProps,
});
customElements.define("cps-select", customElement);
export const CprSelect = customElementToReact({ name: "cps-select" });
