import React from "react";
import { m } from "kremling";
import { CpCheckbox } from "@components";

export function SelectCell({
  selection,
  resource,
  resourceIndex,
  onSelect,
  isDisabled,
}) {
  const isSelected = selection.byId[resource.id] || false;
  return (
    <CpCheckbox
      className={m("cp-table-cell-disabled-opacity", isDisabled)}
      disabled={isDisabled}
      checked={
        isDisabled
          ? false
          : selection.type === "excludes"
          ? !isSelected
          : selection.allSelected || isSelected
      }
      onChange={(value, event) => onSelect(event, resource, resourceIndex)}
    />
  );
}
