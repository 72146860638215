import React from "react";
import { bool, func, string } from "prop-types";
import { a } from "kremling";
import { CpPillButton } from "@components";

CpPillToggle.propTypes = {
  checked: bool,
  className: string,
  onChange: func,
  text: string,
};

export function CpPillToggle(props) {
  const { checked, className, onChange, text, ...rest } = props;

  return (
    <CpPillButton
      checked={checked}
      className={a(className).m("checked", checked)}
      onClick={() => onChange(!checked)}
      text={text}
      {...rest}
    />
  );
}
