// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".list-cell-styles__moreButton--8OwDI {\n  background: none;\n  border: none;\n  padding: 0;\n  margin-left: 0.4rem;\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n.list-cell-styles__dropdownContent--WT2AG {\n  display: flex;\n  flex-direction: column;\n  padding: 0.8rem;\n  padding-left: 0.2rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/cp-table/cells/list-cell/list-cell.styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,UAAU;EACV,mBAAmB;EACnB,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,oBAAoB;AACtB","sourcesContent":[".moreButton {\n  background: none;\n  border: none;\n  padding: 0;\n  margin-left: 0.4rem;\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n.dropdownContent {\n  display: flex;\n  flex-direction: column;\n  padding: 0.8rem;\n  padding-left: 0.2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"moreButton": "list-cell-styles__moreButton--8OwDI",
	"dropdownContent": "list-cell-styles__dropdownContent--WT2AG"
};
export default ___CSS_LOADER_EXPORT___;
