import React, { useState, useEffect, useMemo, useRef } from "react";
import Color from "color";
import { bool, func, number, object, string } from "prop-types";
import { useCss, k, a } from "kremling";

const defaultLightness = "45";
const defaultSaturation = "100";

export function CpColorPicker({
  color,
  format,
  monochromatic,
  onColorChange,
  onLightnessChange,
}) {
  const scope = useCss(css);

  const colorPicker = useRef();

  const [width, setWidth] = useState(0);
  const [start, setStart] = useState(0);

  useEffect(() => {
    handleResize();

    let timeoutId = null;

    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => handleResize(), 1000);
    };

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  const hslColor = useMemo(() => {
    return color
      ? Color(color)
      : Color(`hsl(0, ${defaultSaturation}%, ${defaultLightness}%)`);
  }, [color]);

  const handleResize = () => {
    const rect = colorPicker.current.getBoundingClientRect();
    setWidth(rect.width);
    setStart(rect.left);
  };

  const handleColorChange = (color) => {
    onColorChange(color[format || "hex"]().toString());
  };

  const handleLightnessChange = (lightness) => {
    onLightnessChange(lightness);
  };

  return (
    <div {...scope} ref={colorPicker} className="colorPicker">
      <ColorBar
        color={hslColor}
        monochromatic={monochromatic}
        width={width}
        onColorChange={handleColorChange}
        onLightnessChange={handleLightnessChange}
      />
      <ColorSlider
        color={hslColor}
        monochromatic={monochromatic}
        width={width}
        start={start}
        onColorChange={handleColorChange}
        onLightnessChange={handleLightnessChange}
      />
    </div>
  );
}

CpColorPicker.propTypes = {
  color: string,
  format: string,
  monochromatic: bool,
  onColorChange: func,
  onLightnessChange: func,
};

function ColorBar({
  color,
  monochromatic,
  width,
  onColorChange,
  onLightnessChange,
}) {
  const colorBar = useRef();

  const [selectedHue, selectedSaturation, selectedLightness] =
    color.hsl().color;

  const hues = !monochromatic
    ? [0, 60, 120, 180, 240, 300, 360]
    : [
        selectedHue,
        selectedHue,
        selectedHue,
        selectedHue,
        selectedHue,
        selectedHue,
        selectedHue,
      ];

  const lightnesses = monochromatic
    ? [
        100,
        (5 / 6) * 100,
        (4 / 6) * 100,
        (3 / 6) * 100,
        (2 / 6) * 100,
        (1 / 6) * 100,
        0,
      ]
    : [
        defaultLightness,
        defaultLightness,
        defaultLightness,
        defaultLightness,
        defaultLightness,
        defaultLightness,
        defaultLightness,
      ];
  const saturation =
    monochromatic && selectedSaturation === 0 ? 0 : defaultSaturation;

  const colorGradiant = [
    `hsl(${hues[0]}, ${saturation}%, ${lightnesses[0]}%) 0%`,
    `hsl(${hues[1]}, ${saturation}%, ${lightnesses[1]}%) 16%`,
    `hsl(${hues[2]}, ${saturation}%, ${lightnesses[2]}%) 33%`,
    `hsl(${hues[3]}, ${saturation}%, ${lightnesses[3]}%) 50%`,
    `hsl(${hues[4]}, ${saturation}%, ${lightnesses[4]}%) 66%`,
    `hsl(${hues[5]}, ${saturation}%, ${lightnesses[5]}%) 83%`,
    `hsl(${hues[6]}, ${saturation}%, ${lightnesses[6]}%) 100%`,
  ];

  return (
    <div
      ref={colorBar}
      style={{
        height: 0.09 * width,
        background: `linear-gradient(to right, ${colorGradiant[0]}, ${colorGradiant[1]},${colorGradiant[2]},${colorGradiant[3]},${colorGradiant[4]},${colorGradiant[5]},${colorGradiant[6]})`,
      }}
      className="bar"
      onClick={(e) => {
        const rect = colorBar.current.getBoundingClientRect();
        const position = Math.floor(
          ((e.clientX - rect.left) / rect.width) * 100,
        );
        if (monochromatic) {
          onLightnessChange(Math.floor(100 - position));
        } else {
          onColorChange(
            Color(
              `hsl(${3.6 * position}, ${saturation}%, ${selectedLightness}%)`,
            ),
          );
        }
      }}
    />
  );
}

ColorBar.propTypes = {
  color: object.isRequired,
  monochromatic: bool,
  width: number,
  onColorChange: func,
  onLightnessChange: func,
};

function ColorSlider({
  color,
  monochromatic,
  width,
  start,
  onColorChange,
  onLightnessChange,
}) {
  const [dragging, setDragging] = useState(false);

  const sliderSize = 0.16 * width;
  const height = 0.09 * width;

  const handleMouseDown = (e) => {
    e.preventDefault();
    setDragging(true);
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const sliderPosition = (e.clientX - start) / width;
    if (monochromatic) {
      const lightness = Math.floor((1 - sliderPosition) * 100);
      onLightnessChange(lightness > 100 ? 100 : lightness < 0 ? 0 : lightness);
    } else {
      const hue = Math.floor(sliderPosition * 360);
      onColorChange(
        Color(
          `hsl(${
            hue > 360 ? 359 : hue < 0 ? 0 : hue
          }, ${defaultSaturation}%, ${defaultLightness}%)`,
        ),
      );
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
    window.removeEventListener("mousemove", handleMouseMove);
    window.removeEventListener("mouseup", handleMouseUp);
  };

  const percentageLeft = useMemo(() => {
    if (monochromatic) {
      return 100 - color.hsl().color[2];
    } else {
      return Math.floor((color.hsl().hue() / 360) * 100);
    }
  }, [color, monochromatic]);

  return (
    <div
      style={{
        top: `-${sliderSize / 2 - height / 2}px`,
        left: `calc(${percentageLeft}% - ${sliderSize / 2}px)`,
        height: `${sliderSize}px`,
        width: `${sliderSize}px`,
        background: `${color.hsl().string()}`,
      }}
      className={a("slider").m("grabbing", dragging).m("grab", !dragging)}
      onMouseDown={handleMouseDown}
    />
  );
}

ColorSlider.propTypes = {
  color: object.isRequired,
  monochromatic: bool,
  width: number,
  start: number,
  onColorChange: func,
  onLightnessChange: func,
};

const css = k`
.colorPicker {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.bar {
  width: 100%;
  border-radius: 5px;
  cursor: "pointer";
}

.slider {
  border-radius: 50%;
  position: absolute;
  box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.2);
}

.grab {
  cursor: grab;
}

.grabbing {
  cursor: grabbing;
}
`;
