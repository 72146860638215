import { useState, useMemo, useCallback } from "react";

export type Selection = {
  allSelected: boolean;
  byId: Record<string | number, boolean>;
  type: "includes" | "excludes";
  length: number;
  totalSelected: number;
  toArray: () => (string | number)[];
  selectAll: () => void;
  deselectAll: () => void;
  toggleSelection: (resourceId: string | number) => void;
  selectMultiple: (resourceIds: (string | number)[]) => void;
  isSelected: (resourceId: string | number) => boolean;
};

export function useTableSelection({
  totalSize,
  disabledResourceIds = [],
}: {
  totalSize: number;
  disabledResourceIds?: (string | number)[];
}): Selection {
  const [allSelected, setAllSelected] = useState(false);
  const [selectionType, setSelectionType] = useState<"includes" | "excludes">(
    "includes",
  );
  const [selectionMap, setSelectionMap] = useState<
    Record<string | number, boolean>
  >({});

  const calcTotalSize = totalSize - disabledResourceIds.length;

  const selectAll = useCallback(() => {
    setSelectionMap({});
    setAllSelected(true);
    setSelectionType("excludes");
  }, []);

  const deselectAll = useCallback(() => {
    setSelectionMap({});
    setAllSelected(false);
    setSelectionType("includes");
  }, []);

  // Updates the selection map and resets to default state if all items are selected or deselected
  const updateSelectionMap = useCallback(
    (newSelectionMap: Record<string | number, boolean>) => {
      const checkedCount = Object.values(newSelectionMap).filter(
        (s) => !!s,
      ).length;
      if (checkedCount >= calcTotalSize) {
        selectionType === "excludes" ? deselectAll() : selectAll();
      } else if (checkedCount <= 0 && selectionType === "excludes") {
        selectAll();
      } else {
        setSelectionMap(newSelectionMap);
      }
    },
    [calcTotalSize, selectionType, selectAll, deselectAll],
  );

  const toggleSelection = useCallback(
    (resourceId: string | number) => {
      if (allSelected) {
        setAllSelected(false);
        setSelectionType("excludes");
      }

      const newValue = !selectionMap[resourceId];
      updateSelectionMap({ ...selectionMap, [resourceId]: newValue });
    },
    [allSelected, selectionMap, updateSelectionMap],
  );

  /* Adds an array of resource ids to the selection.
    Resulting behavior is determined by the selection `type`:
      includes - Adds the array of ids to the selection. If the resulting selection map is the same as the total size, then we trigger a selectAll

      excludes - Exclusion selections keep track of items being excluded from an "all selection", so to simulate "adding" items we actually remove them from the map. If the resulting selection map is empty, then we trigger a selectAll
  */
  const selectMultiple = useCallback(
    (resourceIds: (string | number)[]) => {
      const newSelection = { ...selectionMap };
      const enabledResourceIds = resourceIds.filter(
        (id) => !disabledResourceIds.includes(id),
      );
      if (selectionType === "includes") {
        enabledResourceIds.forEach((id) => (newSelection[id] = true));
      } else if (selectionType === "excludes") {
        enabledResourceIds.forEach((id) => delete newSelection[id]);
      }
      updateSelectionMap(newSelection);
    },
    [selectionType, selectionMap, updateSelectionMap, disabledResourceIds],
  );

  // Returns whether the resourceId is considered selected based on the current selectionType.
  const isSelected = useCallback(
    (resourceId: string | number) => {
      if (allSelected) {
        return true;
      } else if (selectionType === "includes") {
        return !!selectionMap[resourceId];
      } else if (selectionType === "excludes") {
        return !selectionMap[resourceId];
      } else {
        return false;
      }
    },
    [selectionMap, selectionType, allSelected],
  );

  const selection = useMemo(() => {
    const selectionLength = Object.values(selectionMap).filter(
      (s) => !!s,
    ).length;
    const totalSelected =
      selectionType === "excludes"
        ? calcTotalSize - selectionLength
        : selectionLength;

    return {
      allSelected,
      byId: selectionMap,
      type: selectionType,
      length: selectionLength, // A count of how many inclusive or exclusive selections there are
      totalSelected, // A count of how many resulting items will be selected
      toArray: () =>
        Object.entries(selectionMap)
          .filter(([, val]) => !!val)
          .map(([key]) => (isNaN(Number(key)) ? key : Number(key))),
      selectAll,
      deselectAll,
      toggleSelection,
      selectMultiple,
      isSelected,
    };
  }, [
    calcTotalSize,
    allSelected,
    selectionType,
    selectionMap,
    selectAll,
    deselectAll,
    toggleSelection,
    selectMultiple,
    isSelected,
  ]);

  return selection;
}
