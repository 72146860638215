import React from "react";
import { useCss, a } from "kremling";

import { CpCard, CpEmptyState } from "@components";

export function CpEmptyStateCard({ className, ...props }) {
  const scope = useCss(styles);
  return (
    <CpCard {...scope} className={a("cp-empty-state-card", className)}>
      <CpEmptyState {...props} />
    </CpCard>
  );
}

CpEmptyStateCard.propTypes = CpEmptyState.propTypes;

const styles = `
  .cp-empty-state-card {
    padding: 3.2rem;
    max-width: 46.4rem;
    margin-left: auto;
    margin-right: auto;
  }
`;
