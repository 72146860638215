import React, { useRef } from "react";
import { CpDropdown, CpButton } from "@components";
import styles from "./filter-header.styles.css";
import { SortFilter } from "../filters";

export function FilterHeader({ columnSchema, filterControl }) {
  const { label, filter } = columnSchema;
  const dropdownRef = useRef();
  const FilterComponent = filter?.component || SortFilter;
  const dropdownWidth = FilterComponent.dropdownWidth || 300;

  return (
    <div className="cp-flex-center cp-ellipsis">
      <div className={`cp-ellipsis ${styles.label}`}>{label}</div>
      <CpDropdown
        ref={dropdownRef}
        allowContentClicks
        contentWidth={dropdownWidth}
        renderWhenClosed={false}
        renderTrigger={({ toggle }) => (
          <CpButton
            small
            btnType="icon"
            icon="caret-small-down"
            onClick={toggle}
            className="cp-ml-8 shrink-0"
            aria-label={`Filter ${label}`}
          />
        )}
        renderContent={({ close }) => (
          <FilterComponent
            columnSchema={columnSchema}
            onClose={close}
            filterControl={filterControl}
            {...filter.props}
          />
        )}
      />
    </div>
  );
}
