// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".select-filter-styles__selectionContainer--afLaB {\n  overflow: auto;\n  max-height: 20rem;\n}\n.select-filter-styles__selectionItem--Y5juk {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-top: 0.8rem;\n  padding-bottom: 0.8rem;\n}\n.select-filter-styles__selectionItemLabel--EOUXW {\n  flex: 1;\n  padding-right: 0.8rem;\n  cursor: pointer;\n}\n.select-filter-styles__selectionItem--Y5juk:last-child {\n  padding-bottom: 0;\n}\n", "",{"version":3,"sources":["webpack://src/components/cp-table/filters/select-filter/select-filter.styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;EACnB,sBAAsB;AACxB;AACA;EACE,OAAO;EACP,qBAAqB;EACrB,eAAe;AACjB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".selectionContainer {\n  overflow: auto;\n  max-height: 20rem;\n}\n.selectionItem {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-top: 0.8rem;\n  padding-bottom: 0.8rem;\n}\n.selectionItemLabel {\n  flex: 1;\n  padding-right: 0.8rem;\n  cursor: pointer;\n}\n.selectionItem:last-child {\n  padding-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectionContainer": "select-filter-styles__selectionContainer--afLaB",
	"selectionItem": "select-filter-styles__selectionItem--Y5juk",
	"selectionItemLabel": "select-filter-styles__selectionItemLabel--EOUXW"
};
export default ___CSS_LOADER_EXPORT___;
