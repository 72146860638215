import { useRef, useState, useEffect, useCallback } from "react";

/*
 * Used within CpTable to allow for easier reordering of columns by extending the left/right edge of the drag scroll trigger.
 */
export function useEdgeScroll({
  scrollContainerRef, // The ref of the element that has the scrollbar
  leftScrollAt, // Initiates scrolling when mouse position is less than this value relative to the scroll container
  rightScrollAt, // Same as left, but relative to the right edge. So 0 would be the right edge of the scroll container
  scrollSpeed = 1, // Max scroll speed, roughly how many pixels to scroll per millisecond
}) {
  const [scrolling, setScrolling] = useState(null);
  const speedScale = useRef(0); // Used to increase speed as you approach edge
  const prevTime = useRef(null);
  const loopRequestId = useRef();

  useEffect(() => {
    if (scrolling) {
      function step(timestamp) {
        if (prevTime.current === null) {
          prevTime.current = timestamp;
        }

        // The amount of time passed since last frame in ms
        const deltaTime = timestamp - prevTime.current;
        prevTime.current = timestamp;

        // We make sure to apply the scroll in relation to the delta time in order to have consistent velocities with differing refresh rates and rendering performances
        // e.g. A 120hz display would run this twice as much compared to 60hz resulting in a much faster scroll speed
        if (deltaTime > 0) {
          const speed = scrollSpeed * speedScale.current;
          if (scrolling === "left") {
            scrollContainerRef.current.scrollLeft -= speed * deltaTime;
          } else if (scrolling === "right") {
            scrollContainerRef.current.scrollLeft += speed * deltaTime;
          }
        }

        loopRequestId.current = window.requestAnimationFrame(step);
      }

      window.requestAnimationFrame(step);
      return () => window.cancelAnimationFrame(loopRequestId.current);
    }
  }, [scrolling, scrollSpeed, scrollContainerRef]);

  const runEdgeScroller = useCallback(
    (event) => {
      if (event.clientX <= 0) return;

      const rect = scrollContainerRef.current.getBoundingClientRect();
      const relativeLeftX = event.clientX - rect.left;
      const relativeRightX = rect.right - event.clientX;

      if (relativeLeftX <= leftScrollAt) {
        setScrolling("left");
        speedScale.current = 1 - relativeLeftX / leftScrollAt;
      } else if (relativeRightX <= rightScrollAt) {
        setScrolling("right");
        speedScale.current = 1 - relativeRightX / rightScrollAt;
      } else {
        setScrolling(null);
      }
    },
    [leftScrollAt, rightScrollAt, scrollContainerRef],
  );

  const stopEdgeScroller = useCallback(() => setScrolling(null), []);

  return {
    runEdgeScroller,
    stopEdgeScroller,
  };
}
