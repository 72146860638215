import { v4 as uuid } from "uuid";

export type ClickEventStackAddResponse = { remove: () => void };

class ClickEventStack {
  __subscribers: { id: string; callback: (e: MouseEvent) => void }[] = [];

  constructor() {
    window.addEventListener("click", this.__next, true);
    window.addEventListener("contextmenu", this.__next, true);
  }

  __next = (e: MouseEvent) => {
    if (this.__subscribers.length) {
      const { callback } = this.__subscribers[this.__subscribers.length - 1];
      callback(e);
    }
  };

  add = (callback: (e: MouseEvent) => void): ClickEventStackAddResponse => {
    const id = uuid();
    this.__subscribers.push({ id, callback });
    return {
      remove: () => {
        this.__subscribers = this.__subscribers.filter((s) => s.id !== id);
      },
    };
  };
}

export const clickEventStack = new ClickEventStack();
