import React, { useMemo } from "react";
import { func, oneOf, string } from "prop-types";
import { CpArea, CpIcon } from "@components";
import styles from "./sort.styles.css";

export function Sort({ onChange, direction, dataType, ascLabel, descLabel }) {
  const label = useMemo(() => {
    const text = {};
    switch (dataType) {
      case "number":
        text.ascending = "Lowest - Highest";
        text.descending = "Highest - Lowest";
        break;
      case "date_iso":
      case "date_unix_ms":
        text.ascending = "Oldest - Newest";
        text.descending = "Newest - Oldest";
        break;
      default:
        text.ascending = "A-Z";
        text.descending = "Z-A";
    }

    if (ascLabel) text.ascending = ascLabel;
    if (descLabel) text.descending = descLabel;

    return text;
  }, [dataType, ascLabel, descLabel]);

  const isAsc = direction === "asc";
  const isDesc = direction === "desc";
  const setSortAsc = () => onChange(isAsc ? null : "asc");
  const setSortDesc = () => onChange(isDesc ? null : "desc");

  return (
    <div className={`cp-flex-column ${styles.sortContainer}`}>
      <CpArea
        className={`cp-mb-8 cp-body ${styles.row} ${
          isAsc && styles.row__selected
        }`}
        onClick={() => setSortAsc()}
      >
        <span>
          Sort <b>{label.ascending}</b>
        </span>
        {isAsc && <CpIcon name="checkmark-small" />}
      </CpArea>
      <CpArea
        className={`cp-mb-8 cp-body ${styles.row} ${
          isDesc && styles.row__selected
        }`}
        onClick={() => setSortDesc()}
      >
        <span>
          Sort <b>{label.descending}</b>
        </span>
        {isDesc && <CpIcon name="checkmark-small" />}
      </CpArea>
    </div>
  );
}

Sort.propTypes = {
  ascLabel: string,
  dataType: string,
  descLabel: string,
  direction: oneOf(["asc", "desc", null, undefined]),
  onChange: func.isRequired,
};
