import React from "react";
import styles from "./cps-button-icon.styles.css";
import { customElementToReact } from "../custom-element-to-react.js";
import { reactToCustomElement } from "../react-to-custom-element.js";
import { CprButton } from "../cps-button/cps-button.component.js";
import { CprTooltip } from "../cps-tooltip/cps-tooltip.component.js";
import { CprIcon } from "../cps-icon/cps-icon.component";
export default function CpsButtonIcon(props) {
  const button = (
    <CprButton
      actionType="tertiary"
      className={`${styles.buttonIcon} ${styles.iconPadding} ${props.customClass}`}
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {/* -- for screen reader -- */}
      {props.ariaText ? (
        <span className={styles.visuallyHidden}>{props.ariaText}</span>
      ) : null}
      {/* ----------------------- */}
      <CprIcon
        name={props.icon}
        size={props.size}
        className={props.customIconClass}
      />
    </CprButton>
  );

  if (props.tooltip) {
    return (
      <CprTooltip
        html={props.tooltip}
        htmlIsTrusted={props.htmlIsTrusted}
        tooltipContainer={props.tooltipContainer}
      >
        {button}
      </CprTooltip>
    );
  } else {
    return button;
  }
}

CpsButtonIcon.defaultProps = {
  customClass: "",
  icon: "information-large",
  size: "24",
  type: "button",
  tooltip: null,
  tooltipContainer: null,
  htmlIsTrusted: false,
  ariaText: null,
};

const elementName = "cps-button-icon";
const customElement = reactToCustomElement(CpsButtonIcon, {
  parentClass: HTMLElement,
  properties: [
    "icon",
    "onClick",
    "tooltip",
    "tooltipContainer",
    "customClass",
    "customIconClass",
    "type",
    "size",
    "htmlIsTrusted",
    "ariaText",
    "disabled",
  ],
});
customElements.define(elementName, customElement);
export const CprButtonIcon = customElementToReact({ name: elementName });
