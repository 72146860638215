import React, { useState, useMemo } from "react";
import { getTimeZones } from "@vvo/tzdb";
import { lowerCase } from "lodash";
import { func, string } from "prop-types";

import { CpSelectSingle } from "@components";
import { CpSelect } from "../cp-select/cp-select.component";

const timeZones = getTimeZones().map((tz) => ({
  name: `${tz.name} (${tz.alternativeName})`,
  value: tz.name,
}));

const initialTimezones = [
  { name: "America/Los_Angeles (Pacific Time)", value: "America/Los_Angeles" },
  { name: "America/Denver (Mountain Time)", value: "America/Denver" },
  { name: "America/Chicago (Central Time)", value: "America/Chicago" },
  { name: "America/New_York (Eastern Time)", value: "America/New_York" },
  { name: "America/Phoenix (Mountain Time)", value: "America/Phoenix" },
  { name: "America/Anchorage (Alaska Time)", value: "America/Anchorage" },
  {
    name: "Pacific/Honolulu (Hawaii-Aleutian Time)",
    value: "Pacific/Honolulu",
  },
  { name: "America/Halifax (Atlantic)", value: "America/Halifax" },
  { name: "America/St_Johns (Newfoundland Time)", value: "America/St_Johns" },
];

export function CpTimezone({
  onChange,
  value,
  contentWidth,
  position,
  disabled,
  clearable = false,
}) {
  const valObj = useMemo(() => {
    if (!value) return null;
    return timeZones.find((tz) => tz.value === value);
  }, [value]);

  const [search, setSearch] = useState("");
  return (
    <CpSelectSingle
      clearable={clearable}
      contentWidth={contentWidth}
      data={search ? timeZones : initialTimezones}
      disabled={disabled}
      onChange={(val) => onChange(val && val.value)}
      placeholder="Select time zone"
      position={position}
      searchFilter={CpSelect.filterAlpha(search, ["name", "readableName"])}
      searchOnChange={setSearch}
      searchValue={search}
      transformData={(tz) => ({
        ...tz,
        id: tz.value,
        // lowerCase allows for better searching (eg: "new york" instead of "new_york")
        readableName: `${lowerCase(tz.name)} (${tz.alternativeName})`,
      })}
      value={valObj}
    />
  );
}

CpTimezone.propTypes = {
  onChange: func.isRequired,
  value: string,
};
