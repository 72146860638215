import React from "react";
import { CpButton, CpCard } from "@components";
import { useForm } from "react-hook-form";
import { SortController } from "./common/sort/sort-controller.component";

export function SortFilter({ columnSchema, onClose, filterControl }) {
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      sort: filterControl.sortData[columnSchema.id]?.direction,
    },
  });

  function onSubmit(data) {
    filterControl.applyFilter(columnSchema.id, {
      sortDir: data.sort,
    });
    onClose();
  }

  function onClear() {
    reset({ sort: null });
  }

  return (
    <CpCard>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CpCard.Body>
          <SortController
            control={control}
            dataType={columnSchema.dataType}
            ascLabel={columnSchema.sort.ascLabel}
            descLabel={columnSchema.sort.descLabel}
          />
        </CpCard.Body>
        <CpCard.Footer>
          <div className="cp-flex-spread">
            <div className="cp-flex">
              <CpButton
                aria-label="Apply"
                btnType="primary"
                className="cp-mr-8"
                type="submit"
              >
                Apply
              </CpButton>
              <CpButton
                aria-label="Cancel"
                btnType="flat"
                onClick={onClose}
                type="button"
              >
                Cancel
              </CpButton>
            </div>
            <CpButton
              aria-label="Reset"
              btnType="flat"
              onClick={onClear}
              type="button"
            >
              Clear
            </CpButton>
          </div>
        </CpCard.Footer>
      </form>
    </CpCard>
  );
}
