import React, { useMemo } from "react";
import { useCss, k, a } from "kremling";
import { func, number, string, oneOfType, array } from "prop-types";
import { range } from "lodash";
import { CpButton, CpSelectSingle } from "@components";

CpPages.propTypes = {
  className: string,
  currentPage: number.isRequired,
  limit: oneOfType([number, string]),
  limitOptions: array,
  onChange: func.isRequired,
  onLimitChange: func,
  pagesToDisplay: number,
  totalPages: number.isRequired,
};

export function CpPages({
  className,
  currentPage,
  limit,
  limitOptions = [50, 100, 200],
  onChange,
  onLimitChange,
  pagesToDisplay = 5,
  totalPages,
}) {
  const scope = useCss(css);
  const firstPage = 1;
  const shouldCondense = totalPages > pagesToDisplay;

  const pages = useMemo(() => {
    // render all pages when not enough pages to collapse
    if (!shouldCondense) {
      if (totalPages <= firstPage) {
        return [firstPage];
      } else {
        return range(firstPage, totalPages + 1);
      }
    }

    // on the leftmost side
    if (currentPage < pagesToDisplay) {
      return range(firstPage, pagesToDisplay + 1);
    }

    // rightmost side
    if (currentPage > totalPages - pagesToDisplay + 1) {
      return range(totalPages - pagesToDisplay + 1, totalPages + 1);
    }

    // inbetween
    // how many pages to render on either side of the current page
    const offset = pagesToDisplay / 2;
    // how many pages should the current page be offset from the center
    // 1 ... 4 _5_ 6 7 ... 10 <- centerOffset = 1
    // 1 ... 4 5 _6_ 7 8 ... 10 <- centerOffset = 0
    const centerOffset = pagesToDisplay % 2 === 0 ? 1 : 0;

    return range(
      currentPage - Math.floor(offset - centerOffset),
      currentPage + Math.round(offset + centerOffset),
    );
  }, [totalPages, currentPage, pagesToDisplay, firstPage, shouldCondense]);

  const renderPageButton = (page) => {
    const isCurrent = currentPage === page;
    return (
      <button
        key={page}
        aria-label={isCurrent ? `Page ${page} selected` : `Page ${page}`}
        className={a("cp-pages_page-button")
          .m("cp-pages_page-button--current", isCurrent)
          .m("cp-wt-bold", isCurrent)
          .t("cp-body", "cp-body-sm", isCurrent)}
        onClick={() => onChange(page)}
      >
        {page}
      </button>
    );
  };

  return (
    <div {...scope} className={a("cp-pages").a(className)}>
      <span className="cps-screenreader">
        Select a page number for more results
      </span>
      <CpButton
        aria-label="Previous page"
        icon="caret-large-left"
        onClick={() => onChange(currentPage - 1)}
        disabled={currentPage - 1 < firstPage}
        small
      />
      {shouldCondense && pages[0] !== firstPage && (
        <>
          {renderPageButton(firstPage)}
          {pages[0] - 1 !== firstPage && "..."}
        </>
      )}
      <div className="cp-pages_page-buttons">{pages.map(renderPageButton)}</div>
      {shouldCondense && pages[pages.length - 1] < totalPages && (
        <>
          {pages[pages.length - 1] + 1 !== totalPages && "..."}
          {renderPageButton(totalPages)}
        </>
      )}
      <CpButton
        aria-label="Next page"
        icon="caret-large-right"
        onClick={() => onChange(currentPage + 1)}
        disabled={currentPage + 1 > totalPages}
        small
      />
      {onLimitChange && (
        <CpSelectSingle
          className="cp-ml-20"
          contentWidth="sm"
          data={limitOptions}
          transformData={(item) => ({ id: item, name: item.toString() })}
          value={limit}
          onChange={(limit) => {
            onLimitChange(limit);
          }}
          placeholder="Select limit"
        />
      )}
    </div>
  );
}

const css = k`
  .cp-pages {
    display: flex;
    align-items: center;
    color: var(--cp-color-app-secondary-text);
  }

  .cp-pages_page-buttons {
    display: flex;
    align-items: baseline;
  }

  .cp-pages_page-button {
    background: none;
    border: none;
    cursor: pointer;
    border-radius: .5rem;
  }

  .cp-pages_page-button:hover:not(:active) {
    color: var(--cp-color-button-flat-text);
  }

  .cp-pages_page-button:focus {
    color: var(--cp-color-button-flat-text);
    outline: none;
  }

  .cp-pages_page-button:focus:not(:active) {
    box-shadow: var(--cp-form-focus-state);
  }

  .cp-pages_page-button--current {
    color: var(--cp-color-button-flat-text);
  }
`;
