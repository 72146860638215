import React from "react";
import { a } from "kremling";

import { CpIcon, CpNameChip } from "@components";
import { useHighlighter } from "@hooks";
import { ItemBase } from "./item-base";

export function ItemSingle(props) {
  const { isSelected, value, item, onChange, searchValue, disableSelection } =
    props;
  const isActive = !!(item && value && item.id === value.id);
  const onClick = () => {
    if (!disableSelection) {
      onChange(item);
    }
  };

  const name = useHighlighter(item.name, searchValue);
  const subName = useHighlighter(item.subName, searchValue);

  return (
    <ItemBase
      id={item.id}
      isSelected={isSelected}
      className={a("item-single")
        .m("item-static", disableSelection)
        .m("item-single--selected", isSelected)}
      onClick={onClick}
      subHeader={item.subHeader}
    >
      <div
        className="item-single-content cp-flex-center"
        style={{ marginLeft: 16 * (item.indent || 0) }}
      >
        {item.icon && (
          <CpIcon
            className="cp-mr-8"
            name={item.icon}
            fill={
              item.disabled
                ? "var(--cp-color-app-disabled-text)"
                : item.iconColor || "var(--cp-color-app-icon)"
            }
          />
        )}
        {item.namechip && (
          <CpNameChip className="cp-mr-8" size="medium" {...item.namechip} />
        )}
        <div className="cp-ellipsis">
          {item.overline && (
            <div
              className="item-single-content__sub-name cp-ellipsis"
              style={{
                color: item.disabled ? "var(--cp-color-app-disabled-text)" : "",
              }}
            >
              {item.overline}
            </div>
          )}
          <div
            className={`item-single-content__name ${
              item.disabled ? "cp-color-app-disabled-text" : ""
            }`}
          >
            {item ? name : "—"}
          </div>
          {item.subName && (
            <div
              className="item-single-content__sub-name cp-ellipsis"
              style={{
                color: item.disabled ? "var(--cp-color-app-disabled-text)" : "",
              }}
            >
              {subName}
            </div>
          )}
        </div>
      </div>
      <div>
        {isActive && (
          <CpIcon
            name="checkmark-circle-filled-small"
            fill="var(--cps-color-success)"
          />
        )}
      </div>
    </ItemBase>
  );
}
