import React from "react";
import { CpSelectMulti } from "@components";
import { useController } from "react-hook-form";
import { any, bool, object, string } from "prop-types";

export function CpSelectMultiField(props) {
  const {
    fieldName,
    control,
    controllerOptions,
    required,
    rules,
    ...cpInputMultiProps
  } = props;
  let mRules = {};
  if (required) {
    mRules.validate = (v) => {
      return v.length || "Field is required";
    };
  }
  const mergedRules = { ...mRules, ...rules };

  const {
    field: { value, onBlur, onChange, ref },
    fieldState: { error },
  } = useController({
    name: fieldName,
    control,
    rules: mergedRules,
    ...controllerOptions,
  });

  return (
    <CpSelectMulti
      {...cpInputMultiProps}
      error={error?.message}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      triggerRef={ref}
    />
  );
}

CpSelectMultiField.propTypes = {
  control: any.isRequired,
  controllerOptions: object,
  fieldName: string.isRequired,
  rules: object,
  required: bool,
};
