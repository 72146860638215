import React, { forwardRef } from "react";
import { string, object, oneOfType, func, bool, number } from "prop-types";
import { useCss, a } from "kremling";

import { CpIcon, CpButton, CpLabel } from "@components";
import { deprecated } from "@prop-types";

import { Url } from "./components/url/url.component";
import { PhoneNumber } from "./components/phone-number/phone-number.component";
import { Email } from "./components/email/email.component";

export const CpInput = forwardRef(function CpInput(props, ref) {
  const {
    className,
    disabled,
    error,
    icon,
    id,
    isSearch,
    label,
    onChange,
    onClear,
    type = "text",
    value,
    ...inputProps
  } = props;
  const message = typeof error === "string" ? props.error : props.message;

  const scope = useCss(css);

  /**"icon" prop is deprecated, but still supported.
   * remove this line and use "isSearch" directly once migration is complete */
  const showSearchIcon =
    isSearch ||
    icon === "misc-magnifying-glass" ||
    icon === "misc-magnifying-glass-small";

  function inputOnChange(e) {
    return onChange?.(e.target.value);
  }

  return (
    <div
      {...scope}
      className={a("cp-input-container")
        .m("cp-input-container--icon", showSearchIcon)
        .m("cp-input-container--error", error)
        .m("cp-input-container--disabled", disabled)}
    >
      {label && (
        <CpLabel htmlFor={id} error={error} required={inputProps.required}>
          {label}
        </CpLabel>
      )}
      <div className="cp-input-container">
        <input
          {...inputProps}
          maxLength={inputProps.maxLength || 500}
          className={a("cp-input", className).m("cp-input--clear", onClear)}
          disabled={disabled}
          id={id}
          onChange={inputOnChange}
          ref={ref}
          type={type}
          value={value}
        />
        {message && <div className="cp-input-message">{message}</div>}
        {showSearchIcon && (
          <div className="cp-input__search-icon">
            <CpIcon name={"misc-magnifying-glass-small"} />
          </div>
        )}
        {onClear && props.value && (
          <div className="clear-button">
            <CpButton
              small
              onClick={() => onClear(props.value)}
              aria-label="Clear"
              icon="close-small"
            />
          </div>
        )}
      </div>
    </div>
  );
});

CpInput.propTypes = {
  className: oneOfType([string, object]),
  error: oneOfType([bool, string]),
  icon: deprecated(string, {
    showMessage: true,
    message:
      'Please use prop "isSearch" if you need to add a magnifying glass icon.',
  }),
  id: string,
  isSearch: bool,
  label: string,
  message: string,
  onChange: func,
  onClear: func,
  type: string,
  value: oneOfType([string, number]),
  disabled: bool,
};

// language=scss
const css = `
  .cp-input {
    height: 3.2rem;
    border-radius: var(--cp-form-border-radius);
    border: solid .1rem var(--cp-color-input-border);
    background-color: var(--cp-color-input-bg);
    font-size: 1.4rem;
    line-height: 3.2rem;
    width: 100%;
    display: block;
    padding: 0 .8rem;
    transition: box-shadow var(--cp-form-transition-duration) ease, border-color var(--cp-form-transition-duration) ease;
  }

  .cp-input:hover {
    border-color: var(--cp-color-input-hover-border);
  }

  .cp-input:focus, .cp-input:active {
    outline: none;
  }

  .cp-input:focus {
    border-color: var(--cp-color-app-primary);
    box-shadow: var(--cp-form-focus-state);
  }

  .cp-input:disabled {
    color: var(--cp-color-app-inactive-text);
    border-color: var(--cp-color-input-border);
  }

  .cp-input:disabled::placeholder {
    color: var(--cp-color-input-disabled-placeholder);
  }

  .cp-input.cp-input--clear {
    padding-right: 3.2rem;
  }

  .cp-input-container {
    position: relative;
  }

  .cp-input-container.cp-input-container--icon .cp-input {
    padding-left: 3rem;
  }

  .cp-input-container.cp-input-container--error label,
  .cp-input-container.cp-input-container--error .cp-input__required {
    color: var(--cp-color-app-error-text);
  }

  .cp-input-container.cp-input-container--error input {
    border-color: var(--cp-color-input-error-border);
  }

  .cp-input-container.cp-input-container--error input:focus {
    box-shadow: var(--cp-form-focus-state-error);
  }

  .cp-input-container.cp-input-container--error .cp-input-message {
    color: var(--cp-color-app-error-text);
  }

  .cp-input-container .cp-input__search-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: .5rem;
    color: var(--cp-color-app-icon);
    pointer-events: none;
    display: flex;
    align-items: center;
  }

  .cp-input-container--disabled .cp-input__search-icon {
    color: var(--cp-color-input-disabled-search-icon);
  }

  .cp-input-container .clear-button {
    position: absolute;
    top: 0;
    right: 0;
    color: var(--cp-color-app-icon);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: .4rem;
  }

  .cp-input-container .cp-input--error-text {
    color: var(--cp-color-app-error-text);
  }

  .cp-input-container .cp-input-message {
    color: var(--cp-color-app-secondary-text);
    font-size: 1.2rem;
    margin-top: .4rem;
  }
`;

CpInput.Url = Url;
CpInput.PhoneNumber = PhoneNumber;
CpInput.Email = Email;
