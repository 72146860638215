import React from "react";
import { CpButton, CpCard } from "@components";

export function FilterFooter({ onApply, onCancel, onClear }) {
  return (
    <CpCard.Footer>
      <div className="cp-flex-spread">
        <div className="cp-flex">
          <CpButton
            aria-label="Apply"
            btnType="primary"
            className="cp-mr-8"
            type="submit"
            onClick={onApply}
          >
            Apply
          </CpButton>
          <CpButton
            aria-label="Cancel"
            btnType="flat"
            onClick={onCancel}
            type="button"
          >
            Cancel
          </CpButton>
        </div>
        <CpButton
          aria-label="Reset"
          btnType="flat"
          onClick={onClear}
          type="button"
        >
          Clear
        </CpButton>
      </div>
    </CpCard.Footer>
  );
}
