// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cp-table-action-bar-styles__actionBar--kMOF7 {\n  height: 5.6rem;\n  background-color: var(--cp-color-table-bg);\n  padding-left: 3.2rem;\n  padding-right: 3.2rem;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-top: 0.1rem solid var(--cp-color-app-border);\n  border-bottom: 0.1rem solid var(--cp-color-app-border);\n}\n", "",{"version":3,"sources":["webpack://src/components/cp-table/sub-components/cp-table-action-bar.styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,0CAA0C;EAC1C,oBAAoB;EACpB,qBAAqB;EACrB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mDAAmD;EACnD,sDAAsD;AACxD","sourcesContent":[".actionBar {\n  height: 5.6rem;\n  background-color: var(--cp-color-table-bg);\n  padding-left: 3.2rem;\n  padding-right: 3.2rem;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-top: 0.1rem solid var(--cp-color-app-border);\n  border-bottom: 0.1rem solid var(--cp-color-app-border);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionBar": "cp-table-action-bar-styles__actionBar--kMOF7"
};
export default ___CSS_LOADER_EXPORT___;
