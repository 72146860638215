import React from "react";
import {
  func,
  string,
  array,
  bool,
  oneOf,
  oneOfType,
  object,
} from "prop-types";

import { CpSelect } from "../cp-select/cp-select.component";
import { defaultSearchFilter, filterAlpha } from "../cp-select/common/utils";
import { Scoped } from "kremling";
import styles from "./../cp-select/cp-select.styles.scss";

export function CpSelectMultiInput(props) {
  const {
    appendTo,
    className,
    data,
    disabled,
    isGroupData,
    groupType,
    loading,
    onBlur,
    onChange,
    onClose,
    onOpen,
    teamMember,
    placeholder,
    preventKeyOpen,
    preventOutsideClickUntilClosed,
    renderFooter,
    renderPill,
    searchFilter,
    searchOnChange,
    searchValue,
    clearOnSelect,
    stopPropagation,
    transformData,
    triggerRef,
    value,
    error,
  } = props;

  return (
    <Scoped css={styles}>
      <CpSelect
        appendTo={appendTo}
        className={className}
        contentWidth="block"
        data={data}
        disabled={disabled}
        isGroupData={isGroupData}
        groupType={groupType}
        loading={loading}
        onChange={onChange}
        onClose={onClose}
        onOpen={onOpen}
        preventKeyOpen={preventKeyOpen}
        preventOutsideClickUntilClosed={preventOutsideClickUntilClosed}
        renderFooter={renderFooter || CpSelect.Footer}
        renderGroup={CpSelect.Group}
        renderItem={CpSelect.ItemMulti}
        renderTrigger={(triggerProps) => (
          <CpSelect.TriggerMultiInput
            renderPill={renderPill}
            onBlur={onBlur}
            teamMember={teamMember}
            placeholder={placeholder}
            error={error}
            {...triggerProps}
          />
        )}
        searchFilter={defaultSearchFilter(searchFilter, searchValue)}
        searchOnChange={searchOnChange}
        searchValue={searchValue}
        clearOnSelect={clearOnSelect}
        showResultsInContent
        stopPropagation={stopPropagation}
        transformData={transformData}
        triggerIsBlock
        triggerRef={triggerRef}
        value={value}
      />
      {typeof error === "string" && (
        <div className="cp-select--error-text">{error}</div>
      )}
    </Scoped>
  );
}

CpSelectMultiInput.propTypes = {
  appendTo: oneOfType([object, oneOf(["parent"])]),
  preventOutsideClickUntilClosed: bool,

  className: oneOfType([string, object]),
  data: array,
  filter: func,
  isGroupData: bool,
  groupType: oneOf(["header", "border"]),
  loading: bool,
  onBlur: func,
  onChange: func.isRequired,
  onClose: func,
  onOpen: func,
  teamMember: bool,
  placeholder: string,
  preventKeyOpen: bool,
  renderFooter: func,
  renderPill: func,
  searchFilter: oneOfType([func, bool]),
  searchOnChange: func.isRequired,
  searchValue: string.isRequired,
  stopPropagation: bool,
  transformData: func,
  value: array.isRequired,
  error: oneOfType([bool, string]),
};

CpSelectMultiInput.filterAlpha = filterAlpha;
