import { parsePhoneNumber, isPossiblePhoneNumber } from "libphonenumber-js";

export function phoneNumber(number, options = {}) {
  const { defaultCountry = "US" } = options;

  if (isPossiblePhoneNumber(number, defaultCountry)) {
    return parsePhoneNumber(number, defaultCountry).formatNational();
  } else {
    return number;
  }
}
