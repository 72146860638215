import { useEffect } from "react";
import { keydownEventStack } from "@helpers";

export function useKeydownEventStack({ keys, callback, disabled = false }) {
  useEffect(() => {
    if (!disabled) {
      const listener = keydownEventStack.add({ keys, callback });
      return () => listener.remove();
    }
    /* Add missing deps: keys (verify that this won't change references per render), callback (memoize or store in ref) */
  }, [disabled]); // eslint-disable-line react-hooks/exhaustive-deps
}
