// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cps-button-icon-styles__buttonIcon--4fK6Z {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.cps-button-icon-styles__buttonIcon--4fK6Z.cps-button-icon-styles__iconPadding--CFzo3 {\n  padding: 4px;\n}\n\n/* from https://accessibility.18f.gov/hidden-content/ - license: public domain */\n.cps-button-icon-styles__visuallyHidden--23VLn {\n  border: 0;\n  clip: rect(0 0 0 0);\n  height: 1px;\n  margin: -1px;\n  overflow: hidden;\n  padding: 0;\n  position: absolute;\n  width: 1px;\n}\n", "",{"version":3,"sources":["webpack://src/custom-elements/cps-button-icon/cps-button-icon.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA,gFAAgF;AAChF;EACE,SAAS;EACT,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,UAAU;EACV,kBAAkB;EAClB,UAAU;AACZ","sourcesContent":[".buttonIcon {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.buttonIcon.iconPadding {\n  padding: 4px;\n}\n\n/* from https://accessibility.18f.gov/hidden-content/ - license: public domain */\n.visuallyHidden {\n  border: 0;\n  clip: rect(0 0 0 0);\n  height: 1px;\n  margin: -1px;\n  overflow: hidden;\n  padding: 0;\n  position: absolute;\n  width: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonIcon": "cps-button-icon-styles__buttonIcon--4fK6Z",
	"iconPadding": "cps-button-icon-styles__iconPadding--CFzo3",
	"visuallyHidden": "cps-button-icon-styles__visuallyHidden--23VLn"
};
export default ___CSS_LOADER_EXPORT___;
