import React, { useRef } from "react";
import PropTypes from "prop-types";
import { includes, intersection, isNull, isUndefined, isEmpty } from "lodash";
import { CpCheckbox, CpLoader } from "@components";

import styles from "./filter.styles.css";

const DynamicFilter = ({
  filterValues,
  filterSearchValue,
  filterSelections,
  applyFilter,
  selections,
  showSearch,
  showFilterList,
  hideSortOptions,
  searchPlaceholder,
  searching,
}) => {
  const initialSelections = useRef(selections);

  return (
    <div>
      {!hideSortOptions && (
        <li className="cps-divider cps-margin-left-16 cps-margin-right-16 cps-margin-top-4 cps-margin-bottom-4" />
      )}
      {showSearch && (
        <li className={styles.betterMargins}>
          <input
            type="text"
            value={filterSearchValue}
            className="cps-form-control"
            placeholder={`Search ${searchPlaceholder}`}
            onChange={(evt) => filterSelections(evt, !showFilterList)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </li>
      )}
      <div className={`${styles.filterMenu}`}>
        {filterValues?.length > 0 ||
        (initialSelections.current && initialSelections.current.length > 0)
          ? (filterValues?.length > 0 ? filterValues : []).map(
              (value, index) => {
                const selected = Array.isArray(value.id)
                  ? intersection(selections, value.id).length > 0
                  : includes(selections, value.id);
                return (
                  <li
                    key={`${value.id} - ${index}`}
                    className="cps-cursor-pointer"
                  >
                    <div
                      className={`${styles.spreadContents} cps-margin-left-16 cps-margin-right-16 cps-padding-top-8 cps-padding-bottom-8`}
                      onClick={(e) => applyFilter(e, value.id, true)}
                      style={{ lineHeight: "20px" }}
                    >
                      <div className="cps-ellipsis">{value.label}</div>
                      <div
                        style={{
                          minWidth: "25px",
                          margin: "-3px",
                          pointerEvents: "none",
                        }}
                      >
                        <CpCheckbox checked={selected} onChange={() => {}} />
                      </div>
                    </div>
                  </li>
                );
              },
            )
          : (isNull(filterValues) || isUndefined(filterValues)) &&
            showFilterList && <CpLoader />}
      </div>
      {showFilterList &&
        (searching ? (
          <CpLoader />
        ) : (
          isEmpty(filterValues) && (
            <div className="cps-margin-left-16 cps-margin-bottom-8">
              No search results.
            </div>
          )
        ))}
    </div>
  );
};

DynamicFilter.propTypes = {
  filterValues: PropTypes.array,
  filterSearchValue: PropTypes.string,
  filterSelections: PropTypes.func.isRequired,
  applyFilter: PropTypes.func.isRequired,
  selections: PropTypes.array,
  showSearch: PropTypes.bool,
  showFilterList: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
};

export default DynamicFilter;
