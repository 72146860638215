import React from "react";
import { m } from "kremling";
import { string, func } from "prop-types";

export function LinkCell({ href, onClick, label, isDisabled }) {
  return (
    <>
      {(href || onClick) && !isDisabled ? (
        <a href={href} onClick={onClick}>
          {label}
        </a>
      ) : (
        <span className={m("cp-table-cell-disabled", isDisabled)}>{label}</span>
      )}
    </>
  );
}

LinkCell.propTypes = {
  href: string,
  onClick: func,
  label: string.isRequired,
};
