import React, { useState, useCallback, useRef, DragEvent } from "react";
import styles from "./table-headers.styles.css";
import { m } from "kremling";
import { isEqual } from "lodash";

import { HeaderContainer } from "./headers/header-container.component";
import { FilterHeader } from "./headers/filter-header.component";
import { TextHeader } from "./headers/text-header.component";
import { Selection } from "./hooks/use-table-selection.hook";
import { useColumnWidths } from "./hooks/use-column-widths";
import {
  ColumnId,
  ColumnSchema,
  ColumnWidthMap,
  FilterControl,
  ResizeInfo,
  TableSchema,
} from "./types";

type TableHeadersProps = {
  columnWidths?: ColumnWidthMap;
  filterControl?: FilterControl;
  onColumnOrderChange?: (columnIds: ColumnId[]) => void;
  onColumnWidthsChange?: (columnWidths: ColumnWidthMap) => void;
  onDrag: (e: DragEvent<HTMLTableCellElement>) => void;
  onDragEnd: () => void;
  onResize: (columnSchema: ColumnSchema, width: number) => void;
  orderedColumns: ColumnId[];
  schema: TableSchema;
  selection?: Selection;
  verticalBorders: boolean;
};

export function TableHeaders({
  columnWidths,
  filterControl,
  onColumnOrderChange,
  onColumnWidthsChange,
  onDrag,
  onDragEnd,
  orderedColumns,
  schema,
  selection,
  verticalBorders,
  onResize: onResizeProp,
}: TableHeadersProps) {
  const [dragStartId, setDragStartId] = useState<ColumnId | null>(null);
  const [dragTargetId, setDragTargetId] = useState<ColumnId | null>(null);
  const [dragStartPos, setDragStartPos] = useState(0);
  const [dragStyle, setDragStyle] = useState("");

  const [resizeStartPos, setResizeStartPos] = useState<{
    columnId: ColumnId;
    startPos: number;
    startWidth: number;
  } | null>(null);
  const [resizing, setResizing] = useState<ResizeInfo | null>(null);

  const onHeaderDrag = useCallback(
    (e: DragEvent<HTMLTableCellElement>) => onDrag(e),
    [onDrag],
  );

  const onDragStart = useCallback((e: DragEvent, columnId: ColumnId) => {
    setDragStartId(columnId);
    setDragStartPos(e.clientX);
  }, []);

  const onDragOver = useCallback(
    (e: DragEvent, targetColumnId: ColumnId) => {
      setDragStyle(
        e.clientX - dragStartPos > 0 ? styles.dragRight : styles.dragLeft,
      );
      setDragTargetId(targetColumnId);
    },
    [dragStartPos],
  );

  const onDrop = useCallback(() => {
    if (dragStartId && dragTargetId && onColumnOrderChange) {
      const cols = [...orderedColumns];
      const fromIndex = cols.indexOf(dragStartId);
      const toIndex = cols.indexOf(dragTargetId);
      cols.splice(fromIndex, 1);
      cols.splice(toIndex, 0, dragStartId);
      if (!isEqual(cols, orderedColumns)) {
        onColumnOrderChange(cols);
      }
    }
  }, [orderedColumns, dragStartId, dragTargetId, onColumnOrderChange]);

  const onHeaderDragEnd = useCallback(() => {
    setDragStartId(null);
    setDragTargetId(null);
    setDragStyle("");
    onDragEnd?.();
  }, [onDragEnd]);

  const onColumnWidthsChangeRef = useRef(onColumnWidthsChange);
  onColumnWidthsChangeRef.current = onColumnWidthsChange;

  const onWidthChange = useCallback(
    (columnId: ColumnId, width: number) => {
      const newWidths = {
        ...columnWidths,
        [columnId]: width,
      };
      if (!width) {
        delete newWidths[columnId];
      }
      onColumnWidthsChangeRef.current?.(newWidths);
    },
    [columnWidths],
  );

  const onColumnWidthReset = useCallback(
    (columnId: ColumnId) => {
      onWidthChange(columnId, 0);
    },
    [onWidthChange],
  );

  const onResizeStart = useCallback(
    (columnId: ColumnId, startPos: number, startWidth: number) => {
      setResizeStartPos({ columnId, startPos, startWidth });
    },
    [],
  );

  const onResize = useCallback(
    (e: MouseEvent) => {
      if (resizeStartPos) {
        const { columnId, startPos, startWidth } = resizeStartPos;
        const newWidth = startWidth + (e.screenX - startPos);
        setResizing({
          columnId,
          width: newWidth,
        });
        onResizeProp(schema[columnId], newWidth);
      }
    },
    [resizeStartPos, schema, onResizeProp],
  );

  const onResizeEnd = useCallback(() => {
    if (resizing) {
      onWidthChange(resizing.columnId, resizing.width);
      setResizing(null);
      setResizeStartPos(null);
    }
  }, [resizing, onWidthChange]);

  function getDefaultHeader(columnSchema: any) {
    return filterControl &&
      (columnSchema.filter !== false || columnSchema.sort !== false)
      ? FilterHeader
      : TextHeader;
  }

  const { aggregateWidth } = useColumnWidths();

  return (
    <thead
      className={m(styles.isDragging, !!dragStartId)
        .a(styles.tableHeader)
        .m(styles.verticalBorder, verticalBorders)}
    >
      <tr>
        {orderedColumns.map((columnId) => {
          const columnSchema = schema[columnId];
          if (!columnSchema || (columnId === "select" && !selection))
            return null;
          const HeaderComponent =
            columnSchema.header?.component || getDefaultHeader(columnSchema);

          const { columnWidth, aggregatedWidth } = aggregateWidth({
            columnSchema,
            columnWidths,
            resizingWidth:
              resizing?.columnId === columnId ? resizing.width : undefined,
          });

          return (
            <HeaderContainer
              key={columnId}
              filterControl={filterControl}
              HeaderComponent={HeaderComponent}
              columnSchema={columnSchema}
              columnWidth={columnWidth}
              draggable={!!onColumnOrderChange}
              selection={selection}
              sticky={!!columnSchema.sticky}
              isDragTarget={columnId === dragTargetId}
              dragStyle={dragStyle}
              onColumnWidthReset={onColumnWidthReset}
              onDragStart={onDragStart}
              onDragOver={onDragOver}
              onDrop={onDrop}
              onDragEnd={onHeaderDragEnd}
              onDrag={onHeaderDrag}
              onResizeStart={onResizeStart}
              onResize={onResize}
              onResizeEnd={onResizeEnd}
              canChangeWidth={columnSchema.resizable ?? verticalBorders}
              aggregatedWidth={aggregatedWidth}
            />
          );
        })}
      </tr>
    </thead>
  );
}
