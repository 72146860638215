import React from "react";
import {
  func,
  string,
  array,
  bool,
  oneOf,
  oneOfType,
  object,
} from "prop-types";

import { CpSelect } from "../cp-select/cp-select.component";
import { defaultSearchFilter, filterAlpha } from "../cp-select/common/utils";
import styles from "./../cp-select/cp-select.styles.scss";
import { Scoped } from "kremling";

export function CpSelectMulti(props) {
  const {
    appendTo,
    className,
    contentWidth,
    data,
    disabled,
    error,
    insertSearch,
    isGroupData,
    groupType,
    loading,
    onBlur,
    onChange,
    onClose,
    onOpen,
    placeholder = "Select",
    preventKeyOpen,
    preventOutsideClickUntilClosed,
    renderFooter,
    renderTrigger,
    renderValue,
    searchFilter,
    searchOnChange,
    searchValue,
    clearOnSelect,
    stopPropagation,
    transformData,
    triggerIsBlock,
    triggerRef,
    value,
  } = props;
  return (
    <Scoped css={styles}>
      <CpSelect
        appendTo={appendTo}
        className={className}
        contentWidth={contentWidth}
        data={data}
        disabled={disabled}
        insertSearch={insertSearch}
        isGroupData={isGroupData}
        groupType={groupType}
        loading={loading}
        onChange={onChange}
        onClose={onClose}
        onOpen={onOpen}
        preventKeyOpen={preventKeyOpen}
        preventOutsideClickUntilClosed={preventOutsideClickUntilClosed}
        renderFooter={renderFooter || CpSelect.Footer}
        renderGroup={CpSelect.Group}
        renderItem={CpSelect.ItemMulti}
        renderTrigger={
          renderTrigger ||
          ((triggerProps) => (
            <CpSelect.TriggerMulti
              {...triggerProps}
              onBlur={onBlur}
              placeholder={placeholder}
              renderValue={renderValue}
              error={error}
            />
          ))
        }
        searchFilter={defaultSearchFilter(searchFilter, searchValue)}
        searchOnChange={searchOnChange}
        searchValue={searchValue}
        clearOnSelect={clearOnSelect}
        showResultsInContent
        stopPropagation={stopPropagation}
        transformData={transformData}
        triggerIsBlock={triggerIsBlock}
        triggerRef={triggerRef}
        value={value}
      />
      {typeof error === "string" && (
        <div className="cp-select--error-text">{error}</div>
      )}
    </Scoped>
  );
}

CpSelectMulti.propTypes = {
  appendTo: oneOfType([object, oneOf(["parent"])]),
  preventOutsideClickUntilClosed: bool,

  className: oneOfType([string, object]),
  data: array,
  error: oneOfType([bool, string]),
  filter: func,
  insertSearch: bool,
  isGroupData: bool,
  groupType: oneOf(["header", "border"]),
  loading: bool,
  onBlur: func,
  onChange: func.isRequired,
  onClose: func,
  onOpen: func,
  placeholder: string,
  preventKeyOpen: bool,
  renderFooter: func,
  renderTrigger: func,
  renderValue: func,
  searchOnChange: func,
  searchValue: string,
  stopPropagation: bool,
  transformData: func,
  triggerIsBlock: bool,
  value: array.isRequired,
};

CpSelectMulti.filterAlpha = filterAlpha;
