import React, { FC, useState } from "react";
import { useCss, a } from "kremling";

import { CpSideMenuButton } from "./sub-components/cp-side-menu-button.component";
import { CpSideMenuAccordion } from "./sub-components/cp-side-menu-accordion.component";
import { CpSideMenuDivider } from "./sub-components/cp-side-menu-divider.component";
import { CpSideMenuContext } from "./cp-side-menu-context";
import styles from "./cp-side-menu.styles.scss";

type CpSideMenuComponent = FC<CpSideMenuProps> & {
  Button: typeof CpSideMenuButton;
  Accordion: typeof CpSideMenuAccordion;
  Divider: typeof CpSideMenuDivider;
};

export const CpSideMenu: CpSideMenuComponent = (props) => {
  const {
    children,
    className,
    style,
    value,
    onChange,
    wrapWith = "well",
  } = props;
  const scope = useCss(styles);
  const [activeAccordion, setActiveAccordion] = useState<string | undefined>();
  return (
    <CpSideMenuContext.Provider
      value={{ value, onChange, activeAccordion, setActiveAccordion }}
    >
      <div
        {...scope}
        className={a("cp-side-menu", className)
          .m("cp-side-menu--well", wrapWith === "well")
          .m("cp-side-menu--card", wrapWith === "card")}
        style={style}
      >
        {children}
      </div>
    </CpSideMenuContext.Provider>
  );
};

CpSideMenu.Button = CpSideMenuButton;
CpSideMenu.Accordion = CpSideMenuAccordion;
CpSideMenu.Divider = CpSideMenuDivider;

export type CpSideMenuProps = {
  /**
   * (optional) pass in items as children (eg: <CpSideMenu.Button>)
   */
  children?: React.ReactNode;
  /**
   * (optional) className applied to the parent div
   */
  className?: string;
  /**
   * (optional) callback that is called when items are clicked.
   */
  onChange?: (value: string | number | undefined) => void;
  /**
   * (optional) override styles on parent div
   */
  style?: React.CSSProperties;
  /**
   * (optional) wrap menu in a L1 card or L1 well
   */
  wrapWith?: "none" | "card" | "well" | undefined;
  /**
   * (optional) Value to control the selected item within.
   */
  value?: string | number;
};
