import React, { forwardRef } from "react";
import isEmail from "validator/lib/isEmail";

import { CpInputField } from "./cp-input-field.component";
import { object, string } from "prop-types";

export const CpInputFieldEmail = forwardRef(
  function CpInputFieldEmail(props, ref) {
    const { control, fieldName, rules = {}, ...inputProps } = props;

    return (
      <CpInputField
        control={control}
        fieldName={fieldName}
        rules={{
          ...rules,
          validate: {
            ...rules?.validate,
            isValidEmail: (v) =>
              !v?.length || isEmail(v) || "Field must be email",
          },
        }}
        ref={ref}
        {...inputProps}
      />
    );
  },
);

CpInputFieldEmail.propTypes = {
  control: object.isRequired,
  fieldName: string.isRequired,
  rules: object,
};
