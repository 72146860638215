import React, { useLayoutEffect, useRef, useState } from "react";
import { useCss, k, a } from "kremling";
import { number, string, boolean } from "prop-types";

CpProgressBar.propTypes = {
  current: number.isRequired,
  min: number,
  max: number,
  minText: string,
  maxText: string,
  currentText: string,
  className: string,
  milestone: number,
  milestoneText: string,
  aiGradient: boolean,
};

export function CpProgressBar(props) {
  const {
    min = 0,
    max = 100,
    current = 0,
    minText = String(min),
    maxText = String(max),
    currentText = String(current),
    milestoneText = "",
    className = "",
    aiGradient = false,
  } = props;

  const scope = useCss(css);

  const milestone =
    props.milestone <= min || props.milestone > max ? 0 : props.milestone;
  const milestoneBarPercent = calcWidthPercent(
    current > milestone ? milestone : current,
    min,
    max,
  );
  const milestoneCaretPercent = calcWidthPercent(milestone, min, max);

  const percent = calcWidthPercent(current, min, max);
  const isFilling = percent < 100 && percent > 0;
  const isOverfilled = current > max;

  const minTextRef = useRef();
  const maxTextRef = useRef();
  const [currentTextLeft, currentTextRef, currentTextInitialized] =
    useCurrentText(percent);
  const [milestoneTextLeft, milestoneTextRef] = useMilestoneText(
    milestoneCaretPercent,
    minTextRef,
    maxTextRef,
  );

  return (
    <div {...scope} className={`${className} progress-bar`}>
      <div className="relative-wrapper">
        {!!currentText && (
          <div className="top-gutter">
            {(!!isFilling || isOverfilled) && (
              <>
                <div
                  className={
                    a("current-text cps-body")
                      .m(
                        "current-text--overfilled cps-wt-semibold",
                        isOverfilled,
                      )
                      .m("current-text--transition", currentTextInitialized) // Only start transitions after first positioning
                  }
                  style={{ left: currentTextLeft || "0" }}
                  ref={currentTextRef}
                >
                  {currentText}
                </div>
                <div
                  className="caret-wrapper"
                  style={{ left: `calc(${percent}% - ${halfCaretWidth})` }}
                >
                  <div className="caret" data-testid="caret">
                    <svg
                      width="8"
                      height="17"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 4.5L0 0h8L5 4.5V17H3V4.5z"
                        fill={"black"}
                        fillRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {!!milestone && milestone < max && (
          <div
            className="caret-wrapper"
            style={{
              left: `calc(${milestoneCaretPercent}% - ${halfCaretWidth})`,
            }}
          >
            <div className="caret">
              <svg width="8" height="17" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 4.5L0 0h8L5 4.5V17H3V4.5z"
                  fill={"black"}
                  fillRule="evenodd"
                />
              </svg>
            </div>
          </div>
        )}

        {!!milestone && !!milestoneText && milestone < max && (
          <div className="milestone-text-wrapper">
            <div
              className="milestone-text cps-body"
              style={{ left: milestoneTextLeft || "0" }}
              ref={milestoneTextRef}
            >
              {milestoneText}
            </div>
          </div>
        )}
      </div>

      <div className="background">
        <div
          className={a("foreground")
            .m("foreground--filling", isFilling)
            .m("ai-gradient", aiGradient)
            .m("infinite", current === Infinity && aiGradient)}
          style={{ width: `${percent}%` }}
        />
        {!!milestone && current > 0 && (
          <div
            className="milestone-foreground"
            style={{ width: `${milestoneBarPercent}%` }}
          />
        )}
      </div>

      <div className="bottom-text cps-body">
        <div ref={minTextRef}>{minText}</div>
        <div ref={maxTextRef}>{maxText}</div>
      </div>
    </div>
  );
}

function useCurrentText(current) {
  const currentTextRef = useRef();
  const [currentTextLeft, setCurrentTextLeft] = useState(null);
  const [initiallyPositioned, setInitiallyPositioned] = useState(false);
  const initialRender = useRef(false);

  useLayoutEffect(() => {
    if (!refsExist(currentTextRef)) return;
    const currentTextRect = currentTextRef.current.getBoundingClientRect();
    const position = `calc(${current}% - ${pxToRem(
      currentTextRect.width / 2,
    )})`;
    const max = `calc(100% - ${pxToRem(currentTextRect.width)})`;
    setCurrentTextLeft(`clamp(0%, ${position}, ${max})`);
    if (initialRender.current && !initiallyPositioned) {
      setInitiallyPositioned(true);
    } else {
      initialRender.current = true;
    }
    /* Add missing deps and verify it doesn't break: initiallyPositioned */
  }, [current]); // eslint-disable-line react-hooks/exhaustive-deps

  return [currentTextLeft, currentTextRef, initiallyPositioned];
}

function useMilestoneText(percent, minTextRef, maxTextRef) {
  const textRef = useRef();
  const [textLeft, setTextLeft] = useState(null);

  useLayoutEffect(() => {
    if (!refsExist(textRef, minTextRef, maxTextRef)) return;
    const textRect = textRef.current.getBoundingClientRect();
    const minTextRect = minTextRef.current.getBoundingClientRect();
    const maxTextRect = maxTextRef.current.getBoundingClientRect();

    const paddingPx = 8;
    const min = pxToRem(minTextRect.width + paddingPx);
    const max = `calc(100% - ${pxToRem(
      textRect.width + maxTextRect.width + paddingPx,
    )})`;
    const position = `calc(${percent}% - ${pxToRem(textRect.width)})`;

    setTextLeft(`clamp(${min}, ${position}, ${max})`);
    /* Add missing deps and verify it doesn't break: minTextRef, maxTextRef */
  }, [percent]); // eslint-disable-line react-hooks/exhaustive-deps

  return [textLeft, textRef];
}

function calcWidthPercent(val, min, max) {
  return val >= max ? 100 : ((val - min) / (max - min)) * 100;
}

function pxToRem(px) {
  return px / 10 + "rem";
}

function refsExist() {
  return [...arguments].every((r) => !!r?.current);
}

const barSize = "1.2rem";
const halfCaretWidth = ".4rem";
const transition = "200ms ease-out";

const css = k`
  .progress-bar {
    width: 100%;
    height: fit-content;
    height: -moz-fit-content;
  }

  .background {
    width: 100%;
    height: ${barSize};
    background-color: var(--cps-color-border);
    border-radius: 2.5rem;
    display: grid;
  }

  .foreground, .milestone-foreground {
    grid-column: 1;
    grid-row: 1;
  }

  .foreground {
    height: ${barSize};
    border-radius: 2.5rem;
    transition: width ${transition};
    background-color: var(--cps-color-primary);
  }

  .foreground.ai-gradient {
    background: linear-gradient(to right, 
      var(--cp-color-app-gradient-primary), 
      var(--cp-color-app-gradient-secondary)
    );
  }

  .foreground.ai-gradient.infinite {
    background: linear-gradient(to right,  
      var(--cp-color-app-gradient-primary), 
      var(--cp-color-app-gradient-secondary),  
      var(--cp-color-app-gradient-primary), 
      var(--cp-color-app-gradient-secondary), 
      var(--cp-color-app-gradient-primary)
    );
    animation: infiniteGradient 5s linear infinite;
    background-size: 200% 200%;
  }

  .milestone-foreground {
    height: ${barSize};
    border-radius: 2.5rem;
    transition: width ${transition};
    background: repeating-linear-gradient(
      45deg,
      white,
      white 10px,
      var(--cps-color-primary) 0px,
      var(--cps-color-primary) 14px
    );
    border: 1px solid var(--cps-color-primary);
  }

  .foreground.foreground--filling {
    border-radius: 2.5rem 0 0 2.5rem;
    border-right: .1rem solid var(--cps-color-primary-text);
  }

  .foreground.ai-gradient.foreground--filling {
    border-radius: 2.5rem 0 0 2.5rem;
    border-right: .1rem solid #6AE7C7;
  }

  .bottom-text {
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  .current-text {
    position: absolute;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    top: -0.4rem;
  }

  .current-text--transition {
    transition: left ${transition};
  }

  .current-text--overfilled {
    color: var(--cp-color-app-error-text);
  }

  .caret-wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    transition: left ${transition};
    width: fit-content;
    width: -moz-fit-content;
  }

  .top-gutter {
    height: 2rem;
  }

  .milestone-text-wrapper {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -3.2rem;
  }

  .milestone-text {
    position: absolute;
    bottom: 0;
    margin-right: auto;
    width: fit-content;
    width: -moz-fit-content;
  }

  .caret {
    position: absolute;
    width: 0.8rem;
    height: 0.5rem;
    bottom: 0;
  }

  .relative-wrapper {
    position: relative;
  }

  @keyframes infiniteGradient {
    from {
      background-position: 100% 0;
    }
    to {
      background-position: 0 0;
    }
  }
`;
