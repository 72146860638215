import React, { ReactNode } from "react";
import { a } from "kremling";

type CpWellProps = {
  level?: number;
  children?: ReactNode;
  className?: string;
};

export function CpWell({ children, level, className, ...rest }: CpWellProps) {
  return (
    <div
      className={a(className)
        .m("cp-well-l1", !level || level === 1)
        .m("cp-well-l2", level === 2)
        .m("cp-well-l3", level === 3)}
      {...rest}
    >
      {children}
    </div>
  );
}
