import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  concat,
  debounce,
  difference,
  intersection,
  omit,
  reject,
  sortBy,
} from "lodash";
import { catchAsyncStacktrace } from "auto-trace";

import { CpButton } from "@components";
import { defaultDateFormat, filterTypes } from "./column.helper";
import DynamicFilter from "./filter/dynamic-filter";
import FixedFilter from "./filter/fixed-filter";
import DateFilter from "./filter/date-filter";
import DateRangeFilter from "./filter/date-range-filter";
import { defaultDateFilterRanges } from "./filter/date-filter.helper";

import styles from "./menu-content.styles.css";

export const CpDateRangeSelector = ({
  filterContext,
  column,
  getDynamicFilters,
  getFixedFilters,
  onCancel,
  setColumnMenuShown,
  menuStyles,
  hideSortOptions,
  hideClear,
}) => {
  const [availableFilterValues, setAvailableFilterValues] = useState([]);
  const [allFilterValues, setAllFilterValues] = useState(null);
  const [selections, setSelections] = useState({
    ...filterContext.filters[column.sortParam],
  });
  const [filterSearchValue, setFilterSearchValue] = useState("");
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [initialSearch, setInitialSearch] = useState(false);
  const [searching, setSearching] = useState(false);
  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    if (
      filterContext.filters[column.sortParam]?.before?.includes("-") ||
      filterContext.filters[column.sortParam]?.after?.includes("-")
    ) {
      // Check if the value was passed in, and if it's in 'YYYY-MM-DD' format
      applyDateRangeFilter(
        filterContext.filters[column.sortParam].filter_params,
        filterContext.filters[column.sortParam],
      ); // If so, we need to format it to 'MM/DD/YYYY' for the rest of the calculations, if not, the selections state is already in the format the component needs ('MM/DD/YYYY')
    }
    /* Add missing deps and verify it doesn't break: applyDateRangerFilter (memoize), column.sortParam */
  }, [filterContext]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchDynamicFilter = () => setSearchTrigger(true);

  const debounceGetDynamicFilters = useMemo(
    () => debounce(searchDynamicFilter, 500),
    [],
  );

  useEffect(() => {
    if (!searchTrigger) return;
    let columnFilters = { ...filterContext.filters };
    columnFilters = omit(columnFilters, column.sortParam);
    const filterSubscription = getDynamicFilters(
      column.filterName,
      columnFilters,
      column.sortFilterList,
      column.showSearch && filterSearchValue.trim(),
    ).subscribe((result) => {
      if (result) {
        // check if the array has a null entry, then remove it
        let allFilterValues = reject(result, ["label", null]);

        if (column.sortFilterList) {
          allFilterValues = sortBy(allFilterValues, (value) =>
            value.label.toLowerCase(),
          );
        }
        setAvailableFilterValues(
          filterSearchValue
            ? allFilterValues.filter(
                (value) =>
                  value.label &&
                  value.label
                    .toString()
                    .toLowerCase()
                    .includes(filterSearchValue),
              )
            : allFilterValues,
        );
        setAllFilterValues(allFilterValues);
        setSearchTrigger(false);
        setSearching(false);
      }
    }, catchAsyncStacktrace());

    return () => filterSubscription.unsubscribe();
    /* Tons of deps are missing here, remove the disable comment and run lint to see */
  }, [searchTrigger]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (column.filterType === filterTypes.Dynamic) {
      if (column.showFilterList) {
        if (!initialSearch) {
          setSearchTrigger(true);
          setInitialSearch(true);
          setSearching(true);
        } else {
          setSearching(true);
          debounceGetDynamicFilters(true);
        }
      }
    } else if (column.filterType === filterTypes.Fixed) {
      let availableFilters = getFixedFilters
        ? getFixedFilters(column)
        : [...column.filterValues];

      setAvailableFilterValues(availableFilters);
      setAllFilterValues(availableFilters);
    }
    /* Add missing deps and verify it doesn't break: debounceGetDynamicFilters, initialSearch, getFixedFilters (memoize or store in ref) */
  }, [column, filterSearchValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const filterSelections = (e, applyImmediately) => {
    const searchValue = e.target.value.toString().toLowerCase().trimStart();
    setFilterSearchValue(searchValue);

    if (applyImmediately) {
      applyFilter(e, searchValue, false);
    } else {
      if (!allFilterValues) return;

      setAvailableFilterValues(
        allFilterValues.filter(
          (value) =>
            value.label &&
            value.label.toString().toLowerCase().includes(searchValue),
        ),
      );
    }
  };

  const applySelections = () => {
    filterContext.applyFilter(column.sortParam, selections || 0);
    setColumnMenuShown(false);
  };

  const clearSelections = () => {
    setSelections({});
    setAvailableFilterValues(allFilterValues);
    setFilterSearchValue("");
    setDisableSave(false);
  };

  const applySort = (order, e) => {
    e.stopPropagation();

    setSelections({
      ...selections,
      order: selections && selections.order === order ? undefined : order,
    });
  };

  const applyFilter = (e, filter, allowMultiple, selectionRules) => {
    e.stopPropagation();

    const newSelections = { ...selections };

    if (!newSelections.filter_params) {
      newSelections.filter_params = [];
    }
    const filterIsArray = Array.isArray(filter);
    const isSelected = filterIsArray
      ? intersection(newSelections.filter_params, filter).length > 0
      : newSelections.filter_params.includes(filter);

    isSelected
      ? (newSelections.filter_params = difference(
          newSelections.filter_params,
          filterIsArray ? filter : [filter],
        ))
      : allowMultiple
        ? (newSelections.filter_params = concat(
            newSelections.filter_params,
            filter,
          ))
        : (newSelections.filter_params = [filter]);

    if (selectionRules) {
      newSelections.filter_params = selectionRules(
        filter,
        newSelections.filter_params,
      );
    }

    setSelections(newSelections);
  };

  const applyDateFilter = (evt) => {
    const selectedDate = moment(evt.detail).format(defaultDateFormat);
    setSelections({ ...selections, filter_params: selectedDate });
  };

  const applyDateRangeFilter = (selectedFilter, filter) => {
    let before, after; // Canopy date standard is 'YYYY-MM-DD' as of Feb 2020 but this component works with date as 'MM/DD/YYYY', the below code is overcomplicated because we are accepting either format
    if (filter.before && typeof filter.before === "string") {
      // if the before and after dates were passed in as 'YYYY-MM-DD', we need to format them to 'MM/DD/YYYY' for this component
      before = moment(filter.before, "YYYY-MM-DD").format(defaultDateFormat);
    } else if (filter.before) {
      before = filter.before.format(defaultDateFormat);
    }
    if (filter.after && typeof filter.after === "string") {
      // if the before and after dates were passed in as 'YYYY-MM-DD', we need to format them to 'MM/DD/YYYY' for this component
      after = moment(filter.after, "YYYY-MM-DD").format(defaultDateFormat);
    } else if (filter.after) {
      after = filter.after.format(defaultDateFormat);
    }
    if (before || after || selectedFilter) {
      setSelections({
        ...selections,
        filter_params: selectedFilter,
        before,
        after,
      });
    } else {
      setSelections({});
    }
  };

  let selectedFilters = selections.filter_params;

  return (
    <ul
      className="cps-dropdown-menu cps-padding-bottom-0"
      role="menu"
      style={menuStyles}
      onClick={(e) => e.stopPropagation()}
    >
      {!hideSortOptions && (
        <>
          <li
            className={`cps-cursor-pointer ${
              column.filterType === filterTypes.DateRange
                ? `${styles.dateFilterWidth} cp-wt-semibold`
                : ""
            }`}
          >
            <div
              className={`${styles.spreadContents} cps-margin-left-16 cps-margin-right-16 cps-padding-top-8 cps-padding-bottom-8`}
              onClick={(evt) => applySort("asc", evt)}
              style={{ lineHeight: "20px" }}
            >
              <div
                style={{ whiteSpace: "nowrap" }}
                className={
                  selections.order === "asc" ? "cps-color-primary" : ""
                }
              >
                Sort
                <span className="cp-wt-semibold">
                  &nbsp;
                  {column.minSortValue} - {column.maxSortValue}
                </span>
              </div>
              <div style={{ minWidth: "25px", margin: "-3px" }}>
                {selections.order === "asc" && (
                  <i className="cps-color-primary cps-icon cps-icon-sm-check" />
                )}
              </div>
            </div>
          </li>
          <li
            className={`cps-cursor-pointer ${
              column.filterType === filterTypes.DateRange
                ? `${styles.dateFilterWidth} cp-wt-semibold`
                : ""
            }`}
          >
            <div
              className={`${styles.spreadContents} cps-margin-left-16 cps-margin-right-16 cps-padding-top-8 cps-padding-bottom-8`}
              onClick={(evt) => applySort("desc", evt)}
              style={{ lineHeight: "20px" }}
            >
              <div
                style={{ whiteSpace: "nowrap" }}
                className={
                  selections.order === "desc" ? "cps-color-primary" : ""
                }
              >
                Sort
                <span className="cp-wt-semibold">
                  &nbsp;
                  {column.maxSortValue} - {column.minSortValue}
                </span>
              </div>
              <div style={{ minWidth: "25px", margin: "-3px" }}>
                {selections.order === "desc" && (
                  <i className="cps-color-primary cps-icon cps-icon-sm-check" />
                )}
              </div>
            </div>
          </li>
        </>
      )}

      {column.filterType === filterTypes.Dynamic && (
        <DynamicFilter
          filterValues={availableFilterValues}
          filterSelections={filterSelections}
          applyFilter={applyFilter}
          selections={selectedFilters}
          showSearch={column.showSearch}
          showFilterList={column.showFilterList}
          hideSortOptions={hideSortOptions}
          filterSearchValue={filterSearchValue}
          searchPlaceholder={column.searchPlaceholder}
          searching={searching}
        />
      )}
      {column.filterType === filterTypes.Fixed && (
        <FixedFilter
          filterValues={availableFilterValues}
          applyFilter={applyFilter}
          selections={selectedFilters}
          hideSortOptions={hideSortOptions}
          allowMultiple={column.allowMultiple}
        />
      )}
      {column.filterType === filterTypes.Date && (
        <DateFilter
          applyFilter={applyDateFilter}
          selectedDate={
            Array.isArray(selectedFilters)
              ? selectedFilters[0]
              : selectedFilters
          }
        />
      )}
      {column.filterType === filterTypes.DateRange && (
        <DateRangeFilter
          applyFilter={applyDateRangeFilter}
          filter={selections}
          dateRanges={column.dateRanges || defaultDateFilterRanges}
          hideSortOptions={hideSortOptions}
          setDisableSave={setDisableSave}
        />
      )}
      {column.filterType === filterTypes.Custom &&
        column.customFilter &&
        column.customFilter({
          applyFilter: applyFilter,
          selections: selections,
        })}
      {column.filterType === filterTypes.None && <div />}
      <div className={`${styles.applyFilter} ${styles.spreadContents}`}>
        <div style={{ display: "inline-flex" }}>
          <CpButton
            btnType="primary"
            className="cp-mr-8"
            onClick={applySelections}
            disabled={disableSave}
          >
            Apply
          </CpButton>
          <CpButton btnType="flat" onClick={onCancel}>
            Cancel
          </CpButton>
        </div>
        {!hideClear && (
          <CpButton btnType="flat" onClick={clearSelections}>
            Clear
          </CpButton>
        )}
      </div>
    </ul>
  );
};

CpDateRangeSelector.propTypes = {
  filterContext: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  getDynamicFilters: PropTypes.func,
  getFixedFilters: PropTypes.func,
  setColumnMenuShown: PropTypes.func,
};
