import { createContext, useEffect, FC } from "react";
import { modalService } from "../../helpers/index";

export const durationInMillis = 400;
export const modalWidthMin = 424;
export const modalWidthMax = 832;
export const smallOverlayMax = 1280;

export function forceRange(val: number, min: number, max: number): number {
  if (val < min) return min;
  if (val > max) return max;
  return val;
}

type ModalContextType = {
  onClose?: () => void;
};

export const ModalContext = createContext<ModalContextType>({});

export const ModalMount: FC = () => {
  useEffect(() => {
    modalService.__openModal(true);
    return () => {
      modalService.__closeModal();
    };
  }, []);
  return null;
};
