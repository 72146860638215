import React from "react";
import { CpRadio } from "@components";
import { useController } from "react-hook-form";
import { any, object, string } from "prop-types";

export function CpRadioField(props) {
  const {
    fieldName,
    control,
    controllerOptions,
    required,
    rules,
    ...CpRadioProps
  } = props;
  let mRules = {};
  if (required) {
    mRules.required = "Field is required";
  }
  const mergedRules = { ...mRules, ...rules };

  const {
    field: { value, onBlur, onChange },
    fieldState: { error },
  } = useController({
    name: fieldName,
    control,
    rules: mergedRules,
    ...controllerOptions,
  });

  return (
    <CpRadio
      {...CpRadioProps}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      required={required}
      error={error?.message}
    />
  );
}

CpRadioField.Item = CpRadio.Item;

CpRadioField.propTypes = {
  control: any.isRequired,
  controllerOptions: object,
  fieldName: string.isRequired,
  rules: object,
};
