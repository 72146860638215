import React from "react";
import { m } from "kremling";
import styles from "./table-body.styles.css";
import { useColumnWidths } from "../hooks/use-column-widths";
import { ColumnId, ColumnWidthMap, ResizeInfo, TableSchema } from "../types";
import { Selection } from "../hooks/use-table-selection.hook";
import { MemoCell } from "./cell";

function Row({
  columnWidths,
  stickyResizing,
  rowIsDisabled,
  resource,
  schema,
  orderedColumns,
  openContextMenu,
  contextMenuOpenOnRow,
  selection,
  resourceIndex,
  onSelect,
  verticalBorders,
}: {
  columnWidths?: ColumnWidthMap;
  stickyResizing: ResizeInfo | null;
  rowIsDisabled?: boolean;
  resource: any;
  schema: TableSchema;
  orderedColumns: ColumnId[];
  openContextMenu: ((e: React.MouseEvent, resource: any) => void) | null;
  contextMenuOpenOnRow: boolean;
  selection?: Selection;
  resourceIndex: number;
  onSelect: (
    evt: React.MouseEvent,
    resource: any,
    resourceIndex: number,
  ) => void;
  verticalBorders: boolean;
}) {
  const { aggregateWidth } = useColumnWidths();
  return (
    <tr
      onContextMenu={(e) => {
        if (openContextMenu) {
          e.preventDefault();
          openContextMenu(e, resource);
        }
      }}
      className={m(styles.verticalBorder, verticalBorders)
        .m(styles.contextMenuHighlight, contextMenuOpenOnRow)
        .m(
          styles.selectedRow,
          rowIsDisabled ? false : selection?.isSelected(resource.id),
        )
        .m(styles.disableRow, rowIsDisabled)}
    >
      {orderedColumns.map((columnId) => {
        const isSelect = columnId === "select";
        if (!schema[columnId] || (isSelect && !selection)) return null;
        const { columnWidth, aggregatedWidth } = aggregateWidth({
          columnSchema: schema[columnId],
          columnWidths,
          resizingWidth:
            stickyResizing?.columnId === columnId
              ? stickyResizing?.width
              : undefined,
        });

        return (
          <MemoCell
            key={columnId}
            columnId={columnId}
            resource={resource}
            schema={schema}
            rowIsDisabled={rowIsDisabled}
            aggregatedWidth={
              // Only pass for sticky cells so we can memoize the others
              schema[columnId].sticky ? aggregatedWidth : null
            }
            columnWidth={columnWidth}
            resourceIndex={resourceIndex}
            // Only pass to select cell so we can memoize the rest
            selection={isSelect && selection ? selection : null}
            onSelect={isSelect ? onSelect : null}
          />
        );
      })}
    </tr>
  );
}
export const MemoRow = React.memo(Row);
