import React, {
  HTMLAttributes,
  MutableRefObject,
  RefObject,
  useEffect,
  useRef,
} from "react";
import { k, useCss } from "kremling";
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingList,
  FloatingNode,
  FloatingPortal,
  offset,
  Placement,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useHover,
  useInteractions,
  useListItem,
  useListNavigation,
  useMergeRefs,
  useRole,
  useTypeahead,
} from "@floating-ui/react";
import { MenuContext } from "./cp-floating-menu-context";

export type CpFloatingMenuInnerProps = {
  nested?: boolean;
  placement?: Extract<Placement, "bottom-start" | "bottom-end">;
  children?: React.ReactNode;
  renderItem?: (props: any) => React.ReactNode;
  renderTrigger?: (refProps: any, opts: any) => React.ReactNode;
  contextTrigger?: MutableRefObject<HTMLElement> | RefObject<HTMLElement>;
};

export const CpFloatingMenuInner = React.forwardRef<
  HTMLButtonElement,
  CpFloatingMenuInnerProps & HTMLAttributes<HTMLElement>
>(
  (
    {
      children,
      renderTrigger,
      contextTrigger,
      renderItem,
      placement = "bottom-start",
      ...props
    },
    forwardedRef,
  ) => {
    const scope = useCss(css);
    const [isOpen, setIsOpen] = React.useState(false);
    const [hasFocusInside, setHasFocusInside] = React.useState(false);
    const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

    const elementsRef = React.useRef<Array<HTMLButtonElement | null>>([]);
    const labelsRef = React.useRef<Array<string | null>>([]);
    const allowMouseUpCloseRef = useRef(false);
    const parent = React.useContext(MenuContext);

    const tree = useFloatingTree();
    const nodeId = useFloatingNodeId();
    const parentId = useFloatingParentNodeId();
    const item = useListItem();

    const isNested = parentId != null;

    const { floatingStyles, refs, context } = useFloating<HTMLButtonElement>({
      nodeId,
      open: isOpen,
      onOpenChange: setIsOpen,
      placement: isNested ? `right-start` : (placement as Placement),
      middleware: [
        offset({ mainAxis: 0, alignmentAxis: isNested ? -8 : 0 }),
        flip(),
        shift(),
      ],
      whileElementsMounted: autoUpdate,
    });

    const hover = useHover(context, {
      enabled: isNested,
      delay: { open: 75 },
      handleClose: safePolygon({ blockPointerEvents: true }),
    });
    const click = useClick(context, {
      event: "mousedown",
      toggle: !isNested,
      ignoreMouse: isNested,
    });
    const role = useRole(context, { role: "menu" });
    const dismiss = useDismiss(context, { bubbles: true });
    const listNavigation = useListNavigation(context, {
      listRef: elementsRef,
      activeIndex,
      nested: isNested,
      onNavigate: setActiveIndex,
    });
    const typeahead = useTypeahead(context, {
      listRef: labelsRef,
      onMatch: isOpen ? setActiveIndex : undefined,
      activeIndex,
    });

    const { getReferenceProps, getFloatingProps, getItemProps } =
      useInteractions([hover, click, role, dismiss, listNavigation, typeahead]);

    // Event emitter allows you to communicate across tree components.
    // This effect closes all menus when an item gets clicked anywhere
    // in the tree.
    React.useEffect(() => {
      if (!tree) return;

      function handleTreeClick() {
        setIsOpen(false);
      }

      function onSubMenuOpen(event: { nodeId: string; parentId: string }) {
        if (event.nodeId !== nodeId && event.parentId === parentId) {
          setIsOpen(false);
        }
      }

      tree.events.on("click", handleTreeClick);
      tree.events.on("menuopen", onSubMenuOpen);

      return () => {
        tree.events.off("click", handleTreeClick);
        tree.events.off("menuopen", onSubMenuOpen);
      };
    }, [tree, nodeId, parentId]);

    React.useEffect(() => {
      if (isOpen && tree) {
        tree.events.emit("menuopen", { parentId, nodeId });
      }
    }, [tree, isOpen, nodeId, parentId]);

    const mergedRefs = useMergeRefs([
      refs.setReference,
      item.ref,
      forwardedRef,
    ]);
    const referenceProps = {
      ref: mergedRefs,
      tabIndex: !isNested
        ? undefined
        : parent.activeIndex === item.index
          ? 0
          : -1,
      "data-open": isOpen ? "" : undefined,
      "data-nested": isNested ? "" : undefined,
      "data-focus-inside": hasFocusInside ? "" : undefined,
      ...getReferenceProps(
        parent.getItemProps({
          ...props,
          onFocus(event: React.FocusEvent<HTMLButtonElement>) {
            props.onFocus?.(event);
            setHasFocusInside(false);
            parent.setHasFocusInside(true);
          },
        }),
      ),
    };

    useEffect(() => {
      let timeout: number;

      function onContextMenu(e: MouseEvent) {
        e.preventDefault();
        refs.setPositionReference({
          getBoundingClientRect: () => ({
            width: 0,
            height: 0,
            x: e.clientX,
            y: e.clientY,
            top: e.clientY,
            right: e.clientX,
            bottom: e.clientY,
            left: e.clientX,
          }),
        });

        setIsOpen(true);
        clearTimeout(timeout);

        allowMouseUpCloseRef.current = false;
        timeout = window.setTimeout(() => {
          allowMouseUpCloseRef.current = true;
        }, 300);
      }

      function onMouseUp() {
        if (allowMouseUpCloseRef.current) {
          setIsOpen(false);
        }
      }

      const el = contextTrigger?.current;

      if (!renderTrigger && !!contextTrigger) {
        document.addEventListener("mouseup", onMouseUp);
        el?.addEventListener("contextmenu", onContextMenu);
      }
      return () => {
        if (!renderTrigger && !!contextTrigger) {
          document.removeEventListener("mouseup", onMouseUp);
          clearTimeout(timeout);
          el?.removeEventListener("contextmenu", onContextMenu);
        }
      };
    }, [contextTrigger, refs, renderTrigger]);

    return (
      <FloatingNode id={nodeId}>
        {renderTrigger?.(referenceProps, null) ||
          renderItem?.({
            ...referenceProps,
            isSubMenu: true,
            tabIndex: !isNested
              ? undefined
              : parent.activeIndex === item.index
                ? 0
                : -1,
            refs: mergedRefs,
          })}
        <MenuContext.Provider
          value={{
            activeIndex,
            setActiveIndex,
            getItemProps,
            setHasFocusInside,
            isOpen,
          }}
        >
          <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
            {isOpen && (
              <FloatingPortal>
                <FloatingFocusManager
                  context={context}
                  modal={false}
                  initialFocus={isNested ? -1 : 0}
                  returnFocus={!isNested}
                >
                  <div
                    {...scope}
                    ref={refs.setFloating}
                    className="cp-floating-menu"
                    style={floatingStyles}
                    {...getFloatingProps()}
                  >
                    {children}
                  </div>
                </FloatingFocusManager>
              </FloatingPortal>
            )}
          </FloatingList>
        </MenuContext.Provider>
      </FloatingNode>
    );
  },
);

const css = k`
  .cp-floating-menu {
    background-color: var(--cp-color-card-bg);
    display: flex;
    flex-direction: column;
    outline: none;
    border-radius: 8px;
    box-shadow:
            0 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.06),
            0 0.2rem 0.6rem 0 rgba(0, 0, 0, 0.26);
    border: var(--cp-color-card-border);
    z-index: 100001;
    overflow: hidden;
    padding: .8rem 0;
  }

  .cp-floating-menu-item {
    width: 100%;
    background: var(--menu-item-bg);
    border: none;
    color: var(--cp-color-app-primary-text);
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 11rem;
    margin: 0;
    outline: 0;
    padding: .8rem 1.6rem;
    min-height: 4rem;
    gap: 2.4rem;
    cursor: pointer;
    text-decoration: none;

    svg {
      fill: var(--cp-color-app-icon);
    }

    &:focus, &:not([disabled]):active {
      background-color: var(--cp-color-menu-hover-bg);
    }
  }

  .cp-floating-menu-item-sub-text {
    font-size: 1.2rem;
    color: var(--cp-color-app-secondary-text);
  }


  .cp-floating-menu-item__inner {
    gap: .8rem;
    display: flex;
    align-items: center;
  }

  .menu-item__icon {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    color: purple;
  }

  .menu > .menu-item:last-child {
    border: none;
  }

  .menu-item-icon-left {
    margin-right: 1.6rem;
  }


  .menu-divider {
    padding-top: .4rem;
    margin-bottom: .4rem;
    border-bottom: solid .1rem var(--app-border);
    margin-left: -.4rem;
    width: calc(100% + .8rem);
  }
`;
