// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filter-styles__filterMenu--0zWDf {\n  overflow-y: auto;\n  overflow-x: hidden;\n  width: 100%;\n  max-height: 175px;\n  max-width: 300px;\n}\n\n.filter-styles__betterMargins--Hcm64 {\n  margin: 12px 16px;\n}\n\n.filter-styles__spreadContents--twhTn {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n", "",{"version":3,"sources":["webpack://src/components/cp-table-header-menu/filter/filter.styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC","sourcesContent":[".filterMenu {\n  overflow-y: auto;\n  overflow-x: hidden;\n  width: 100%;\n  max-height: 175px;\n  max-width: 300px;\n}\n\n.betterMargins {\n  margin: 12px 16px;\n}\n\n.spreadContents {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterMenu": "filter-styles__filterMenu--0zWDf",
	"betterMargins": "filter-styles__betterMargins--Hcm64",
	"spreadContents": "filter-styles__spreadContents--twhTn"
};
export default ___CSS_LOADER_EXPORT___;
