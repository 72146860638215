// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cps-time-styles__dropdown--sxFrL {\n  height: 196px;\n  max-height: 196px;\n  overflow-y: auto;\n}\n", "",{"version":3,"sources":["webpack://src/custom-elements/cps-time/cps-time.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".dropdown {\n  height: 196px;\n  max-height: 196px;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "cps-time-styles__dropdown--sxFrL"
};
export default ___CSS_LOADER_EXPORT___;
