import { createContext } from "react";
import { CpSideMenuProps } from "./cp-side-menu.component";

export const CpSideMenuContext = createContext<
  Pick<CpSideMenuProps, "value" | "onChange"> & {
    activeAccordion: string | undefined;
    setActiveAccordion: (id: string) => void;
  }
>({
  activeAccordion: undefined,
  setActiveAccordion: () => {},
});
