import { isEmpty } from "lodash";
import moment from "moment";

export const defaultDateFormat = "MM/DD/YYYY";

export const filterTypes = {
  None: "none",
  Dynamic: "dynamic",
  Fixed: "fixed",
  Date: "date",
  DateRange: "date-range",
  Custom: "custom",
};

export function hasSortOrFilter(columnSettings) {
  return !!(
    !isEmpty(columnSettings) &&
    (columnSettings.order ||
      !isEmpty(columnSettings.filter_params) ||
      columnSettings.after ||
      columnSettings.before)
  );
}

export const weekFilter = {
  filterKey: "custom",
  startDate: moment().add(-7, "day").format(defaultDateFormat),
  endDate: moment().format(defaultDateFormat),
};
