// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".colorpicker-styles__colorpicker--tYEei {\n  width: 100%;\n  position: relative;\n  display: flex;\n  align-items: center;\n}\n\n.colorpicker-styles__bar--jdyPP {\n  width: 100%;\n  border-radius: 5px;\n  cursor: \"pointer\";\n}\n\n.colorpicker-styles__slider--aDhrU {\n  border-radius: 50%;\n  position: absolute;\n  box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.2);\n}\n\n.colorpicker-styles__grab--DM32T {\n  cursor: grab;\n}\n\n.colorpicker-styles__grabbing--uXo1Q {\n  cursor: grabbing;\n}\n", "",{"version":3,"sources":["webpack://src/custom-elements/cps-colorpicker/colorpicker.styles.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,2CAA2C;AAC7C;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".colorpicker {\n  width: 100%;\n  position: relative;\n  display: flex;\n  align-items: center;\n}\n\n.bar {\n  width: 100%;\n  border-radius: 5px;\n  cursor: \"pointer\";\n}\n\n.slider {\n  border-radius: 50%;\n  position: absolute;\n  box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.2);\n}\n\n.grab {\n  cursor: grab;\n}\n\n.grabbing {\n  cursor: grabbing;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorpicker": "colorpicker-styles__colorpicker--tYEei",
	"bar": "colorpicker-styles__bar--jdyPP",
	"slider": "colorpicker-styles__slider--aDhrU",
	"grab": "colorpicker-styles__grab--DM32T",
	"grabbing": "colorpicker-styles__grabbing--uXo1Q"
};
export default ___CSS_LOADER_EXPORT___;
