import React from "react";
import { CpIcon } from "@components";
import { bool, node, number, oneOfType, string } from "prop-types";

export function Group(props) {
  const { name, icon, count, isSeparator, showCount = true } = props;

  if (isSeparator) {
    return <div className="cp-select-component__group-separator" />;
  }

  if (!name && !icon) {
    return null;
  }

  return (
    <div className="cp-select-component__group">
      <div className="cp-select-component__group-line" />
      <div className="cp-select-component__group-content">
        {icon && typeof icon === "string" ? (
          <CpIcon className="cp-mr-8" name={icon} />
        ) : (
          <span className="cp-select-component__group-custom-image">
            {icon}
          </span>
        )}
        {name}{" "}
        {showCount && (
          <span className="cp-select-component__group-count">
            ({count} results)
          </span>
        )}
      </div>
    </div>
  );
}

Group.propTypes = {
  name: string,
  icon: oneOfType([string, node]),
  count: number,
  isSeparator: bool,
  showCount: bool,
};
