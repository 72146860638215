import React from "react";
import { customElementToReact } from "../custom-element-to-react.js";
import { reactToCustomElement } from "../react-to-custom-element.js";
import { CprButton } from "../cps-button/cps-button.component.js";
import { Scoped, k, a, t } from "kremling";
import CpsIcon from "../cps-icon/cps-icon.component.js";
import PropTypes from "prop-types";

let selectId = 0;
const DESELECT_KEY = "__DESELECT_KEY__";

// Exported only for testing purposes. Shouldn't be used outside of canopy-styleguide project
export default class CpsShortSingleSelect extends React.Component {
  constructor(props) {
    super(props);
    if (props.labelText && props.ariaLabelText) {
      throw new Error(
        `CprShortSingleSelect props "labelText" and "ariaLabelText" are mutually exclusive (both cannot be provided at once)`,
      );
    } else if (!props.labelText && !props.ariaLabelText) {
      throw new Error(
        `CprShortSingleSelect expects prop "labelText" or "ariaLabelText". If a hidden label is desired, please provide prop "ariaLabelText".`,
      );
    }
    this.labelText = props.labelText ? props.labelText : props.ariaLabelText;
  }
  static defaultProps = {
    placeholderText: "Select an item",
    options: [],
    width: "",
    listWidth: "",
    isListWrapped: false,
  };
  static propTypes = {
    initialSelection: PropTypes.string,
    value: PropTypes.string,
  };
  selectId = `select-${selectId++}`;
  labelId = this.selectId + "-label";
  buttonId = this.selectId + "-button";
  listboxRef = React.createRef();
  state = {
    optionsOpen: false,
    selectedItem: this.props.initialSelection
      ? this.getSelectedValue(this.props.initialSelection)
      : null,
    previouslySelectedItem: null,
    deselected: !(this.props.initialSelection || this.props.value),
  };
  render() {
    const selectedItem = this.state.selectedItem
      ? this.state.selectedItem
      : this.props.placeholderText;
    let selection = this.props.value
      ? this.getSelectedValue(this.props.value)
      : selectedItem;
    if (this.state.deselected && this.props.deselectText) {
      selection = { key: DESELECT_KEY, value: this.props.deselectText };
    }

    return (
      <Scoped css={styles}>
        <div className="select">
          <label className={t("hidden", "label", !this.props.labelText)}>
            {this.labelText}
          </label>
          {/* screen reader */}
          <label id={this.labelId} className="hidden">
            {this.props.ariaLabelText}
          </label>
          <CprButton
            actionType="unstyled"
            type="button"
            id={this.buttonId}
            aria-haspopup="listbox"
            aria-labelledby={`${this.labelId} ${this.buttonId}`}
            aria-expanded={this.state.optionsOpen}
            onClick={this.toggleShowOptions}
            onKeyDown={this.keyDown}
            className={a("button")
              .m("unselected", !selection)
              .m("optionsOpen", this.state.optionsOpen)}
            disabled={this.props.isDisabled}
            style={{ width: `${this.props.width}` }}
          >
            <div className="noOverflow">
              {this.getButtonValue(selection, selectedItem)}
            </div>
            <div className="caret">
              <CpsIcon name="sm-caret-down" size={24} />
            </div>
          </CprButton>
          <ul
            tabIndex={-1}
            role="listbox"
            aria-labelledby={this.labelId}
            aria-activedescendant={
              selection ? `${this.selectId}-option-${selection.key}` : null
            }
            className={a("listbox")
              .t("", "noOverflow", this.props.isListWrapped)
              .t("open", "hidden", this.state.optionsOpen)}
            style={{ width: `${this.props.listWidth}` }}
            onKeyDown={this.keyDown}
            ref={this.listboxRef}
          >
            {this.getOptions(selection)}
          </ul>
        </div>
      </Scoped>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.optionsOpen && this.state.optionsOpen) {
      if (this.listboxRef && this.listboxRef.current) {
        this.listboxRef.current.focus();
      }
      document.addEventListener("click", this.closeOptions, true);
    } else if (prevState.optionsOpen && !this.state.optionsOpen) {
      document.removeEventListener("click", this.closeOptions, true);
    }

    if (
      (prevState.selectedItem !== this.state.selectedItem ||
        prevProps.value != this.props.value) &&
      this.state.optionsOpen
    ) {
      if (this.props.value || this.state.selectedItem) {
        const selectedDomElement = this.listboxRef.current.querySelector(
          `li[data-key="${
            this.props.value ? this.props.value : this.state.selectedItem.key
          }"]`,
        );
        const scrollTop = this.listboxRef.current.scrollTop;
        const scrollBottom = scrollTop + this.listboxRef.current.clientHeight;
        const needToScrollIntoView =
          selectedDomElement.offsetTop + selectedDomElement.offsetHeight <=
            scrollTop || selectedDomElement.offsetTop >= scrollBottom;
        if (needToScrollIntoView) {
          selectedDomElement.scrollIntoView();
        }
      }
    } else if (
      prevState.selectedItem !== this.state.selectedItem &&
      !this.props.value
    ) {
      if (this.state.selectedItem.key !== DESELECT_KEY) {
        this.props.optionSelected(this.state.selectedItem);
      }
    }
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.closeOptions, true);
  }
  closeOptions = (evt) => {
    if (
      evt &&
      this.listboxRef.current &&
      this.listboxRef.current.contains(evt.target)
    ) {
      // Don't close the popup here if you click on it. It closes after the selectedItem updates.
      return;
    }
    this.setState({ optionsOpen: false });
  };
  toggleShowOptions = () => {
    this.setState((prevState) => ({ optionsOpen: !prevState.optionsOpen }));
  };

  getOptions = (selection) => {
    const options = this.props.deselectText
      ? this.getOptionsWithDeselect()
      : this.props.options;
    return options.map((option) => (
      <li
        role="option"
        aria-selected={
          selection && selection.key && option.key
            ? selection.key === option.key
            : null
        }
        id={option.key ? `${this.selectId}-option-${option.key}` : "selector"}
        key={option.key ? option.key : "selector"}
        className={a("option")
          .m("selectedOption", selection ? selection.key === option.key : false)
          .m("separator", option.separator ? option.separator : false)}
        onClick={() => this.selectOption(option)}
        data-key={option.key}
      >
        <div
          className={t("", "noOverflow", this.props.isListWrapped)}
          style={{ width: `${this.props.listWidth}` }}
        >
          {option.value}
        </div>
      </li>
    ));
  };

  getOptionsWithDeselect = () => [
    { key: DESELECT_KEY, value: this.props.deselectText },
    ...this.props.options,
  ];

  keyDown = (evt) => {
    const newSelectedItem = this.selectionFromKeyDown(evt);
    if (evt.key === "ArrowDown" || evt.key === "ArrowUp") {
      this.setState({ optionsOpen: true });
    }
    if (evt.key === "Tab") {
      this.setState({ optionsOpen: false });
    }
    if (newSelectedItem != null) {
      if (this.props.value) {
        this.setState(
          { deselected: newSelectedItem.key === DESELECT_KEY },
          () =>
            this.props.optionSelected(
              newSelectedItem.key === DESELECT_KEY
                ? undefined
                : newSelectedItem,
            ),
        );
      } else {
        this.setState(
          (prevState) => ({
            previouslySelectedItem: prevState.selectedItem,
            selectedItem: newSelectedItem,
            deselected: newSelectedItem.key === DESELECT_KEY,
          }),
          () =>
            this.props.optionSelected(
              newSelectedItem.key === DESELECT_KEY
                ? undefined
                : newSelectedItem,
            ),
        );
      }
    }
  };

  getButtonValue(selection, selectedItem) {
    if (selection) {
      if (this.props.deselectText && selection.key === DESELECT_KEY) {
        return this.props.placeholderText; //  if state.selectedItem has our deselect_key, just display the placeholder
      } else {
        return selection.value || selection;
      }
    } else {
      return selectedItem;
    }
  }

  getSelectedValue(value) {
    if (typeof value === "string") {
      const matchingObject = this.props.options.find(
        (option) => option.key === value,
      );
      if (!matchingObject) {
        // matchingObject is undefined, no match found
        console.warn(
          `The provided key "${value}" does not exist in the provided options. Check the "value" or "initialSelection" props.`,
        );
      }
      return matchingObject;
    } else {
      return value;
    }
  }

  selectionFromKeyDown = (evt) => {
    const options = this.props.deselectText
      ? this.getOptionsWithDeselect()
      : this.props.options;
    let selectedItem = this.props.value
      ? this.props.value
      : this.state.selectedItem;
    if (this.state.deselected) {
      selectedItem = { key: DESELECT_KEY, value: this.props.deselectText };
    }

    if (options.length <= 0) {
      return;
    } else if (
      (evt.key === "ArrowDown" || evt.key === "ArrowUp") &&
      !selectedItem
    ) {
      return options[0];
      // this.setState({selectedItem: options[0]})
    } else if (evt.key === "ArrowDown") {
      const currentIndex = options.findIndex(
        (option) =>
          option.key === (selectedItem.key ? selectedItem.key : selectedItem),
      );
      if (currentIndex === options.length - 1) {
        // If you're at the bottom and press ArrowDown, nothing happens
        return null;
      } else if (options[currentIndex + 1].separator) {
        // If you hit a separator, skip to next viable option
        return options[currentIndex + 2];
      } else {
        return options[currentIndex + 1];
      }
    } else if (evt.key === "ArrowUp") {
      const currentIndex = options.findIndex(
        (option) =>
          option.key === (selectedItem.key ? selectedItem.key : selectedItem),
      );
      if (currentIndex === 0) {
        // If you're at the top and press ArrowUp, nothing happens
        return null;
      } else if (options[currentIndex - 1].hasOwnProperty("separator")) {
        // If you hit a separator, skip to next viable option
        return options[currentIndex - 2];
      } else {
        return options[currentIndex - 1];
      }
    } else if (evt.key === "Enter") {
      this.setState({ previouslySelectedItem: null });
    } else if (evt.key === "Escape") {
      this.setState((prevState) => ({
        previouslySelectedItem: null,
        selectedItem: prevState.previouslySelectedItem,
        optionsOpen: false,
      }));
    }
  };

  selectOption = (option) => {
    if (this.props.value) {
      if (option.key === DESELECT_KEY) {
        this.setState({ selectedItem: option, deselected: true }, () =>
          this.props.optionSelected(undefined),
        );
      } else {
        this.props.optionSelected(option);
      }
      this.setState({
        optionsOpen: false,
        previouslySelectedItem: this.props.value,
      });
    } else {
      this.setState(
        (prevState) => ({
          previouslySelectedItem: prevState.selectedItem,
          selectedItem: option,
          optionsOpen: false,
          deselected: option.key === DESELECT_KEY,
        }),
        () =>
          option.key === DESELECT_KEY && this.props.optionSelected(undefined),
      );
    }
  };
}

const customElement = reactToCustomElement(CpsShortSingleSelect, {
  parentClass: HTMLElement,
  properties: [
    "labelText",
    "ariaLabelText",
    "placeholderText",
    "options",
    "initialSelection",
    "optionSelected",
    "value",
    "isDisabled",
    "width",
    "listWidth",
    "isListWrapped",
    "deselectText",
  ],
});
customElements.define("cps-short-single-select", customElement);
export const CprShortSingleSelect = customElementToReact({
  name: "cps-short-single-select",
});

const styles = k`
  .label {
    display: block;
    padding-bottom: 4px;
  }

  /* 'label.hidden' is used to provide greater specificity in order to override other common canopy styles such as '.cps-form-group label' which set a display value */
  .hidden, label.hidden {
    display: none;
  }

  .open {
    display: block;
  }

  .selectedOption {
    background-color: var(--cps-color-chrome);
  }

  button.button, button.button:focus, button.button:hover {
    border-radius: 5px;
    border: 1px solid var(--cps-color-athens);
    min-width: 152px;
    max-width: 100%;
    height: 32px;
    background-color: white;
    padding: 0 8px 0 10px;
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .noOverflow {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  button.button:focus {
    border: 1px solid var(--cps-color-primary);
  }

  button:disabled, button:disabled:hover {
    border: 1px dashed var(--cps-color-athens);
    color: var(--cps-color-af);
    background-color: var(--cps-color-ash)
  }

  .button.unselected, .button.optionsOpen {
    color: var(--cps-color-af);
  }

  .listbox {
    padding: 0;
    list-style-type: none;
    background-color: white;
    border-radius: 5px;
    border: 1px solid var(--cps-color-athens);
    box-shadow: 0 10px 40px -24px rgba(75,78,83,0.40);
    outline: none;
    max-height: 282px; /* 7 items * 40px + 2px for borders */
    overflow-y: auto;
    position: absolute;
    z-index: 10;
    min-width: 152px;
    max-width: 100%;
  }

  .listbox:focus {
    outline: none;
  }

  .option {
    min-height: 40px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    cursor: default;
  }

  .option:hover {
    background-color: var(--cps-color-chrome);
  }

  .separator {
    border-bottom: 1px solid #e2e2e2;
    height: 0px !important;
    margin-top: 15px;
    margin-bottom: 16px;
  }

  .select {
    position: relative;
  }

  .caret {
    flex-grow: 0;
    display: flex;
    align-items: center;
  }

`;
