import React from "react";
import { a } from "kremling";
import { noop, isNil, isString } from "lodash";
import moment from "moment";

import styles from "./cps-datepicker.styles.css";
import { defaultDateFormat } from "./cps-datepicker.helpers.js";
import Calendar from "./cps-calendar.component.js";
import { CprIcon } from "./../cps-icon/cps-icon.component";

export class CprDatepicker extends React.Component {
  static defaultProps = {
    format: defaultDateFormat,
    inputClass: "",
    valid: true,
    disabled: false,
    containerClass: "",
    setFocus: noop,
  };

  constructor(props) {
    super(props);

    checkDateValidity(props.date);
    const hasId = !isNil(props.id);
    if (!hasId) {
      console.warn(
        "Provide an id to CpsDatepicker to associate the input with an external label element for accessibility.",
      );
    } else if (hasId && !isString(props.id)) {
      throw Error("id should be a string");
    }

    this.state = {
      showCalendar: false,
      dateString: props.date ? moment(props.date).format(props.format) : "",
    };
  }

  componentDidMount() {
    this.props.setFocus(() => {
      this.focus();
    });
  }

  componentDidUpdate(prevProps) {
    const { date, format } = this.props;
    checkDateValidity(date);

    if (prevProps.date !== date) {
      this.setState({
        dateString: date
          ? moment(date).format(format || defaultDateFormat)
          : "",
      });
    }
  }

  render() {
    const {
      className,
      containerClass,
      date,
      disabled,
      inputClass,
      nonPopup,
      orientation,
      placeholder,
      positionAdjustment,
      removeDateOption,
      removeDateText,
      valid,
      width,
      id,
    } = this.props;

    return (
      <div
        className={a("cps-form-group")
          .a(styles.container)
          .a(className)
          .a(containerClass)
          .m("cps-has-error", !valid)}
        onKeyDown={this.handleKeyDown}
      >
        {this.state.showCalendar && (
          <div
            onMouseDown={this.dontBlur}
            style={getOrientation(orientation, positionAdjustment)}
            className={`${nonPopup ? "" : styles.popupDatepicker}`}
          >
            <Calendar
              width={width}
              removeDateOption={removeDateOption}
              removeDateText={removeDateText}
              selectDate={this.selectDate}
              date={date}
            />
          </div>
        )}
        <input
          type="text"
          ref={(ref) => (this.input = ref)}
          placeholder={placeholder}
          value={this.state.dateString}
          onChange={noop}
          onInput={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          className={`${inputClass} cps-form-control`}
          autoComplete="off"
          disabled={disabled}
          id={id}
        />
        {!valid && (
          <span className="cps-form-control-feedback" aria-hidden={true}>
            <CprIcon name={"alert-triangle-open-large"} />
          </span>
        )}
      </div>
    );
  }

  handleKeyDown = (evt) => {
    //enter and tab keys respectively
    if (this.state.showCalendar && (evt.keyCode === 13 || evt.keyCode === 9)) {
      this.setState({
        showCalendar: false,
      });
    }
  };

  dontBlur = () => {
    this.stopBlurEvent = true;
  };

  selectDate = (date) => {
    this.props.events?.datechange?.({
      detail: date ? new Date(date) : null,
    });

    this.setState({
      showCalendar: false,
    });

    this.stopBlurEvent = false;
    this.blurNoChange = true;
    this.input.blur();
  };

  focus = () => {
    this.input && this.input.focus();
  };

  handleChange = (e) => {
    this.setState({
      dateString: e.target.value,
    });
  };

  handleFocus = () => {
    this.setState({
      showCalendar: true,
    });
  };

  handleBlur = (e) => {
    if (this.stopBlurEvent) {
      e.preventDefault();
      this.stopBlurEvent = false;
      e.target.focus();
      return;
    }

    if (this.blurNoChange) {
      this.blurNoChange = false;
      return;
    }

    const momentDate = moment(e.target.value, [
      defaultDateFormat,
      this.props.format,
    ]);
    const date = momentDate.isValid() ? momentDate.toDate() : new Date();

    this.props.events?.datechange?.({
      detail: e.target.value ? date : null,
    });

    this.setState({
      showCalendar: false,
    });
  };
}

function getOrientation(orientation, adjustment = {}) {
  const { top, bottom, right, left } = adjustment;

  if (orientation === "bottom")
    return { top: `calc(100% + ${4 + (bottom || 0)}px)` };

  if (orientation === "bottom left")
    return {
      top: `calc(100% + ${4 + (bottom || 0)}px)`,
      right: `${0 + left || 0}px`,
    };

  if (orientation === "bottom right")
    return {
      top: `calc(100% + ${4 + (bottom || 0)}px)`,
      left: `${0 + right || 0}px`,
    };

  if (orientation === "top")
    return { bottom: `calc(100% + ${4 + (top || 0)}px)` };

  if (orientation === "top left")
    return {
      bottom: `calc(100% + ${4 + (top || 0)}px)`,
      right: `${0 + left || 0}px`,
    };

  if (orientation === "top right")
    return {
      bottom: `calc(100% + ${4 + (top || 0)}px)`,
      left: `${0 + right || 0}px`,
    };
}

function checkDateValidity(date) {
  if (date && !moment(date).isValid()) {
    throw Error("Date provided to CprDatepicker was not valid.");
  }
}
