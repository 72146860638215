import React from "react";
import styles from "./cps-icon.styles.css";
import { a } from "kremling";
import { iconMigrateList } from "./icon-migrate";

export default class CpsIcon extends React.Component {
  render() {
    const { name, size = 24, className, ...rest } = this.props;
    const icon = iconMigrateList[name] || name;
    return (
      <svg
        {...rest}
        className={a(styles.icon, icon, className)}
        style={{ width: `${size}px`, height: `${size}px` }}
        viewBox="0 0 24 24"
      >
        <use href={`#${icon}`} xlinkHref={`#${icon}`} />
      </svg>
    );
  }
}

export const CprIcon = CpsIcon;
