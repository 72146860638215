import React, {
  useRef,
  useEffect,
  useState,
  ReactNode,
  CSSProperties,
  FC,
} from "react";
import { useCss, a } from "kremling";
import ReactDOM from "react-dom";
import { LazyMotion, m, AnimatePresence, domAnimation } from "framer-motion";

import { ModalContext, ModalMount, durationInMillis } from "./modal.utils";
import { useClickEventStack, useKeydownEventStack } from "../../hooks/index";

const transition = {
  duration: durationInMillis / 1000,
  ease: "easeInOut",
};

type ModalContainerComponentProps = {
  animScale?: number;
  animRotateX?: number;
  children: ReactNode;
  className?: string;
  onAfterClose?: () => void;
  onAfterOpen?: () => void;
  onClose?: () => void;
  show?: boolean;
  styles?: CSSProperties;
};

export const ModalContainerComponent: FC<ModalContainerComponentProps> = ({
  animScale,
  animRotateX,
  children,
  className,
  onAfterClose,
  onAfterOpen,
  onClose,
  show,
  styles = {},
}) => {
  const scope = useCss(css);
  const modalRef = useRef<HTMLDivElement>(null);
  const [openAnimationDone, setOpenAnimationDone] = useState(false);
  useClickEventStack(() => {}, !show);
  useKeydownEventStack({
    keys: ["Escape", "Esc"],
    callback: onClose,
    disabled: !show,
  });

  useEffect(() => {
    // Don't apply focus if the focus is already on one of its children
    const activeElement = document.activeElement as HTMLElement;
    if (modalRef.current && modalRef.current.contains(activeElement)) return;

    // Set focus to first focusable element in current modal
    // need to find if there is an element to focus on during open and close in case two modals are open
    const modals = document.querySelectorAll(".cp-modal-container");
    if (modals.length) {
      const focusableElements =
        "button, [href], input, select, textarea, [tabindex]:not([tabindex='-1']), div.cp-modal";
      const firstFocusableElement = modals[modals.length - 1].querySelector(
        focusableElements,
      ) as HTMLElement | null;

      firstFocusableElement?.focus();
    }
  }, [show]);

  return ReactDOM.createPortal(
    <LazyMotion features={domAnimation}>
      <ModalContext.Provider value={{ onClose }}>
        <AnimatePresence onExitComplete={() => onAfterClose?.()}>
          {show && (
            <m.div
              {...scope}
              transition={transition}
              className="cp-modal-container"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onAnimationComplete={() => {
                if (!openAnimationDone) {
                  setOpenAnimationDone(true);
                  onAfterOpen?.();
                }
              }}
            >
              <div className="cp-modal-backdrop" />
              <m.div
                className={a("cp-modal cp-card-l3").a(className)}
                transition={transition}
                style={styles}
                tabIndex={-1}
                ref={modalRef}
                initial={{ rotateX: animRotateX, scale: animScale }}
                animate={{ rotateX: 0, scale: 1 }}
                exit={{ rotateX: animRotateX, scale: animScale }}
              >
                {children}
              </m.div>
              <ModalMount />
            </m.div>
          )}
        </AnimatePresence>
      </ModalContext.Provider>
    </LazyMotion>,
    document.body,
  );
};

// language=scss
const css = `
  .cp-modal-container {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100001;
    perspective: 20rem;
    transform: translate(0);
  }

  .cp-modal-backdrop {
    position: absolute;
    inset: 0;
    background-color: rgba(var(--cps-color-primary-text-rgb), .4);
    z-index: 0;
  }

  .cp-modal {
    position: relative;
    background-color: #fff;
    margin: 3.2rem auto;
    z-index: 1;
    box-shadow: var(--cp-box-shadow-l3);
    transform-origin: center center;
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 3.2rem);
  }

  .cp-modal:focus {
    outline: none;
  }

  .cp-modal__body {
    padding: 1.6rem;
    min-height: 6.4rem;
    overflow: auto;
  }
`;
