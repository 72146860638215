import { Children } from "react";
import { oneOfType, bool, arrayOf, any } from "prop-types";

export function CpSwitch({ expression, children }) {
  const _children = Children.toArray(children);
  let defaultChild = null;
  let result = null;

  for (let i = 0; i < _children.length; i++) {
    const child = _children[i];
    if (!child?.props) continue;

    const value = child?.props?.value;
    const _default = !!child?.props?.default;
    const values = Array.isArray(value) ? value : [value];
    const match = values.some((v) => v === expression);

    if (_default) {
      if (defaultChild) {
        throw Error(`[CpSwitch] Only one default case allowed.`);
      }
      defaultChild = child;
    }

    if (match) {
      result = child;
      break;
    }
  }

  if (!result && defaultChild) {
    result = defaultChild;
  }

  return result;
}

CpSwitch.propTypes = {
  expression: any,
};

export function CpCase({ children }) {
  return children;
}

CpCase.propTypes = {
  value: oneOfType([arrayOf(any), any]),
  default: bool,
};
