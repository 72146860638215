import React from "react";
import { m } from "kremling";
import { isEmpty, isNumber, isBoolean } from "lodash";
import { EmptyCell } from "../cells";
import styles from "./table-body.styles.css";
import { ColumnId, TableSchema } from "../types";
import { Selection } from "../hooks/use-table-selection.hook";

function Cell({
  columnId,
  aggregatedWidth,
  resource,
  schema,
  rowIsDisabled,
  columnWidth,
  selection,
  resourceIndex,
  onSelect,
}: {
  columnId: ColumnId;
  aggregatedWidth: number | null;
  resource: any;
  schema: TableSchema;
  rowIsDisabled?: boolean;
  columnWidth: number;
  selection: Selection | null;
  resourceIndex: number;
  onSelect:
    | ((evt: React.MouseEvent, resource: any, resourceIndex: number) => void)
    | null;
}) {
  const columnSchema = schema[columnId];
  if (!columnSchema) return null;

  const CellComponent = columnSchema.cell.component;
  const value = columnSchema.fieldId ? resource[columnSchema.fieldId] : null;
  const formattedValue = columnSchema.cell.formatValue
    ? columnSchema.cell.formatValue(value)
    : value;
  const cellIsEmpty =
    isNumber(formattedValue) || isBoolean(formattedValue)
      ? false
      : isEmpty(formattedValue);
  const showEmpty =
    cellIsEmpty &&
    !CellComponent.overrideEmptyCell &&
    columnSchema.dataType !== "component";

  const schemaProps = columnSchema.cell.props;
  const props =
    typeof schemaProps === "function"
      ? schemaProps({ resource, value })
      : schemaProps;

  function renderCell() {
    if (showEmpty) {
      return <EmptyCell isDisabled={rowIsDisabled} />;
    }
    return (
      <CellComponent
        isDisabled={rowIsDisabled}
        resource={resource}
        resourceIndex={resourceIndex}
        columnId={columnId}
        columnSchema={columnSchema}
        value={formattedValue}
        selection={selection}
        onSelect={onSelect}
        {...props}
      />
    );
  }

  return (
    <td
      className={m(styles.stickyCell, !!columnSchema.sticky)
        .m(`cp-text-${props?.align}`, props?.align)
        .m("cp-ellipsis", columnSchema.dataType !== "component")
        .m(styles.forceVerticalBorder, columnSchema.showVerticalBorder === true)
        .m(
          styles.forceNoVerticalBorder,
          columnSchema.showVerticalBorder === false,
        )}
      style={
        !!columnSchema.sticky && aggregatedWidth !== null
          ? {
              left: `${(aggregatedWidth - columnWidth) / 10}rem`,
            }
          : {}
      }
    >
      {renderCell()}
    </td>
  );
}
export const MemoCell = React.memo(Cell);
