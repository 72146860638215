import { createContext, Dispatch, SetStateAction } from "react";

export type ChildIds = Record<string | number, string | number>;

export const CpSideMenuAccordionContext = createContext<{
  accordionId: string;
  childIds: ChildIds;
  setChildIds: Dispatch<SetStateAction<ChildIds>>;
}>({
  accordionId: "",
  childIds: {},
  setChildIds: () => {},
});

export const CpSideMenuAccordionButtonContext = createContext<{
  isActive: boolean;
  accordionId: string;
}>({ isActive: false, accordionId: "" });
