// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filter-header-styles__label--QGe1E {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://src/components/cp-table/headers/filter-header.styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB","sourcesContent":[".label {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "filter-header-styles__label--QGe1E"
};
export default ___CSS_LOADER_EXPORT___;
