import React, {
  CSSProperties,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useCss, a } from "kremling";
import Tippy from "@tippyjs/react/headless";
import type { Instance, Props as TippyProps, Placement } from "tippy.js";

import { useIsMounted } from "../../hooks";
import styles from "./cp-dropdown.styles.scss";
import {
  useContentWidth,
  useDropdownPlacementConversion,
  getAnimationDuration,
  useAutoDisable,
  hideOnEsc,
} from "./cp-dropdown.utils";
import { createPortal } from "react-dom";
import { clickEventStack } from "../../helpers";
import { ClickEventStackAddResponse } from "../../helpers/click-event-stack/click-event-stack";

const animationDuration = getAnimationDuration();

export type CpDropdownRef = {
  open: () => void;
  close: () => void;
  toggle: (e: React.MouseEvent) => void;
  isOpen: boolean;
};

export type CpDropdownProps = {
  allowContentClicks?: boolean;
  appendTo?: HTMLElement;
  contentHeight?: CSSProperties["maxHeight"];
  contentWidth?: "sm" | "md" | "lg" | "block" | CSSProperties["width"];
  cover?: boolean;
  disabled?: boolean;
  position?:
    | Placement
    // deprecated:
    | "top-left"
    | "top-right"
    | "right-top"
    | "right-bottom"
    | "bottom-right"
    | "bottom-left"
    | "left-bottom"
    | "left-top";
  onClose?: () => void;
  onOpen?: () => void;
  preventOutsideClickUntilClosed?: boolean;
  renderContent: (opts: {
    isOpen: boolean;
    close: CpDropdownRef["close"];
  }) => ReactElement;
  renderTrigger: (opts: CpDropdownRef) => ReactElement;
  renderWhenClosed?: boolean;
};

export const CpDropdown = forwardRef<CpDropdownRef, CpDropdownProps>(
  function CpDropdown(props, ref) {
    const {
      allowContentClicks = false,
      appendTo = document.body,
      contentHeight = "auto",
      contentWidth = "sm",
      cover,
      disabled,
      position = "bottom",
      onClose,
      onOpen,
      preventOutsideClickUntilClosed,
      renderContent,
      renderTrigger,
      renderWhenClosed = true,
    } = props;
    const scope = useCss(styles);
    const unmountTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
    const [isOpen, setIsOpen] = useState(false);
    const [instance, setInstance] = useState<Instance | null>(null);
    const isMounted = useIsMounted();
    const calculatedContentWidth = useContentWidth(contentWidth);
    const placement = useDropdownPlacementConversion(position);
    const clickEventStackRef = useRef<ClickEventStackAddResponse>();
    const [isVisible, setIsVisible] = useState(false);

    useLayoutEffect(() => {
      if (isOpen) {
        setTimeout(() => {
          instance?.popperInstance?.update();
        }, 100);
      }
      /* Add missing deps and verify it doesn't break: instance */
    }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      return () => {
        if (!preventOutsideClickUntilClosed) {
          clickEventStackRef.current?.remove();
        }
      };
      /* Add missing deps and verify it doesn't break: preventOutsideClickUntilClosed */
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const documentClickHandler = useCallback(
      (e: MouseEvent) => {
        if (!instance) return;
        // pressing the trigger - don't do anything
        const target = e.target as Element;
        if (instance.reference.contains(target)) return;
        const contains = instance.popper.contains(target);
        if ((contains && !allowContentClicks) || !contains) {
          close();
        }
        // todo: Add missing deps: close (needs to be memoized or put in a ref)
      },
      [instance, allowContentClicks], // eslint-disable-line react-hooks/exhaustive-deps
    );

    function open() {
      instance?.show();
    }

    function close() {
      if (!preventOutsideClickUntilClosed) {
        clickEventStackRef.current?.remove();
      }
      instance?.hide();
    }

    function toggle() {
      if (isOpen) {
        close();
      } else {
        open();
      }
    }

    function onShow({ popper, reference }: Instance) {
      if (instance) {
        if (onOpen) onOpen();
        if (contentWidth === "block") {
          if (placement.includes("left") || placement.includes("right")) {
            popper.style.height =
              reference.getBoundingClientRect().height / 10 + "rem";
          } else {
            popper.style.width =
              reference.getBoundingClientRect().width / 10 + "rem";
          }
        }
        if (!preventOutsideClickUntilClosed) {
          clickEventStackRef.current =
            clickEventStack.add(documentClickHandler);
        }
      }
    }

    function onHide({ unmount }: Instance) {
      if (isMounted.current) {
        setIsOpen(false);
        if (onClose) onClose();
      }
      // wait for animation to finish before unmounting
      unmountTimeoutRef.current = setTimeout(() => {
        if (instance && isMounted.current) {
          setIsVisible(false);
          unmount();
        }
      }, animationDuration);
    }

    function onMount() {
      setIsOpen(true);
      setIsVisible(true);

      if (unmountTimeoutRef.current) {
        clearTimeout(unmountTimeoutRef.current);
      }
    }

    const coverOffset: TippyProps["offset"] = [0, 0];
    if (cover && instance) {
      const rect = instance.reference.getBoundingClientRect();
      if (placement.includes("bottom") || placement.includes("top")) {
        coverOffset[1] = rect.height * -1;
      } else if (placement.includes("left") || placement.includes("right")) {
        coverOffset[1] = rect.width * -1;
      }
    }

    useImperativeHandle(ref, () => ({
      open,
      close,
      toggle,
      isOpen,
    }));

    useAutoDisable(disabled, isOpen, close);

    return (
      <Tippy
        allowHTML
        animation
        appendTo={appendTo}
        disabled={disabled}
        hideOnClick={false}
        interactive
        offset={coverOffset}
        onCreate={setInstance}
        onHide={onHide}
        onMount={onMount}
        onShow={onShow}
        maxWidth={240}
        placement={placement}
        plugins={[hideOnEsc]}
        trigger="manual"
        zIndex={100001}
        popperOptions={{
          modifiers: [
            {
              name: "preventOverflow",
              options: {
                altAxis: true,
              },
            },
          ],
        }}
        render={(attrs) => {
          return (
            <>
              {preventOutsideClickUntilClosed &&
                isOpen &&
                createPortal(
                  <div
                    {...scope}
                    className="cp-dropdown__prevent-click"
                    onClick={(e) => {
                      e.stopPropagation();
                      close();
                    }}
                  />,
                  document.body,
                )}
              <div
                {...attrs}
                {...scope}
                className={a("cp-dropdown").m("cp-dropdown--is-open", isOpen)}
                tabIndex={-1}
                style={{
                  maxHeight: contentHeight,
                  width: calculatedContentWidth,
                }}
              >
                {(isVisible || renderWhenClosed) &&
                  renderContent({ isOpen, close })}
                <div className="cp-tooltip-arrow" data-popper-arrow="" />
              </div>
            </>
          );
        }}
      >
        {renderTrigger?.({
          isOpen,
          toggle,
          open,
          close,
        })}
      </Tippy>
    );
  },
);
