type ClassnameValue = string | undefined | null | false;

const tw =
  (prefix: string) =>
  (...args: ClassnameValue[]): string => {
    return args
      .join(" ")
      .split(" ")
      .filter(Boolean)
      .map((cn) => `${prefix}-${cn}`)
      .join(" ");
  };

const always = (...args: ClassnameValue[]): string => {
  return args.join(" ").split(" ").filter(Boolean).join(" ");
};

const maybe = (enabled: unknown, ...classname: ClassnameValue[]): string => {
  return !!enabled ? classname.filter(Boolean).join(" ") : "";
};

const toggle = (
  enabled: unknown,
  classnameTrue: ClassnameValue | ClassnameValue[],
  classnameFalse: ClassnameValue | ClassnameValue[],
): string => {
  const one = Array.isArray(classnameTrue)
    ? classnameTrue.filter(Boolean).join(" ")
    : classnameTrue || "";
  const two = Array.isArray(classnameFalse)
    ? classnameFalse.filter(Boolean).join(" ")
    : classnameFalse || "";
  return !!enabled ? one : two;
};

export function generateClassNameHelpers(prefix?: string) {
  return {
    tw: tw(prefix || ""),
    always,
    maybe,
    toggle,
  };
}
