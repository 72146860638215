import { CpTable } from "@components";
import {
  TableSchema,
  ColumnId,
  DataType,
  CellComponent,
  FilterComponent,
  RawColumnSchema,
  RawTableSchema,
  ColumnSchema,
} from "./types";
import { SelectAllHeader } from "./headers/select-all-header.component";
import { SelectCell } from "./cells";

export const columnWidthsPx: Record<string, number> = {
  sm: 110,
  md: 200,
  lg: 300,
};

// Returns a number representing the width of the column in pixels
export function getColumnWidthPx(
  widthValue?: string | number,
): number | undefined {
  if (!widthValue) return undefined;
  return typeof widthValue === "number"
    ? widthValue
    : columnWidthsPx[widthValue];
}

// Returns a copy of colsToSort sorted by the columnOrder
export function sortColumns(
  schema: TableSchema,
  colsToSort: ColumnId[],
  columnOrder: ColumnId[],
): ColumnId[] {
  return [...colsToSort].sort((a, b) => {
    if (schema[a]?.sticky === "left" && schema[b]?.sticky !== "left") return -1;
    if (schema[a]?.sticky !== "left" && schema[b]?.sticky === "left") return 1;

    let indexA = columnOrder.indexOf(a);
    let indexB = columnOrder.indexOf(b);
    // If index cannot be found then push these columns to the end of the list
    if (indexA === -1) indexA = Infinity;
    if (indexB === -1) indexB = Infinity;

    return indexA - indexB;
  });
}

function getCellComponentFromDataType(
  dataType: DataType,
): CellComponent<any> | null {
  switch (dataType) {
    case "string":
    case "number":
      return CpTable.TextCell;
    case "bool":
      return CpTable.BoolCell;
    case "date_iso":
    case "date_unix_ms":
      return CpTable.DateCell;
    case "array":
      return CpTable.ListCell;
    default:
      return null;
  }
}

function getFilterComponentFromDataType(
  dataType: DataType,
): FilterComponent<any> {
  switch (dataType) {
    case "bool":
      return CpTable.BoolFilter;
    case "date_iso":
    case "date_unix_ms":
      return CpTable.DateFilter;
    default:
      return CpTable.InputFilter;
  }
}

function getFieldId(
  columnSchema: RawColumnSchema,
  type: "filter" | "sort",
): string {
  const typeObj = columnSchema[type];
  if (typeof typeObj === "object" && typeObj.fieldId) {
    return typeObj.fieldId;
  } else if (columnSchema.fieldId) {
    return columnSchema.fieldId;
  }
  throw new Error(`${columnSchema.id} is missing a fieldId for ${type}`);
}

function formatColumnSchema(
  columnId: ColumnId,
  columnSchema: RawColumnSchema,
): ColumnSchema {
  const derivedCell = getCellComponentFromDataType(columnSchema.dataType);

  return {
    ...columnSchema,
    id: columnId,
    cell: {
      component:
        derivedCell || columnSchema?.cell?.component || CpTable.TextCell,
      ...columnSchema.cell,
    },
    filter: columnSchema.filter
      ? {
          component: getFilterComponentFromDataType(columnSchema.dataType),
          fieldId: getFieldId(columnSchema, "filter"),
          ...(typeof columnSchema.filter === "object" && columnSchema.filter),
        }
      : false,
    sort: columnSchema.sort
      ? {
          fieldId: getFieldId(columnSchema, "sort"),
          ...(typeof columnSchema.sort === "object" && columnSchema.sort),
        }
      : false,
  };
}

// Takes a schema defined by the consumer and returns a fully formed schema with default values and other useful info
export function formatSchema(schema: RawTableSchema) {
  const newSchema: TableSchema = {
    // Add a default select column if one is not provided.
    // This will only render if the selection prop has been passed.
    select: formatColumnSchema("select", {
      sticky: "left",
      header: {
        component: SelectAllHeader,
      },
      cell: {
        component: SelectCell,
      },
      width: 40,
      resizable: false,
      showVerticalBorder: false,
      ...(schema.select || {}),
      dataType: "component",
    }),
  };

  for (const columnId in schema) {
    if (columnId === "select") continue;
    newSchema[columnId] = formatColumnSchema(columnId, schema[columnId]);
  }
  return newSchema;
}
