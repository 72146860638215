import React from "react";
import styles from "./cp-table-footer.styles.css";
import { string, node } from "prop-types";

export function CpTableFooter({ className, children }) {
  return (
    <div className={`${styles.footer} ${className || ""}`}>{children}</div>
  );
}

CpTableFooter.propTypes = {
  className: string,
  children: node,
};
