import React, { ComponentProps } from "react";
import { a } from "kremling";

export type CardBodyProps = ComponentProps<"div">;

export const CardBody = ({ children, className, ...rest }: CardBodyProps) => (
  <div className={a("cp-p-16", className)} data-testid="cp-card-body" {...rest}>
    {children}
  </div>
);
