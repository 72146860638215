import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useCss, a } from "kremling";
import Tippy from "@tippyjs/react";
import { bool, func, number, oneOf, oneOfType, string } from "prop-types";
import { useIsMounted } from "@hooks";

import {
  getAnimationDuration,
  useAutoDisable,
  useContentWidth,
  hideOnEsc,
  useDropdownPlacementConversion,
  dropdownPositionOptions,
} from "../cp-dropdown/cp-dropdown.utils";
import styles from "../cp-dropdown/cp-dropdown.styles.scss";
import { createPortal } from "react-dom";
import { clickEventStack, elementHasClass } from "@helpers";

const animationDuration = getAnimationDuration();

export const CpContextDropdown = forwardRef(
  function CpContextDropdown(props, ref) {
    const {
      allowContentClicks = props.backdrop || props.allowContentClicks,
      appendTo = document.body,
      backdrop,
      contentHeight = "auto",
      contentWidth = "sm",
      disabled,
      position = "bottom",
      onClose,
      onOpen,
      renderContent,
    } = props;
    const scope = useCss(styles);
    const [isOpen, setIsOpen] = useState(false);
    const unmountTimeoutRef = useRef();
    const eventStackRef = useRef(null);
    const [instance, setInstance] = useState(null);
    const [refEl, setRefEl] = useState(<div />);
    const isMounted = useIsMounted();
    const calculatedContentWidth = useContentWidth(contentWidth);
    const placement = useDropdownPlacementConversion(position);

    // todo: Add missing deps and verify it doesn't break: isOpen, close
    useEffect(() => {
      if (disabled && isOpen) close();
    }, [disabled]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      return () => {
        if (eventStackRef?.current) eventStackRef.current.remove();
      };
    }, []);

    function onMount() {
      if (unmountTimeoutRef.current) {
        clearTimeout(unmountTimeoutRef.current);
      }
    }

    function onShow() {
      setIsOpen(true);
      if (onOpen) onOpen();
      setTimeout(() => {
        eventStackRef.current = clickEventStack.add(documentClickHandler);
      }, 0);
    }

    function onHide({ unmount }) {
      if (isMounted.current) {
        setIsOpen(false);
        if (onClose) onClose();
      }
      if (eventStackRef?.current) eventStackRef.current.remove();
      // wait for animation to finish before unmounting
      unmountTimeoutRef.current = setTimeout(() => {
        if (instance && isMounted.current) {
          unmount();
        }
      }, animationDuration);
    }

    function open(rect = {}) {
      setRefEl(
        <div
          style={{
            position: "absolute",
            height: 0,
            width: 0,
            ...rect,
          }}
        />,
      );
      instance.show();
      if (onOpen) onOpen();
    }

    function close() {
      if (instance) instance.hide();
      if (onClose) onClose();
    }

    function toggle() {
      if (isOpen) {
        close();
      } else {
        open();
      }
    }

    if (ref) {
      const current = {
        open,
        close,
        toggle,
        isOpen,
      };
      if (typeof ref === "function") {
        ref({ current });
      } else {
        ref.current = current;
      }
    }

    useAutoDisable(disabled, isOpen, close);

    // todo: Add missing deps and verify it doesn't break: close
    const documentClickHandler = useCallback(
      (e) => {
        if (!instance) return;
        const contains =
          instance.popper.contains(e.target) ||
          elementHasClass(e.target, ["cp-dropdown-backdrop", "cp-dropdown"]);
        const isTrigger = instance.reference.contains(e.target);
        if (isTrigger) return;
        if ((contains && !allowContentClicks) || !contains) {
          close();
        }
      },
      [instance, allowContentClicks], // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
      <Tippy
        allowHTML
        animation
        appendTo={appendTo}
        disabled={disabled}
        interactive
        hideOnClick={false}
        onCreate={setInstance}
        offset={[0, 0]}
        onHide={onHide}
        onShow={onShow}
        onMount={onMount}
        placement={placement}
        plugins={[hideOnEsc]}
        trigger="click"
        zIndex={100001}
        render={(attrs) => {
          return (
            <>
              {!!backdrop &&
                isOpen &&
                createPortal(
                  <div
                    {...scope}
                    data-testid="backdrop"
                    className="cp-dropdown-backdrop"
                  />,
                  document.body,
                )}
              <div
                {...attrs}
                {...scope}
                className={a("cp-dropdown").m("cp-dropdown--is-open", isOpen)}
                tabIndex="-1"
                onClick={() => {
                  if (!backdrop && !allowContentClicks) {
                    close();
                  }
                }}
                style={{
                  maxHeight: contentHeight,
                  width: calculatedContentWidth,
                }}
              >
                {renderContent({ isOpen, close })}
                <div className="cp-tooltip-arrow" data-popper-arrow="" />
              </div>
            </>
          );
        }}
      >
        {refEl}
      </Tippy>
    );
  },
);

CpContextDropdown.propTypes = {
  allowContentClicks: bool,
  backdrop: bool,
  contentHeight: oneOfType([string, number]),
  contentWidth: oneOfType([number, oneOf(["sm", "md", "lg"])]),
  disabled: bool,
  onBackdropClick: func,
  onOpen: func,
  onClose: func,
  position: oneOf(dropdownPositionOptions),
  renderContent: func.isRequired,
};
