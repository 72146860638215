import React, { ComponentProps } from "react";
import { useCss, a } from "kremling";

export type CpHeaderProps = ComponentProps<"div"> & {
  border?: boolean;
  level?: 1 | 2 | 3;
};

export function CpHeader({
  children,
  border = true,
  className,
  level,
  ...rest
}: CpHeaderProps) {
  const scope = useCss(css);
  return (
    <div
      {...scope}
      className={a("cp-header-component")
        .m("cp-header-component__l1", level === 1)
        .m("cp-header-component__l2", level === 2 || !level)
        .m("cp-header-component__l3", level === 3)
        .m("cp-header-component__border", border)
        .a(className)}
      data-testid="cp-card-header"
      {...rest}
    >
      {children}
    </div>
  );
}

const css = `
.cp-header-component {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.6rem;
  flex-shrink: 0;
}

.cp-header-component__border {
  border-bottom: solid .1rem var(--cp-color-card-border);
}

.cp-header-component__l1 {
  height: 4.8rem;
}

.cp-header-component__l2 {
  height: 5.6rem;
}

.cp-header-component__l3 {
  height: 6.4rem;
}
`;
