import React from "react";
import { Controller } from "react-hook-form";
import { Sort } from "./sort.component";

export function SortController({ control, dataType, ascLabel, descLabel }) {
  return (
    <Controller
      name="sort"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Sort
          onChange={onChange}
          direction={value}
          dataType={dataType}
          ascLabel={ascLabel}
          descLabel={descLabel}
        />
      )}
    />
  );
}
