import { getColumnWidthPx } from "../cp-table.helper";
import { ColumnSchema, ColumnWidthMap } from "../types";

/*
  This hook is used to calculate the width of the columns in the table.
  At the moment this is only necessary for sticky columns so that we can properly set the `left` attribute.

  It returns the `aggregateWidth` function which is to be called upon each iteration of the column. This will add the width of the current column to the total width of the columns so far and return it as `aggregatedWidth`.
*/
export function useColumnWidths() {
  let aggregatedWidth = 0;

  return {
    /**
     * Adds the width of the provided column to the total width of the columns so far.
     * Specify resizingWidth for the width of the column while it is resizing via drag.
     */
    aggregateWidth: ({
      columnSchema,
      columnWidths,
      resizingWidth,
    }: {
      columnWidths?: ColumnWidthMap;
      columnSchema: ColumnSchema;
      resizingWidth?: number;
    }) => {
      const widthFromMap = columnWidths?.[columnSchema.id];
      const defaultWidth = getColumnWidthPx(
        columnSchema.width || "md",
      ) as number;

      const columnWidth =
        resizingWidth || getColumnWidthPx(widthFromMap) || defaultWidth;

      aggregatedWidth += columnWidth;

      return {
        columnWidth,
        aggregatedWidth,
      };
    },
  };
}
