import React from "react";
import { CpSelectSingle } from "@components";
import { useController } from "react-hook-form";
import { any, bool, object, string } from "prop-types";

export function CpSelectSingleField(props) {
  const {
    fieldName,
    control,
    controllerOptions,
    required,
    rules,
    ...cpSelectSingleProps
  } = props;

  let mRules = {};
  if (required) {
    mRules.required = "Field is required";
  }
  const mergedRules = { ...mRules, ...rules };

  const {
    field: { value, onBlur, onChange, ref },
    fieldState: { error },
  } = useController({
    name: fieldName,
    control,
    rules: mergedRules,
    ...controllerOptions,
  });

  return (
    <CpSelectSingle
      {...cpSelectSingleProps}
      error={error?.message}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      triggerRef={ref}
    />
  );
}

CpSelectSingleField.propTypes = {
  control: any.isRequired,
  controllerOptions: object,
  fieldName: string.isRequired,
  rules: object,
  required: bool,
};
