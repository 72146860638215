// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu-content-styles__spreadContents--\\+MLPA {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.menu-content-styles__betterMargins--Ai8vl {\n  margin: 12px 16px;\n}\n\n.menu-content-styles__applyFilter--eB4Rm {\n  border-top: 0.1rem solid var(--cp-color-app-border);\n  padding: 1.6rem;\n}\n\n.menu-content-styles__dateFilterWidth--n8QGO {\n  width: 200px;\n}\n", "",{"version":3,"sources":["webpack://src/components/cp-table-header-menu/menu-content.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mDAAmD;EACnD,eAAe;AACjB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".spreadContents {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.betterMargins {\n  margin: 12px 16px;\n}\n\n.applyFilter {\n  border-top: 0.1rem solid var(--cp-color-app-border);\n  padding: 1.6rem;\n}\n\n.dateFilterWidth {\n  width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spreadContents": "menu-content-styles__spreadContents--+MLPA",
	"betterMargins": "menu-content-styles__betterMargins--Ai8vl",
	"applyFilter": "menu-content-styles__applyFilter--eB4Rm",
	"dateFilterWidth": "menu-content-styles__dateFilterWidth--n8QGO"
};
export default ___CSS_LOADER_EXPORT___;
