import { useMemo } from "react";
import { formatSchema } from "../cp-table.helper";
import { RawTableSchema } from "../types";

export function useTableSchema(getSchema: () => RawTableSchema, deps = []) {
  const schema = useMemo(() => {
    return formatSchema(getSchema());
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
  return schema;
}
