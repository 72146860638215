export const conditionOpts = [
  {
    id: "is_any_of",
    name: "Is any of",
    validateHasValues: true,
  },
  {
    id: "is_all_of",
    name: "Is all of",
    validateHasValues: true,
  },
  {
    id: "is_not_any_of",
    name: "Is not any of",
    validateHasValues: true,
  },
  {
    id: "is_blank",
    name: "Is blank",
    validateHasValues: false,
  },
  {
    id: "has_any_value",
    name: "Has any value",
    validateHasValues: false,
  },
];
