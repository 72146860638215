import React, { Component, forwardRef, Fragment } from "react";

import { clearSelectionId, emptyStateItemId } from "./common/utils";
import { ItemClear } from "./items/item-clear.component";
import { ItemSingle } from "./items/item-single.component";
import { bool } from "prop-types";

export const CpSelectContent = forwardRef(function CpSelectContent(props, ref) {
  const {
    data,
    disableSelection,
    disabled,
    isGroupData,
    groupType,
    isMulti,
    onChange,
    renderGroup,
    renderItem,
    searchOnChange,
    searchValue,
    selectedId,
    selectItem,
    showResultsInContent,
    valueTransformed,
    clearable,
    renderEmptyState,
  } = props;

  function buildItem(item) {
    const Item = renderItem;
    const itemProps = {
      disableSelection,
      disabled,
      isSelected: item.id.toString() === selectedId,
      item,
      key: item.id,
      onChange,
      searchOnChange,
      searchValue,
      selectItem,
      value: valueTransformed,
    };
    if (item.id === clearSelectionId) {
      return <ItemClear {...itemProps} />;
    }
    if (item.id === emptyStateItemId) {
      return renderEmptyState({
        itemProps,
        itemId: emptyStateItemId,
        renderSingleItem: (name, subName, overline) => {
          return (
            <ItemSingle
              {...itemProps}
              item={{
                id: emptyStateItemId,
                name,
                subName,
                overline,
              }}
            />
          );
        },
      });
    }
    return <Item {...itemProps} />;
  }

  const hasData = !!data.length;
  let hasValue, showEmptyState, showSelected, shouldShowClearRow;
  if (isMulti) {
    hasValue = valueTransformed.length;
    showEmptyState =
      !hasData &&
      (searchValue || !valueTransformed.length || !showResultsInContent);
    showSelected =
      !hasData && !!valueTransformed.length && showResultsInContent;
  } else {
    hasValue = !!valueTransformed;
    shouldShowClearRow = hasValue && clearable;
    showEmptyState = !hasData && !shouldShowClearRow;
  }
  return (
    <>
      {!showEmptyState || renderEmptyState ? (
        <div className="cp-select-component__content-items" ref={ref}>
          {!!(hasValue || hasData) && (
            <>
              {shouldShowClearRow &&
                buildItem({ id: clearSelectionId, name: "Clear selection" })}
              {showSelected &&
                valueTransformed.map((item) => buildItem(item, data))}
              {isGroupData
                ? data.map((group, index) => {
                    const RenderGroup = renderGroup;
                    const isFirstSeparator =
                      groupType === "border" && index === 0;
                    return (
                      <Fragment key={group.id}>
                        {!isFirstSeparator && (
                          <RenderGroup
                            {...group}
                            count={group.data.length}
                            isSeparator={groupType === "border"}
                          />
                        )}
                        {group.data.map(buildItem)}
                      </Fragment>
                    );
                  })
                : data.map(buildItem)}
            </>
          )}
          {renderEmptyState && !hasData && buildItem({ id: emptyStateItemId })}
        </div>
      ) : (
        <div className="cp-select-component__empty">
          <div className="cp-select-component__empty-text">No Results</div>
          Please refine your search
        </div>
      )}
    </>
  );
});

export class RenderContent extends Component {
  static propTypes = {
    shouldUpdate: bool,
  };
  shouldComponentUpdate(nextProps) {
    return nextProps.shouldUpdate;
  }
  render() {
    return this.props.children;
  }
}
