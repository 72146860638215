import React from "react";
import { DateTime } from "luxon";
import { EmptyCell } from "./empty-cell.component";
import { m } from "kremling";

export function DateCell({ value, columnSchema, isDisabled }) {
  const { dataType } = columnSchema;
  let dateTime;
  if (dataType === "date_iso") {
    dateTime = DateTime.fromISO(value);
  } else if (dataType === "date_unix_ms") {
    dateTime = DateTime.fromMillis(value);
  } else {
    console.error(`Unsupported dataType '${dataType}' for DateCell`);
  }
  return dateTime ? (
    <span className={m("cp-table-cell-disabled", isDisabled)}>
      {dateTime.toLocaleString(DateTime.DATE_SHORT)}
    </span>
  ) : (
    <EmptyCell isDisabled={isDisabled} />
  );
}
