import React, { forwardRef, KeyboardEvent, MouseEvent, ReactNode } from "react";
import { useCss, a } from "kremling";
import { noop } from "lodash";

export type CpAreaProps = {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  focusInset?: boolean;
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
};

export const CpArea = forwardRef<HTMLDivElement, CpAreaProps>(
  function CpArea(props, ref) {
    const {
      children,
      className,
      disabled,
      focusInset,
      onKeyDown,
      onClick,
      ...rest
    } = props;
    const scope = useCss(css);

    function keyDownHandler(e: KeyboardEvent<HTMLDivElement>) {
      if (onKeyDown) onKeyDown(e);
      if (e.key === " " || e.key === "Enter" || e.key === "Spacebar") {
        e.preventDefault();
        (e.target as HTMLDivElement).click();
      }
    }

    return (
      <div
        {...scope}
        className={a("cp-area")
          .a(className)
          .m("cp-area--focus-inset", focusInset)}
        tabIndex={0}
        role="button"
        aria-disabled={disabled}
        onKeyDown={disabled ? noop : keyDownHandler}
        ref={ref}
        onClick={disabled ? noop : onClick}
        {...rest}
      >
        {children}
      </div>
    );
  },
);

const css = `
  .cp-area {
    cursor: pointer;
    position: relative;
    background-color: var(--cp-color-area-bg);
    display: block;
    color: var(--cp-color-primary-text);
    text-decoration: none;
    transition: box-shadow var(--cp-form-transition-duration) ease;
  }

  .cp-area:not([aria-disabled=true]):hover {
    background-color: var(--cp-color-area-hover-bg);
  }

  .cp-area:focus {
    box-shadow: var(--cp-form-focus-state);
    outline: none;
    z-index: 1;
  }

  .cp-area.cp-area--focus-inset:focus {
    box-shadow: var(--cp-form-focus-state-inset);
  }

  .cp-area[aria-disabled=true] {
    cursor: default;
  }
`;
