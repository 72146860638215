export function colorToPosition(color, monochromatic) {
  if (monochromatic) {
    return 100 - color.hsl().color[2];
  } else {
    return Math.floor((color.hsl().hue() / 360) * 100);
  }
}

export const lightness = "45";
export const saturation = "100";
