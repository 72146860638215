export const conditionOptsByDataType = {
  string: [
    {
      id: "contains",
      name: "Contains",
    },
    {
      id: "include_empty",
      name: "Show all blanks",
      disableInput: true,
    },
    {
      id: "starts_with",
      name: "Starts with",
    },
  ],
  number: [
    {
      id: "equal_to",
      name: "Equal to",
    },
    {
      id: "greater_than",
      name: "Greater than",
    },
    {
      id: "less_than",
      name: "Less than",
    },
    {
      id: "include_empty",
      name: "Show all blanks",
      disableInput: true,
    },
  ],
};
