import React from "react";
import { CpIcon } from "@components";
import { m } from "kremling";
import { EmptyCell } from "./empty-cell.component";

export function BoolCell({ value, isDisabled }) {
  return value ? (
    <span className={m("cp-table-cell-disabled", isDisabled)}>
      <CpIcon name="checkmark-large" />
    </span>
  ) : (
    <EmptyCell isDisabled={isDisabled} />
  );
}
BoolCell.overrideEmptyCell = true;
