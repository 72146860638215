import React from "react";
import { CpCheckbox } from "@components";

export function SelectAllHeader({ selection }) {
  return (
    <CpCheckbox
      checked={
        selection.length > 0 && !selection.allSelected
          ? "indeterminate"
          : selection.allSelected
      }
      onChange={(checked) =>
        checked ? selection.selectAll() : selection.deselectAll()
      }
    />
  );
}
