import React from "react";
import styles from "./cp-table-action-bar.styles.css";
import { string, node } from "prop-types";

export function CpTableActionBar({ className, children }) {
  return (
    <div className={`${styles.actionBar} ${className || ""}`}>{children}</div>
  );
}

CpTableActionBar.propTypes = {
  className: string,
  children: node,
};
