import React, { useState, useEffect, useRef } from "react";
import { defaultRangeExtractor, useVirtualizer } from "@tanstack/react-virtual";
import { throttle } from "lodash";
import { CpButton, CpTabs, CpSelectSingle } from "@components";

import styles from "./cp-tabs-dynamic.styles.css";

function isOverflowing(el) {
  if (!el) return false;
  return el.clientWidth < el.scrollWidth;
}
const overscan = 15; // The additional amount of items to render after the visible items

export function CpTabsDynamic({ className, tabsData, activeId, onChange }) {
  const [search, setSearch] = useState("");
  const [selectValue, setSelectValue] = useState();
  const [overflowing, setOverflowing] = useState(false);

  const tabContainerRef = useRef();
  const firstVisibleIndex = useRef();
  const lastVisibleIndex = useRef();

  const tabVirtualizer = useVirtualizer({
    getScrollElement: () => tabContainerRef.current,
    count: tabsData.length,
    estimateSize: () => 88,
    overscan,
    horizontal: true,
    rangeExtractor: (range) => {
      firstVisibleIndex.current = range.startIndex;
      lastVisibleIndex.current = range.endIndex;
      return defaultRangeExtractor(range);
    },
  });

  // Returns false if the target index is outside the (visible + overscan) range
  function canSmoothScroll(targetIndex) {
    return !(
      (targetIndex > lastVisibleIndex.current &&
        targetIndex - lastVisibleIndex.current > overscan) ||
      (targetIndex < firstVisibleIndex.current &&
        firstVisibleIndex.current - targetIndex > overscan)
    );
  }

  const tabVirtualizerRef = useRef();
  const onChangeRef = useRef();
  const prevActiveId = useRef();
  tabVirtualizerRef.current = tabVirtualizer;
  onChangeRef.current = onChange;
  useEffect(() => {
    if (!activeId || prevActiveId.current === activeId) return;
    setSelectValue(tabsData.find((td) => td.id === activeId));
    onChangeRef.current(activeId);
    prevActiveId.current = activeId;
    function scrollToIndex() {
      const targetIndex = tabsData.findIndex((view) => view.id === activeId);
      tabVirtualizerRef.current.scrollToIndex(targetIndex, {
        behavior: canSmoothScroll(targetIndex) ? "smooth" : "instant",
        align: "auto",
      });
    }

    requestAnimationFrame(() => scrollToIndex());
  }, [activeId, tabsData]);

  // Check overflow when window resizes
  useEffect(() => {
    const onResize = throttle(() => {
      setOverflowing(isOverflowing(tabContainerRef.current));
    }, 100);
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  // Check overflow after tabs have initialized or if the virtualizer has changed
  const virtualItemsLength = tabVirtualizer.getVirtualItems().length;
  useEffect(() => {
    if (tabContainerRef.current) {
      requestAnimationFrame(() => {
        setOverflowing(isOverflowing(tabContainerRef.current));
      });
    }
  }, [virtualItemsLength]);

  return (
    <div
      className={`${className || ""} cp-flex-center ${
        overflowing ? "" : "cp-ml-16"
      }`}
    >
      {overflowing && (
        <CpButton
          btnType="icon"
          icon="caret-large-left"
          aria-label="Scroll left"
          className="shrink-0"
          disabled={tabContainerRef.current?.scrollLeft <= 0}
          onClick={() => {
            const scrollLeft = tabContainerRef.current.scrollLeft;
            const nearest = tabVirtualizer
              .getVirtualItems()
              .reverse()
              .find((item) => item.start < scrollLeft);
            if (nearest) {
              tabVirtualizer.scrollToOffset(nearest.start, {
                behavior: "smooth",
              });
            }
          }}
        />
      )}
      <CpTabs className={`${styles.cptabs}`} ref={tabContainerRef}>
        <>
          <div
            className={styles.innerList}
            style={{ width: `${tabVirtualizer.getTotalSize()}px` }}
          >
            {tabVirtualizer.getVirtualItems().map((virtualRow) => {
              const tab = tabsData[virtualRow.index];
              return (
                <div
                  key={virtualRow.key + tab.id}
                  ref={tabVirtualizer.measureElement}
                  data-index={virtualRow.index}
                  className={styles.virtualContainer}
                  style={{
                    transform: `translateX(${virtualRow.start}px)`,
                  }}
                >
                  <CpTabs.Button
                    id={tab.id}
                    activeId={activeId}
                    onChange={(tab) => {
                      setSelectValue(null);
                      onChange(tab);
                    }}
                  >
                    {tab.name}
                  </CpTabs.Button>
                </div>
              );
            })}
          </div>
        </>
      </CpTabs>
      {overflowing && (
        <>
          <CpButton
            btnType="icon"
            icon="caret-large-right"
            aria-label="Scroll right"
            className="shrink-0"
            disabled={
              tabContainerRef.current?.scrollLeft >=
              tabContainerRef.current?.scrollWidth -
                tabContainerRef.current?.clientWidth
            }
            onClick={() => {
              const scrollLeft = tabContainerRef.current.scrollLeft;
              const nearest = tabVirtualizer
                .getVirtualItems()
                .find((item) => item.start > scrollLeft);
              if (nearest) {
                tabVirtualizer.scrollToOffset(nearest.start, {
                  behavior: "smooth",
                });
              }
            }}
          />
          <CpSelectSingle
            autoSelectOnSearch
            data={tabsData}
            onChange={(value) => {
              setSelectValue(value);
              onChange(value.id);
            }}
            searchFilter={tabsData.length > 7}
            searchOnChange={setSearch}
            searchValue={search}
            value={selectValue}
            renderTrigger={({ toggle }) => (
              <CpButton
                btnType="icon"
                icon="caret-small-down"
                aria-label="Show tabs"
                className="shrink-0"
                onClick={toggle}
              />
            )}
          />
        </>
      )}
    </div>
  );
}
