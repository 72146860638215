import React from "react";
import { m } from "kremling";
import { currency } from "@helpers";

export function CurrencyCell({ value, isDisabled }) {
  const cellText = currency(value);
  return (
    <div className={m("cp-table-cell-disabled", isDisabled)} title={cellText}>
      {cellText}
    </div>
  );
}
