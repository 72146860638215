import React, { forwardRef } from "react";
import { object, oneOfType, string } from "prop-types";
import { useCss, a, k } from "kremling";
import { CpIcon } from "@components";

export const CpPillButton = forwardRef(function CpPillButton(props, ref) {
  const { className, iconColor, text, type, disabled = false, ...rest } = props;
  const typeIsValid = ["status", "priority", "empty"].includes(type);

  const scope = useCss(css);

  return (
    <button
      {...scope}
      className={a("cp-pill-button")
        .m("cp-pill-button--icon", typeIsValid)
        .t("cp-pill-button--disabled", "cp-pill-button--hover", disabled)
        .a(className)}
      ref={ref}
      {...rest}
    >
      {typeIsValid && (
        <CpIcon
          className="cp-ml-4"
          name={
            type === "priority"
              ? "urgent-open-small"
              : type === "empty"
                ? "shape-circle-open-medium"
                : "shape-circle-closed"
          }
          fill={iconColor}
        />
      )}
      <span
        className={a("cp-pill-button__inner").m(
          "cp-pill-button__inner--icon",
          typeIsValid,
        )}
      >
        {text}
      </span>
    </button>
  );
});

CpPillButton.propTypes = {
  className: oneOfType([string, object]),
  iconColor: string,
  text: string,
  type: string,
};

const css = k`
.cp-pill-button {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  border-radius: .8rem;
  font-size: 1.3rem;
  width: fit-content;
  height: 2.6rem;
  user-select: none;
  position: relative;
  cursor: pointer;
  background-color: var(--cp-color-pill-button-bg);
  border: solid .1rem var(--cp-color-toggle-pill-inactive-border);
  color: var(--cp-color-app-primary-text);

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.cp-pill-button--icon {
  font-size: 1.4rem;
  height: 2.4rem;
}

.cp-pill-button__inner {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  padding: .4rem .8rem .4rem .8rem;
}

.cp-pill-button__inner--icon {
  padding-left: .4rem;
}

.cp-pill-button--hover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: .6rem;
  background-color: var(--cp-color-pill-button-bg);
  opacity: 0;
  transition: opacity var(--cp-form-transition-duration) ease;
}
.cp-pill-button--hover:hover {
  text-decoration: none;
  background-color: var(--cp-color-pill-button-hover-bg);
}

.cp-pill-button--hover:active, .cp-pill-button--hover:focus {
  outline: none;
  box-shadow: none;
}

.cp-pill-button--hover:active {
  background-color: var(--cp-color-pill-active-bg);
}

.cp-pill-button--hover:focus:not(:active) {
  box-shadow: var(--cp-form-focus-state);
}

.cp-pill-button--hover.checked {
  border: solid .1rem var(--cp-color-toggle-pill-active-border);
  background-color: var(--cp-color-toggle-pill-active-bg);
  color: var(--cp-color-toggle-pill-active-text);
}

.cp-pill-button--hover.checked:active::after {
  opacity: .1;
}

.cp-pill-button--disabled {
  cursor: default;
}
`;
