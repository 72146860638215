import React from "react";
import PropTypes from "prop-types";
import { isNull, isUndefined } from "lodash";
import { CpCheckbox, CpLoader } from "@components";
import styles from "./filter.styles.css";

const FixedFilter = ({
  filterValues,
  applyFilter,
  selections,
  hideSortOptions,
  allowMultiple = true,
}) => {
  return (
    <div>
      {!hideSortOptions && (
        <li className="cps-divider cps-margin-left-16 cps-margin-right-16 cps-margin-top-4 cps-margin-bottom-4" />
      )}
      {filterValues?.length > 0
        ? filterValues.map((value) => {
            const selected = selections?.includes(value.filter) || false;
            return (
              <li key={`${value.filter}`} className="cps-cursor-pointer">
                <div
                  className={`${styles.spreadContents} cps-margin-left-16 cps-margin-right-16 cps-padding-top-8 cps-padding-bottom-8`}
                  onClick={(e) => applyFilter(e, value.filter, allowMultiple)}
                  style={{ lineHeight: "20px" }}
                >
                  <div className="cps-ellipsis">{value.label}</div>
                  <div
                    style={{
                      minWidth: "25px",
                      margin: "-3px",
                      pointerEvents: "none",
                    }}
                  >
                    <CpCheckbox checked={selected} onChange={() => {}} />
                  </div>
                </div>
              </li>
            );
          })
        : (isNull(filterValues) || isUndefined(filterValues)) && <CpLoader />}
    </div>
  );
};

FixedFilter.propTypes = {
  filterValues: PropTypes.array.isRequired,
  applyFilter: PropTypes.func.isRequired,
  selections: PropTypes.array,
};

export default FixedFilter;
