import React, {
  ReactNode,
  CSSProperties,
  useState,
  useRef,
  useContext,
} from "react";
import { useCss, k, a } from "kremling";
import { v4 as uuid } from "uuid";

import {
  CpSideMenuButton,
  CpSideMenuButtonProps,
} from "./cp-side-menu-button.component";
import {
  CpSideMenuAccordionContext,
  ChildIds,
  CpSideMenuAccordionButtonContext,
} from "./cp-side-menu-accordion-context";
import { CpSideMenuContext } from "../cp-side-menu-context";

export type CpSideMenuAccordionProps = Partial<
  Pick<CpSideMenuButtonProps, "iconLeft" | "iconRight" | "className">
> & {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  text?: ReactNode | string;
};

export function CpSideMenuAccordion(props: CpSideMenuAccordionProps) {
  const { text, children, className, style, ...rest } = props;
  const scope = useCss(css);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [childIds, setChildIds] = useState<ChildIds>({});
  const { activeAccordion } = useContext(CpSideMenuContext);
  const id = useRef<string>(uuid());
  const isOpen = id.current === activeAccordion;

  return (
    <div
      {...scope}
      className={a("cp-side-menu-accordion", className)}
      style={style}
    >
      <CpSideMenuAccordionButtonContext.Provider
        value={{ isActive: isOpen, accordionId: id.current }}
      >
        <CpSideMenuButton
          {...rest}
          onClick={() => {
            if (activeAccordion !== id.current) {
              const first = contentRef.current?.querySelector(
                "button, a",
              ) as HTMLElement;
              first?.click?.();
            }
          }}
        >
          {text}
        </CpSideMenuButton>
      </CpSideMenuAccordionButtonContext.Provider>
      <CpSideMenuAccordionContext.Provider
        value={{ accordionId: id.current, childIds, setChildIds }}
      >
        <div
          className="cp-side-menu-accordian-content"
          style={{ display: isOpen ? "block" : "none" }}
          ref={contentRef}
        >
          {children}
        </div>
      </CpSideMenuAccordionContext.Provider>
    </div>
  );
}

const css = k`
  .cp-side-menu-accordion {

  }
`;
