import { useMemo, useRef, useState } from "react";
import { isEmpty } from "lodash";

export function useTableFilters({ schema, singleSort, onFilterApply } = {}) {
  if (!schema) {
    throw new Error("schema is required for useTableFilters");
  }
  const [filters, setFilters] = useState({});
  const [sortData, setSortData] = useState({});
  const onFilterApplyRef = useRef();
  onFilterApplyRef.current = onFilterApply;

  const controller = useMemo(() => {
    function applyFilter(columnId, { sortDir, filterData }) {
      setSortData((currentVal) => {
        const newVal = singleSort && !!sortDir ? {} : { ...currentVal };
        if (sortDir) {
          newVal[columnId] = {
            fieldId: schema[columnId].sort.fieldId,
            direction: sortDir,
          };
        } else {
          delete newVal[columnId];
        }
        return newVal;
      });

      setFilters((currentVal) => {
        const newVal = { ...currentVal };
        if (isEmpty(filterData)) {
          delete newVal[columnId];
        } else {
          newVal[columnId] = {
            fieldId: schema[columnId].filter.fieldId,
            data: filterData,
          };
        }
        return newVal;
      });

      onFilterApplyRef.current?.({
        columnId,
        sortDir,
        filterData: isEmpty(filterData) ? null : filterData,
        filterFieldId: schema[columnId].filter?.fieldId,
        sortFieldId: schema[columnId].sort?.fieldId,
        columnSchema: schema[columnId],
      });
    }

    return {
      filters,
      sortData,
      setFilters,
      setSortData,
      applyFilter,
    };
  }, [filters, sortData, schema, singleSort]);
  return controller;
}
