import React, { useCallback, useMemo } from "react";
import { CpDateRangeSelector } from "@components";
import { DateTime } from "luxon";
import { isEmpty } from "lodash";
import { getColumnProp } from "./date-filter.helper";

const defaultDateFormat = "yyyy-MM-dd";

export function DateFilter({
  columnSchema,
  filterControl,
  onClose,
  hideNoDateAdded,
  dateFormat,
  dateRanges = [],
}) {
  const filterField = columnSchema.filter.fieldId;
  const prevFilter = filterControl.filters[columnSchema.id]?.data?.[0];
  const prevSortDir = filterControl.sortData[columnSchema.id]?.direction;

  const column = useMemo(
    () => getColumnProp(filterField, hideNoDateAdded, dateRanges),
    [filterField, hideNoDateAdded, dateRanges],
  );
  const outputDateFormat = dateFormat || defaultDateFormat; // The format that we send to the BE

  const onApply = useCallback(
    (sortParam, dates) => {
      const inputFormat = "MM/dd/yyyy";
      const filter = {};
      if (dates.after) {
        filter.start_date = DateTime.fromFormat(
          dates.after,
          inputFormat,
        ).toFormat(outputDateFormat);
      }
      if (dates.before) {
        filter.end_date = DateTime.fromFormat(
          dates.before,
          inputFormat,
        ).toFormat(outputDateFormat);
      }
      if (dates.filter_params === "noDateAdded") {
        filter.include_empty = true;
      }
      const filterToApply = isEmpty(filter) ? [] : [filter];
      filterControl.applyFilter(columnSchema.id, {
        filterData: filterToApply,
        sortDir: dates.order,
      });
      onClose();
    },
    [columnSchema.id, filterControl, onClose, outputDateFormat],
  );

  return (
    <CpDateRangeSelector
      hideSortOptions={!columnSchema.sort}
      column={column}
      filterContext={{
        applyFilter: onApply,
        filters: {
          [filterField]: {
            after: prevFilter?.start_date
              ? DateTime.fromFormat(
                  prevFilter.start_date,
                  outputDateFormat,
                ).toISODate()
              : null,
            before: prevFilter?.end_date
              ? DateTime.fromFormat(
                  prevFilter.end_date,
                  outputDateFormat,
                ).toISODate()
              : null,
            order: prevSortDir,
          },
        },
      }}
      menuStyles={{ display: "block" }}
      onCancel={onClose}
      setColumnMenuShown={() => {}} // this is supposed to be optional, but it always gets called when clicking Apply
    />
  );
}
DateFilter.dropdownWidth = 500;
