import React, { forwardRef } from "react";
import { useCss, k, a } from "kremling";
import { node, oneOfType, string, oneOf } from "prop-types";
import { CpIcon } from "@components";

export const CpInlineNotification = forwardRef(
  function CpInlineNotification(props, ref) {
    const {
      message,
      cta,
      type = "info",
      icon = props.type === "error"
        ? "alert-triangle-open-large"
        : "information-circle-open-large",
      ...rest
    } = props;
    const scope = useCss(css);
    return (
      <div
        {...scope}
        {...rest}
        ref={ref}
        className={a("inline-notification").m(
          "error-border",
          type === "error" || type === "warning",
        )}
      >
        <div className="inline-notification__card">
          <CpIcon name={icon} className="inline-notification__icon" />
          <div className="inline-notification__message">{message}</div>
          {!!cta && <div className="inline-notification__cta">{cta}</div>}
        </div>
      </div>
    );
  },
);

CpInlineNotification.propTypes = {
  cta: node,
  message: oneOfType([string, node]).isRequired,
  type: oneOf(["info", "warning", "error"]),
  icon: string,
};

const css = k`
  .inline-notification {
    overflow: hidden;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: var(--cp-color-app-primary);
      width: .8rem;
      border-radius: .4rem 0 0 .4rem;
    }
  }

  .error-border {
    &::before {
      background-color: var(--cp-color-input-error-border);
    }
  }

  .inline-notification__card {
    border: solid .1rem var(--cp-color-app-border);
    border-left: 0;
    padding: 1.6rem;
    border-radius: 0 .4rem .4rem 0;
    background-color: var(--cp-color-card-bg);
    margin-left: .8rem;
    display: flex;
    align-items: center;

    > * + * {
      margin-left: 1.6rem;
    }
  }

  .inline-notification__icon {
    flex-shrink: 0;
  }

  .inline-notification__message {
    font-size: 1.4rem;
    flex-grow: 1;
  }

  .inline-notification__cta {
    flex-shrink: 0;
    flex-grow: 0;
  }
`;
