import React from "react";
import { a } from "kremling";

import { CpIcon, CpBadge } from "@components";
import { ItemBase } from "../cp-select/items/item-base";

export function BadgeItemSingle(props) {
  const { isSelected, value, item, onChange } = props;
  const isActive = !!(item && value && item.id === value.id);
  const onClick = () => {
    onChange(item);
  };

  return (
    <ItemBase
      id={item.id}
      isSelected={isSelected}
      className={a("item-single").m("item-single--selected", isSelected)}
      onClick={onClick}
    >
      <CpBadge>{item.name}</CpBadge>
      <div>
        {isActive && (
          <CpIcon
            name="checkmark-circle-filled-small"
            fill="var(--cps-color-success)"
          />
        )}
      </div>
    </ItemBase>
  );
}
