import React, { forwardRef } from "react";
import isURL from "validator/lib/isURL";

import { CpInputField } from "./cp-input-field.component";
import { object, string } from "prop-types";

export const CpInputFieldUrl = forwardRef(function CpInputFieldUrl(props, ref) {
  const { control, fieldName, rules = {}, ...inputProps } = props;

  return (
    <CpInputField
      control={control}
      fieldName={fieldName}
      rules={{
        ...rules,
        validate: {
          ...rules?.validate,
          isValidUrl: (v) => !v?.length || isURL(v) || "Field must be URL",
        },
      }}
      {...inputProps}
      ref={ref}
    />
  );
});

CpInputFieldUrl.propTypes = {
  control: object.isRequired,
  fieldName: string.isRequired,
  rules: object,
};
