import React, { isValidElement, ReactNode } from "react";

import { CpIcon, CpIconProps, CpIconDynamic } from "./cp-icon.component";
import { Icons } from "../../types/icon";

export type RenderIconProps = {
  icon: CpIconDynamic;
  className?: string;
  size?: number;
};

export function RenderIconDynamic({
  icon,
  className,
  size,
}: RenderIconProps): ReactNode | null {
  if (typeof icon === "string") {
    return <CpIcon name={icon as Icons} size={size} className={className} />;
  }
  const iconProps = icon as CpIconProps;
  if (typeof iconProps === "object" && iconProps?.name) {
    return <CpIcon {...iconProps} size={size} className={className} />;
  }
  if (isValidElement(icon)) {
    return icon;
  }
  return null;
}
