import React, { useState, useMemo } from "react";
import { string, bool, shape, number } from "prop-types";
import { CpIcon } from "@components";
import { useCss, k, a } from "kremling";

export const CpNameChip = (props) => {
  const {
    name,
    className = "",
    active = false,
    size = "medium",
    icon = "person",
    imgSrc,
    team,
    initials,
    count,
  } = props;

  const { teamName, teamHex } = useMemo(() => {
    if (team?.name && team?.color_hex) {
      return { teamName: team.name, teamHex: team?.color_hex };
    }
    return {};
  }, [team]);

  const scope = useCss(css);
  const [imgError, setImgError] = useState(false);
  const computedInitials = useMemo(() => {
    if (count) {
      return count >= 99 ? "+99" : `+${count}`;
    }
    if (initials) return initials.slice(0, 2);

    const entityName = name || teamName;
    const sections = entityName?.split(" ") || [];
    const firstInitial = sections[0]?.charAt(0)?.toUpperCase();
    const lastInitial =
      sections.length > 1 &&
      sections[sections.length - 1]?.charAt(0)?.toUpperCase();

    return teamName ? (
      `${firstInitial}`
    ) : firstInitial && lastInitial ? (
      `${firstInitial}${lastInitial}`
    ) : (
      <CpIcon name={icon} />
    );
    /* Add missing deps and verify it doesn't break: icon */
  }, [name, teamName, initials, count]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      {...scope}
      className={a(`name-chip`, className)
        .t("medium", "large", size === "medium")
        .m("active", active)
        .m("transparent", imgSrc && !imgError)}
      style={
        teamHex && {
          border: `2px solid ${teamHex}`,
          color: `${teamHex}`,
          backgroundColor: "var(--cp-color-button-icon-raised-bg)",
        }
      }
    >
      {imgSrc && !imgError ? (
        <div className="image-container">
          <img
            onError={() => setImgError(true)}
            className="image"
            src={imgSrc}
          />
        </div>
      ) : (
        <div className="initials">{computedInitials}</div>
      )}
    </div>
  );
};

CpNameChip.propTypes = {
  count: number,
  initials: string,
  name: string,
  className: string,
  active: bool,
  size: string,
  icon: string,
  imgSrc: string,
  team: shape({
    color_hex: string,
    name: string,
  }),
};

const css = k`
  .name-chip {
    padding-top: 0;
    display: block;
    background-color: var(--cps-color-secondary-text);
    font-weight: 600;
    line-height: 1.2;
    color: var(--cp-color-app-primary-dark-text);
    border-radius: 50%;
    text-align: center;
    opacity: 1;
  }

  .name-chip.medium {
    font-size: 1.3rem;
    height: 3.2rem;
    width: 3.2rem;
    min-width: 3.2rem;
  }

  .name-chip.large {
    font-size: 2.2rem;
    height: 4.8rem;
    width: 4.8rem;
    min-width: 4.8rem;
  }

  .name-chip.active {
    background-color: var(--cps-color-primary);
  }

  .initials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .image-container {
    overflow: hidden;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }

  .image {
    height: 100%;
  }

  .transparent {
    background: transparent;
  }

`;
