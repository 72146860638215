import { useEffect } from "react";
import { clickEventStack } from "@helpers";

export function useClickEventStack(callback, disabled = false) {
  useEffect(() => {
    if (!disabled) {
      const listener = clickEventStack.add(callback);
      return () => listener.remove();
    }
    /* Add missing deps: callback (memoize or store in a ref) */
  }, [disabled]); // eslint-disable-line react-hooks/exhaustive-deps
}
