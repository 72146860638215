import { v4 as uuid } from "uuid";

class KeydownEventStack {
  __subscribers = {};

  constructor() {
    window.addEventListener("keydown", this.__next, true);
  }

  __next = (e) => {
    if (this.__subscribers[e.key] && this.__subscribers[e.key].length) {
      const { callback } =
        this.__subscribers[e.key][this.__subscribers[e.key].length - 1];
      callback(e);
    }
  };

  add = ({ keys, callback }) => {
    const id = uuid();
    keys.forEach((key) => {
      this.__subscribers[key] = this.__subscribers[key] || [];
      this.__subscribers[key].push({ id, callback });
    });
    return {
      remove: () => {
        keys.forEach((key) => {
          this.__subscribers[key] = this.__subscribers[key].filter(
            (s) => s.id !== id,
          );
        });
      },
    };
  };
}

export const keydownEventStack = new KeydownEventStack();
