import React, { useEffect, useState } from "react";
import { string, func, bool } from "prop-types";
import { isEmpty } from "lodash";
import isEmail from "validator/lib/isEmail";
import { CpInput } from "@components";

Email.propTypes = {
  isValid: bool,
  setIsValid: func,
  value: string,
};

export function Email(props) {
  const [isValidDefault, setIsValidDefault] = useState(true);

  const {
    isValid = isValidDefault,
    setIsValid = setIsValidDefault,
    value,
    error,
    ...inputProps
  } = props;

  useEffect(() => {
    setIsValid(isEmpty(value) || isEmail(value));
    /* Add missing deps and verify it doesn't break: setIsValid */
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CpInput
      error={!isValid && (error || "Invalid email")}
      value={value}
      {...inputProps}
    />
  );
}
