import { useState, useMemo, useCallback } from "react";
import { Selection } from "./use-table-selection.hook";

export function useBasicTableSelection({
  resources,
  disabledResourceIds = [],
}: {
  resources: {
    id: string | number;
  }[];
  disabledResourceIds?: (string | number)[];
}): Selection {
  const [selectionMap, setSelectionMap] = useState<
    Record<string | number, boolean>
  >({});
  const selectionLength = Object.values(selectionMap).filter((s) => !!s).length;
  const calcTotalSize = resources.length - disabledResourceIds.length;
  const allSelected =
    resources.length > 0 ? calcTotalSize === selectionLength : false;

  const selectAll = useCallback(() => {
    const map: Record<string | number, boolean> = {};
    resources.forEach((resource) => {
      if (resource.id && !disabledResourceIds.includes(resource.id)) {
        map[resource.id] = true;
      }
    });
    setSelectionMap(map);
  }, [resources, disabledResourceIds]);

  const deselectAll = useCallback(() => {
    setSelectionMap({});
  }, []);

  const toggleSelection = useCallback(
    (resourceId: string | number) => {
      const newValue = !selectionMap[resourceId];
      setSelectionMap({ ...selectionMap, [resourceId]: newValue });
    },
    [selectionMap],
  );

  /* Adds an array of resource ids to the selection */
  const selectMultiple = useCallback(
    (resourceIds: (string | number)[]) => {
      const newSelection = { ...selectionMap };
      const enabledResourceIds = resourceIds.filter(
        (id) => !disabledResourceIds.includes(id),
      );
      enabledResourceIds.forEach((id) => (newSelection[id] = true));
      setSelectionMap(newSelection);
    },
    [selectionMap, disabledResourceIds],
  );

  // Returns whether the resourceId is selected
  const isSelected = useCallback(
    (resourceId: string | number) => {
      if (allSelected) {
        return true;
      } else {
        return !!selectionMap[resourceId];
      }
    },
    [selectionMap, allSelected],
  );

  const selection = useMemo(() => {
    return {
      allSelected,
      byId: selectionMap,
      type: "includes" as const,
      length: selectionLength, // length and totalSelected are the same for basic selections. Just returning these so they conform to the Selection type
      totalSelected: selectionLength,
      toArray: () =>
        Object.entries(selectionMap)
          .filter(([, val]) => !!val)
          .map(([key]) => (isNaN(Number(key)) ? key : Number(key))),
      selectAll,
      deselectAll,
      toggleSelection,
      selectMultiple,
      isSelected,
    };
  }, [
    allSelected,
    selectionMap,
    selectAll,
    deselectAll,
    toggleSelection,
    selectMultiple,
    isSelected,
    selectionLength,
  ]);

  return selection;
}
