import React from "react";
import { useController } from "react-hook-form";
import { CpInput, CpRadio } from "@components";
import { FilterCondition } from "../common/filter-condition.component";

export function InputConditionController({
  conditionOptions,
  control,
  dataType,
  name,
  onRemove,
  requireOperator,
}) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      validate: {
        operator: (value) => {
          if (requireOperator && value?.condition) {
            return !!value.operator ? true : "Please select an operator";
          } else {
            return true;
          }
        },
      },
    },
    shouldUnregister: true,
  });

  const clearDisabledInput = (condition, value) => {
    return condition?.disableInput ? undefined : value?.filterValue;
  };

  return (
    <div>
      {requireOperator && (
        <div className="cp-mb-8">
          <CpRadio
            inline
            name="operator"
            onChange={(operator) => onChange({ ...value, operator })}
            value={value?.operator}
            error={error?.message}
          >
            <CpRadio.Item id="or">Or</CpRadio.Item>
            <CpRadio.Item id="and">And</CpRadio.Item>
          </CpRadio>
        </div>
      )}
      <FilterCondition
        options={conditionOptions}
        onChange={(condition) =>
          onChange({
            ...value,
            condition,
            filterValue: clearDisabledInput(condition, value),
          })
        }
        onRemove={onRemove}
        value={value?.condition}
      />
      <CpInput
        autoFocus
        value={value?.filterValue || ""}
        onChange={(filterValue) => onChange({ ...value, filterValue })}
        placeholder="Add value"
        type={dataType === "number" ? "number" : "text"}
        disabled={!value?.condition || value?.condition?.disableInput || false}
      />
    </div>
  );
}
