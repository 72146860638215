import { useEffect, useMemo } from "react";
import { isNumber } from "lodash";
import { CpDropdownProps } from "./cp-dropdown.component";
import { Placement, Plugin } from "tippy.js";

export const dropdownPositionOptions = [
  "top",
  "top-start",
  "top-end",
  "left",
  "left-start",
  "left-end",
  "right",
  "right-start",
  "right-end",
  "bottom",
  "bottom-start",
  "bottom-end",
];

export function useDropdownPlacementConversion(position: string): Placement {
  return useMemo(() => {
    let placement;
    switch (position) {
      case "top-left":
        placement = "top-end";
        break;
      case "top-right":
        placement = "top-start";
        break;
      case "top":
        placement = "top-start";
        break;
      case "left-top":
        placement = "left-end";
        break;
      case "left-bottom":
        placement = "left-start";
        break;
      case "left":
        placement = "left-start";
        break;
      case "right-top":
        placement = "right-end";
        break;
      case "right-bottom":
        placement = "right-start";
        break;
      case "right":
        placement = "right-start";
        break;
      case "bottom-left":
        placement = "bottom-end";
        break;
      case "bottom-right":
        placement = "bottom-start";
        break;
      case "bottom":
        placement = "bottom-start";
        break;
      default:
        placement = position;
    }
    return placement as Placement;
  }, [position]);
}

export const hideOnEsc = {
  name: "hideOnEsc",
  defaultValue: true,
  fn({ hide }) {
    function onKeyDown(event: KeyboardEvent) {
      if (event.key === "Escape") hide();
    }
    return {
      onShow: () => document.addEventListener("keydown", onKeyDown),
      onHide: () => document.removeEventListener("keydown", onKeyDown),
    };
  },
} as Plugin<unknown>;

export function useAutoDisable(
  disabled: boolean | undefined,
  isOpen: boolean,
  close: () => void,
) {
  useEffect(() => {
    if (disabled && isOpen) close();
    /* Add missing deps: isOpen, close (needs to be memoized or put in a ref)*/
  }, [disabled]); // eslint-disable-line react-hooks/exhaustive-deps
}

export function useContentWidth(contentWidth: CpDropdownProps["contentWidth"]) {
  return useMemo(() => {
    let width;
    if (isNumber(contentWidth)) {
      width = `${contentWidth / 10}rem`;
    } else if (contentWidth === "sm") {
      width = "18rem";
    } else if (contentWidth === "md") {
      width = "28rem";
    } else if (contentWidth === "lg") {
      width = "40rem";
    } else {
      width = "auto";
    }
    return width;
  }, [contentWidth]);
}

export function getAnimationDuration() {
  return parseInt(
    getComputedStyle(document.documentElement)
      .getPropertyValue("--cp-dropdown-duration")
      .replace("ms", ""),
  );
}
