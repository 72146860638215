import React, {
  ComponentProps,
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
} from "react";
import { m } from "kremling";

import {
  CardHeader,
  CardHeaderProps,
} from "./components/card-header.component";
import { CardBody, CardBodyProps } from "./components/card-body.component";
import {
  CardFooter,
  CardFooterProps,
} from "./components/card-footer.component";

export type CpCardProps = ComponentProps<"div"> & {
  level?: 1 | 2 | 3;
};

// this is needed to add child components
type CpCardType = ForwardRefExoticComponent<
  CpCardProps & RefAttributes<HTMLDivElement>
> & {
  Header: typeof CardHeader;
  Body: typeof CardBody;
  Footer: typeof CardFooter;
};

export const CpCard = forwardRef(function CpCard(props, ref) {
  const { children, className, level, ...rest } = props;

  return (
    <div
      ref={ref}
      className={m("cp-card-l1", !level || level === 1)
        .m("cp-card-l2", level === 2)
        .m("cp-card-l3", level === 3)
        .a(className)}
      data-testid="cp-card"
      {...rest}
    >
      {children}
    </div>
  );
}) as CpCardType;

// card components
CpCard.Header = CardHeader;
CpCard.Body = CardBody;
CpCard.Footer = CardFooter;

export type CpCardHeaderProps = CardHeaderProps;
export type CpCardBodyProps = CardBodyProps;
export type CpCardFooterProps = CardFooterProps;
