import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { CpContextDropdown, CpLoader } from "@components";
import styles from "./table-body.styles.css";
import {
  ColumnId,
  ColumnWidthMap,
  ResizeInfo,
  Resource,
  TableSchema,
} from "../types";
import { Selection } from "../hooks/use-table-selection.hook";
import { MemoRow } from "./row";

export function TableBody({
  columnWidths,
  disabledResourceIds,
  tableContainerRef,
  orderedColumns,
  schema,
  data,
  selection,
  stickyResizing,
  renderContextMenu,
  renderEmptyState,
  showLoader,
  verticalBorders,
}: {
  columnWidths?: ColumnWidthMap;
  disabledResourceIds?: (string | number)[];
  tableContainerRef: MutableRefObject<HTMLDivElement | undefined>;
  orderedColumns: ColumnId[];
  schema: TableSchema;
  data: {
    id: string | number;
    [key: string]: any;
  }[];
  selection?: Selection;
  stickyResizing: ResizeInfo | null;
  renderContextMenu?: ({
    clickedResource,
  }: {
    clickedResource: Resource;
  }) => React.ReactNode;
  renderEmptyState?: () => React.ReactNode;
  showLoader?: boolean;
  verticalBorders: boolean;
}) {
  const contextMenuRef = useRef<{
    open: (rect: {
      top?: number;
      left?: number;
      right?: number;
      bottom?: number;
    }) => void;
  }>();
  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => tableContainerRef.current || null,
    count: data.length,
    // 48px is the fixed height chosen by UX.
    estimateSize: () => 48,
    overscan: 20,
  });
  const [lastSelectedIndex, setLastSelectedIndex] = useState<number | null>(
    null,
  );

  const items = rowVirtualizer.getVirtualItems();
  const paddingTop = items.length > 0 ? items[0].start : 0;
  const paddingBottom =
    items.length > 0
      ? rowVirtualizer.getTotalSize() - items[items.length - 1].end
      : 0;

  const [contextMenuResource, setContextMenuResource] =
    useState<Resource | null>();
  // Separate ref that only updates on click so the rendered menu will always have an id even when the dropdown is closed.
  const clickedResourceRef = useRef<Resource>();
  const hasContextMenu = !!renderContextMenu;
  const openContextMenu = useMemo(() => {
    if (!hasContextMenu) return null;
    return (e: React.MouseEvent, resource: Resource) => {
      if (contextMenuRef.current) {
        clickedResourceRef.current = resource;
        contextMenuRef.current.open({ top: e.pageY, left: e.pageX });
        setContextMenuResource(resource);
      }
    };
  }, [hasContextMenu]);

  const onSelect = useCallback(
    (evt: React.MouseEvent, resource: any, resourceIndex: number) => {
      if (!selection) return;
      if (
        evt.nativeEvent.shiftKey &&
        lastSelectedIndex !== null &&
        lastSelectedIndex !== resourceIndex
      ) {
        const resourceIds = data
          .slice(
            Math.min(lastSelectedIndex + 1, resourceIndex),
            Math.max(lastSelectedIndex - 1, resourceIndex) + 1,
          )
          .map((r) => r.id);
        selection.selectMultiple(resourceIds);
      } else {
        selection.toggleSelection(resource.id);
        setLastSelectedIndex(resourceIndex);
      }
    },
    [data, selection, lastSelectedIndex],
  );

  useEffect(() => {
    setLastSelectedIndex(null);
  }, [data, selection?.allSelected]);

  if (renderEmptyState || showLoader) {
    return (
      <tbody className={styles.emptyStateBody}>
        <tr className={styles.emptyStateRow}>
          <td
            className={styles.emptyStateCell}
            style={{ width: `${tableContainerRef.current?.clientWidth}px` }}
          >
            {showLoader ? <CpLoader /> : renderEmptyState?.()}
          </td>
        </tr>
      </tbody>
    );
  }

  return (
    <tbody className={styles.tableBody}>
      {renderContextMenu && (
        <tr className={styles.hiddenRow}>
          <td className={styles.hiddenCell}>
            <CpContextDropdown
              ref={contextMenuRef}
              // @ts-expect-error - contentWidth is definitely defined for the component, but tsc can't find it.
              contentWidth="md"
              renderContent={(props: any) =>
                renderContextMenu({
                  ...props,
                  clickedResource: clickedResourceRef.current,
                })
              }
              onClose={() => setContextMenuResource(null)}
            />
          </td>
        </tr>
      )}
      {paddingTop > 0 && (
        <tr>
          <td style={{ height: `${paddingTop}px`, border: "none" }} />
        </tr>
      )}
      {rowVirtualizer.getVirtualItems().map((virtualRow) => {
        const resource = data[virtualRow.index];
        const rowIsDisabled = disabledResourceIds?.includes(resource.id);
        return (
          <MemoRow
            columnWidths={columnWidths}
            stickyResizing={stickyResizing}
            resource={resource}
            key={resource.id + "_" + virtualRow.index}
            schema={schema}
            orderedColumns={orderedColumns}
            openContextMenu={openContextMenu}
            contextMenuOpenOnRow={
              !!(resource?.id && contextMenuResource?.id === resource.id)
            }
            selection={selection}
            resourceIndex={virtualRow.index}
            onSelect={onSelect}
            verticalBorders={verticalBorders}
            rowIsDisabled={rowIsDisabled}
          />
        );
      })}
      {paddingBottom > 0 && (
        <tr>
          <td style={{ height: `${paddingBottom}px`, border: "none" }} />
        </tr>
      )}
    </tbody>
  );
}
