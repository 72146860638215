// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cp-table-header-menu-styles__columnHeaderText--CTlsx {\n  z-index: 1;\n  vertical-align: middle;\n  width: 100%;\n  height: 24px;\n}\n.cp-table-header-menu-styles__spreadContents--2GtbN {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.cp-table-header-menu-styles__centerIcon--VQ0Y- {\n  vertical-align: middle;\n}\n\n.cp-table-header-menu-styles__notVisible--8Pq\\+S {\n  visibility: hidden;\n}\n", "",{"version":3,"sources":["webpack://src/components/cp-table-header-menu/cp-table-header-menu.styles.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,sBAAsB;EACtB,WAAW;EACX,YAAY;AACd;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".columnHeaderText {\n  z-index: 1;\n  vertical-align: middle;\n  width: 100%;\n  height: 24px;\n}\n.spreadContents {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.centerIcon {\n  vertical-align: middle;\n}\n\n.notVisible {\n  visibility: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"columnHeaderText": "cp-table-header-menu-styles__columnHeaderText--CTlsx",
	"spreadContents": "cp-table-header-menu-styles__spreadContents--2GtbN",
	"centerIcon": "cp-table-header-menu-styles__centerIcon--VQ0Y-",
	"notVisible": "cp-table-header-menu-styles__notVisible--8Pq+S"
};
export default ___CSS_LOADER_EXPORT___;
