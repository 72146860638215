import { mediaQueryMap } from "../hooks/use-media-query.hook.js";

const body = document.body;

if (window.responsiveMode) {
  // setup mediaQueryLists
  body.classList.add("--responsive");
  const phoneOnly = window.matchMedia(mediaQueryMap["--phone-only"]);
  const tabletPortraitUp = window.matchMedia(
    mediaQueryMap["--tablet-portrait-up"],
  );
  const tabletLandscapeUp = window.matchMedia(
    mediaQueryMap["--tablet-landscape-up"],
  );
  const desktopUp = window.matchMedia(mediaQueryMap["--desktop-up"]);
  // initial check on all
  genericCheck("--phone-only", phoneOnly);
  genericCheck("--tablet-portrait-up", tabletPortraitUp);
  genericCheck("--tablet-landscape-up", tabletLandscapeUp);
  genericCheck("--desktop-up", desktopUp);

  // add listeners
  phoneOnly.addListener(genericCheck.bind(null, "--phone-only"));
  tabletPortraitUp.addListener(genericCheck.bind(null, "--tablet-portrait-up"));
  tabletLandscapeUp.addListener(
    genericCheck.bind(null, "--tablet-landscape-up"),
  );
  desktopUp.addListener(genericCheck.bind(null, "--desktop-up"));
}

function genericCheck(cssClass, eventOrQueryList) {
  eventOrQueryList.matches
    ? body.classList.add(cssClass)
    : body.classList.remove(cssClass);
}
