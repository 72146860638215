import React from "react";

import { CpSelect } from "../cp-select/cp-select.component";
import {
  array,
  bool,
  func,
  object,
  oneOf,
  oneOfType,
  string,
  number,
} from "prop-types";
import { deprecatedOneOf } from "@prop-types";
import { dropdownPositionOptions } from "../cp-dropdown/cp-dropdown.utils";
import { defaultSearchFilter, filterAlpha } from "../cp-select/common/utils";
import styles from "./../cp-select/cp-select.styles.scss";
import { Scoped } from "kremling";

export function CpSelectSingle({
  appendTo,
  autoSelectOnSearch,
  className,
  clearable,
  contentWidth,
  data,
  disableSelection,
  disabled,
  error,
  groupType,
  isGroupData,
  loading,
  onBlur,
  onChange,
  onClose,
  onOpen,
  placeholder = "Select",
  position,
  preventKeyOpen,
  preventOutsideClickUntilClosed,
  renderFooter,
  renderTrigger,
  searchFilter,
  searchOnChange,
  searchValue,
  stopPropagation,
  style,
  title,
  transformData,
  triggerIsBlock,
  triggerRef,
  value,
}) {
  return (
    <Scoped css={styles}>
      <CpSelect
        appendTo={appendTo}
        autoSelectOnSearch={autoSelectOnSearch}
        className={className}
        clearable={clearable}
        contentWidth={contentWidth}
        data={data}
        disabled={disabled}
        disableSelection={disableSelection}
        insertSearch={!!searchOnChange}
        isGroupData={isGroupData}
        groupType={groupType}
        position={position}
        loading={loading}
        onBlur={onBlur}
        onChange={onChange}
        onClose={onClose}
        onOpen={onOpen}
        preventKeyOpen={preventKeyOpen}
        preventOutsideClickUntilClosed={preventOutsideClickUntilClosed}
        renderFooter={renderFooter}
        renderGroup={CpSelect.Group}
        renderItem={CpSelect.ItemSingle}
        renderTrigger={
          renderTrigger ||
          ((triggerProps) => (
            <CpSelect.TriggerSingle
              {...triggerProps}
              placeholder={placeholder}
              onBlur={triggerProps.triggerOnBlur}
              title={title}
              error={error}
            />
          ))
        }
        searchFilter={defaultSearchFilter(searchFilter, searchValue)}
        searchOnChange={searchOnChange}
        searchValue={searchValue}
        stopPropagation={stopPropagation}
        style={style}
        transformData={transformData}
        triggerIsBlock={triggerIsBlock}
        triggerRef={triggerRef}
        value={value}
      />
      {typeof error === "string" && (
        <div className="cp-select--error-text">{error}</div>
      )}
    </Scoped>
  );
}

CpSelectSingle.propTypes = {
  // CpDropdown props
  appendTo: oneOfType([object, oneOf(["parent"])]),
  contentWidth: oneOfType([number, oneOf(["sm", "md", "lg", "block", "auto"])]),
  position: deprecatedOneOf(
    dropdownPositionOptions, // valid options
    [
      "top-left",
      "top-right",
      "right-top",
      "right-bottom",
      "bottom-right",
      "bottom-left",
      "left-bottom",
      "left-top",
    ], // deprecated options
  ),
  preventOutsideClickUntilClosed: bool,

  autoSelectOnSearch: bool,
  clearable: bool,
  data: array,
  disabled: bool,
  disableSelection: bool,
  error: oneOfType([bool, string]),
  isGroupData: bool,
  groupType: oneOf(["header", "border"]),
  loading: bool,
  onChange: func.isRequired,
  onBlur: func,
  onClose: func,
  onOpen: func,
  placeholder: string,
  preventKeyOpen: bool,
  renderFooter: func,
  renderTrigger: func,
  searchFilter: oneOfType([func, bool]),
  searchOnChange: func,
  searchValue: string,
  stopPropagation: bool,
  title: string,
  transformData: func,
  triggerIsBlock: bool,
  value: oneOfType([array, object, string, number]),
};

// utils
CpSelectSingle.filterAlpha = filterAlpha;
