import React from "react";
import Color from "color";
import { saturation, lightness } from "./colorpicker.helper.js";
import styles from "./colorpicker.styles.css";

import ColorBar from "./bar.component.js";
import ColorSlider from "./slider.component.js";

export class CprColorpicker extends React.Component {
  constructor() {
    super();
    this.state = {
      width: 0,
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    const rect = this.colorpicker.getBoundingClientRect();
    this.setState({
      width: rect.width,
      start: rect.left,
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    if (this.resizeTimer) clearTimeout(this.resizeTimer);
  }
  render() {
    const color = this.props.color
      ? Color(this.props.color)
      : Color(`hsl(0, ${saturation}%, ${lightness}%)`);
    return (
      <div
        ref={(ref) => (this.colorpicker = ref)}
        className={`${styles.colorpicker}`}
      >
        <ColorBar
          color={color}
          monochromatic={this.props.monochromatic}
          barHeight={this.state.width * 0.09}
          barClick={this.setColor.bind(this)}
        />
        <ColorSlider
          barHeight={this.state.width * 0.09}
          barStart={this.state.start}
          setColor={this.setColor}
          setLightness={this.setLightness}
          barWidth={this.state.width}
          sliderSize={this.state.width * 0.16}
          monochromatic={this.props.monochromatic}
          color={color}
        />
      </div>
    );
  }
  setColor = (color) => {
    this.props.events?.colorchange?.({
      detail: color[this.props.format || "hex"]().toString(),
    });
  };
  setLightness = (lightness) => {
    this.props.events?.lightnesschange?.({
      detail: lightness,
    });
  };
  handleResize = () => {
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
    }
    this.resizeTimer = setTimeout(() => {
      const rect = this.colorpicker.getBoundingClientRect();
      this.setState({
        width: rect.width,
        start: rect.left,
      });
    }, 1000);
  };
}
