import React, { forwardRef, useRef, useState } from "react";
import { array, bool, func, oneOfType, string } from "prop-types";
import { a } from "kremling";

import { CpIcon, CpPill } from "@components";

export const TriggerMultiInput = forwardRef((props, ref) => {
  const {
    isDisabled,
    isOpen,
    onBlur,
    open,
    placeholder,
    teamMember,
    renderPill,
    searchOnChange,
    searchValue,
    value = [],
    error,
  } = props;
  const inputEl = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleBlur = () => {
    setIsFocused(false);
    onBlur?.();
  };

  const handleFocus = () => {
    setIsFocused(true);
    inputEl.current.setSelectionRange(10000, 10000);
  };

  return (
    <div
      ref={ref}
      className={a("cp-select-component-input__trigger")
        .m("cp-select-component-input__trigger--disabled", isDisabled)
        .m("cp-select-component-input__trigger--error", error)}
      onClick={() => {
        if (!isFocused) inputEl.current.focus();
        if (!isOpen) open();
      }}
    >
      <div className="cp-select-component-input__icon">
        <CpIcon name="misc-magnifying-glass-small" />
      </div>
      <div className="cp-select-component-input__input">
        <input
          ref={inputEl}
          disabled={isDisabled}
          type="text"
          onBlur={handleBlur}
          autoComplete="off"
          placeholder={placeholder}
          onFocus={handleFocus}
          onChange={(e) => searchOnChange(e.target.value)}
          value={searchValue}
        />
      </div>
      {renderPill && !!value.length && (
        <CpPill
          className="cp-select-component-input__selected"
          text={renderPill(value)}
          disabled={isDisabled}
          teamMember={teamMember}
        />
      )}
    </div>
  );
});

TriggerMultiInput.propTypes = {
  error: oneOfType([bool, string]),
  onBlur: func,
  teamMember: bool,
  value: array,
};
