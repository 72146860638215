import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { m } from "kremling";
import { CpEmptyState, CpButton } from "@components";

export const CprEmptyState = (props) => {
  const {
    iconSrc,
    headText = "You haven't created one of these",
    ctaClickHandler = noop,
    ctaButtonText = "Add one now",
    ctaButtonType = "primary",
    showCta = false,
    subheadText = "",
    noPadding = false,
    ...rest
  } = props;

  const iconSource = getIconSource(iconSrc);

  return (
    <div className={m("cp-p-32", !noPadding)}>
      <CpEmptyState
        img={iconSource}
        text={headText}
        subText={subheadText}
        cta={
          showCta && (
            <CpButton btnType={ctaButtonType} onClick={ctaClickHandler}>
              {ctaButtonText}
            </CpButton>
          )
        }
        {...rest}
      />
    </div>
  );
};

function getIconSource(iconSrc) {
  return hasImageExtension(iconSrc)
    ? iconSrc
    : `https://cdn.canopytax.com/static/empty-states/${iconSrc}.svg`;
}

function hasImageExtension(iconSrc) {
  return Boolean(/\.(png|jpe?g|gif|webp|svg)(\?.*)?$/.test(iconSrc.toString()));
}

CprEmptyState.propTypes = {
  /**
   * The source of the icon to display. Can be a url or a string that matches the name of an icon in the canopy empty-state icon library.
   */
  iconSrc: PropTypes.string,
  /**
   * The size of the icon to display. Can be "small" or "large". Defaults to "large".
   */
  iconSize: PropTypes.oneOf(["small", "large"]),
  /**
   * removes padding on the component
   */
  noPadding: PropTypes.bool,
  /**
   * Header text to display
   */
  headText: PropTypes.string,
  /**
   * classname to apply styling to the header. for example "cps-subheader".
   */
  headSize: PropTypes.string,
  /**
   * Subtext to display
   */
  subheadText: PropTypes.string,
  /**
   * determines whether a CTA is rendered below the icon and text
   */
  showCta: PropTypes.bool,
  /**
   * CTA button click handler
   */
  ctaClickHandler: PropTypes.func,
  /**
   * The text to display on the CTA button
   */
  ctaButtonText: PropTypes.string,
  /**
   * The type of CTA button to display. Can be "primary" or "secondary". Defaults to "primary".
   */
  ctaButtonType: PropTypes.string,
};
