import React from "react";
import Color from "color";
import styles from "./colorpicker.styles.css";
import {
  colorToPosition,
  saturation,
  lightness,
} from "./colorpicker.helper.js";

export default class ColorSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dragging: false,
    };
  }
  componentWillUnmount() {
    window.removeEventListener("mousemove", this.mousemove);
    window.removeEventListener("mouseup", this.mouseup);
  }
  render() {
    const { props } = this;
    const percentageLeft = colorToPosition(props.color, props.monochromatic);

    return (
      <div
        onMouseDown={(e) => {
          e.preventDefault();
          this.xStart = e.clientX;
          this.setState({
            dragging: true,
          });
          window.addEventListener("mousemove", this.mousemove);
          window.addEventListener("mouseup", this.mouseup);
        }}
        style={{
          top: `-${props.sliderSize / 2 - props.barHeight / 2}px`,
          height: `${props.sliderSize}px`,
          width: `${props.sliderSize}px`,
          left: `calc(${percentageLeft}% - ${props.sliderSize / 2}px)`,
          background: `${props.color.hsl().string()}`,
        }}
        className={`${styles.slider} ${
          this.state.dragging ? styles.grabbing : styles.grab
        }`}
      ></div>
    );
  }
  mousemove = (e) => {
    const percentageOfSlider =
      (e.clientX - this.props.barStart) / this.props.barWidth;
    if (this.props.monochromatic) {
      let newLightness = (1 - percentageOfSlider) * 100;
      this.props.setLightness(newLightness);
    } else {
      const hue = Math.floor(percentageOfSlider * 360);
      this.props.setColor(
        Color(
          `hsl(${
            hue >= 360 ? 359 : hue < 0 ? 0 : hue
          }, ${saturation}%, ${lightness}%)`,
        ),
      );
    }
  };
  mouseup = () => {
    window.removeEventListener("mousemove", this.mousemove);
    window.removeEventListener("mouseup", this.mouseup);
    this.setState({
      dragging: false,
    });
  };
}
