import React, { ReactNode, HTMLAttributes } from "react";
import { a } from "kremling";

type ModalBodyProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
  className?: string;
};

export const ModalBody: React.FC<ModalBodyProps> = ({
  children,
  className,
  ...rest
}) => (
  <div className={a("cp-modal__body", className)} {...rest}>
    {children}
  </div>
);
