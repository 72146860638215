import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import moment from "moment";
import { CprDateRangePicker } from "../../../custom-elements/cps-datepicker/cps-daterangepicker.component";
import { dateRangeTypes } from "./date-filter.helper";
import { defaultDateFormat } from "../column.helper";

import styles from "./filter.styles.css";

const DateRangeFilter = ({
  applyFilter,
  filter,
  dateRanges,
  hideSortOptions,
  setDisableSave,
}) => {
  const [startDateText, setStartDateText] = useState("");
  const [startDateInvalid, setStartDateInvalid] = useState(false);
  const [endDateText, setEndDateText] = useState("");
  const [endDateInvalid, setEndDateInvalid] = useState(false);

  useEffect(() => {
    setStartDateText(filter.after || "");
    setStartDateInvalid(false);
    setEndDateText(filter.before || "");
    setEndDateInvalid(false);
  }, [filter]);

  const handleRangeChange = (e) => {
    if (
      e.detail.startDate === startDateText &&
      e.detail.endDate === endDateText
    )
      return;
    updateDates(dateRangeTypes.custom, e.detail.startDate, e.detail.endDate);
  };

  const startDateOnBlur = (value) => {
    if (!value || moment(new Date(value)).isValid()) {
      updateDates(dateRangeTypes.custom, value, filter.before);
      setDisableSave(false);
    } else {
      setStartDateInvalid(true);
      setDisableSave(true);
    }
  };

  const endDateOnBlur = (value) => {
    if (!value || moment(new Date(value)).isValid()) {
      updateDates(dateRangeTypes.custom, filter.after, value);
      setDisableSave(false);
    } else {
      setEndDateInvalid(true);
      setDisableSave(true);
    }
  };

  const updateDates = (selectedDateFilter, after, before) => {
    setStartDateText(after || "");
    setStartDateInvalid(false);
    setEndDateText(before || "");
    setEndDateInvalid(false);
    applyFilter(selectedDateFilter, {
      after: after ? moment(new Date(after)) : null,
      before: before ? moment(new Date(before)) : null,
    });
  };

  const checkForValidDate = (str, setStr) => {
    const regex = new RegExp("^[0-9/]*$");
    if (regex.test(str)) {
      setStr(str);
      if (!str || moment(new Date(str)).isValid()) {
        setDisableSave(false);
      }
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div>
        {!hideSortOptions && (
          <li className="cps-divider cps-margin-left-16 cps-margin-right-16 cps-margin-top-4 cps-margin-bottom-4" />
        )}
        {map(dateRanges, (dateRange, dateRangeKey) => {
          return (
            <li
              key={dateRangeKey}
              style={{ width: "200px" }}
              className="cps-cursor-pointer"
            >
              <div
                className={`${styles.spreadContents} cps-margin-left-16 cps-margin-right-16 cps-padding-top-8 cps-padding-bottom-8`}
                onClick={() => applyFilter(dateRangeKey, dateRange.filter)}
                style={{ lineHeight: "20px" }}
              >
                <div
                  className={
                    filter && filter.filter_params === dateRangeKey
                      ? "cps-color-primary cp-wt-semibold"
                      : ""
                  }
                >
                  {dateRange.label}
                </div>
                <div style={{ minWidth: "25px", margin: "-3px" }}>
                  {filter && filter.filter_params === dateRangeKey && (
                    <i className="cps-color-primary cps-icon cps-icon-sm-check" />
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </div>
      <div
        className="cps-margin-left-16 cps-margin-right-16"
        style={{
          marginTop: hideSortOptions ? "0px" : "-76px",
          marginLeft: "24px",
        }}
      >
        <CprDateRangePicker
          startDate={filter.after}
          endDate={filter.before}
          events={{
            rangechange: handleRangeChange,
          }}
        />
        <div className="cps-form-group">
          <div style={{ display: "flex", whiteSpace: "nowrap" }}>
            <label
              style={{ alignSelf: "center" }}
              htmlFor="startDate"
              className="cps-margin-left-12 cps-margin-right-8"
            >
              Start date
            </label>
            <div
              className={`cps-margin-right-16 ${
                startDateInvalid ? "cps-has-error" : ""
              }`}
            >
              <input
                type="text"
                className="cps-form-control"
                value={startDateText}
                onChange={(e) =>
                  checkForValidDate(e.target.value, setStartDateText)
                }
                onBlur={(e) => startDateOnBlur(e.target.value)}
                placeholder={defaultDateFormat}
              />
              <span className="cps-error-block">Invalid date</span>
            </div>
          </div>
          <div style={{ display: "flex", whiteSpace: "nowrap" }}>
            <label
              style={{ alignSelf: "center" }}
              htmlFor="endDate"
              className="cps-margin-left-12 cps-margin-right-12"
            >
              End date
            </label>
            <div
              className={`cps-margin-right-16 ${
                endDateInvalid ? "cps-has-error" : ""
              }`}
            >
              <input
                type="text"
                className="cps-form-control cps-margin-top-8 cps-margin-bottom-8"
                value={endDateText}
                onChange={(e) =>
                  checkForValidDate(e.target.value, setEndDateText)
                }
                onBlur={(e) => endDateOnBlur(e.target.value)}
                placeholder={defaultDateFormat}
                disabled={!startDateText || startDateInvalid}
              />
              <span className="cps-error-block">Invalid date</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DateRangeFilter.propTypes = {
  applyFilter: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  dateRanges: PropTypes.object.isRequired,
};

export default DateRangeFilter;
