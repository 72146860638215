// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cp-table-footer-styles__footer--dA0iE {\n  height: 6.4rem;\n  background-color: var(--cp-color-table-bg);\n  padding-top: 1.6rem;\n  padding-bottom: 1.6rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://src/components/cp-table/sub-components/cp-table-footer.styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,0CAA0C;EAC1C,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".footer {\n  height: 6.4rem;\n  background-color: var(--cp-color-table-bg);\n  padding-top: 1.6rem;\n  padding-bottom: 1.6rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "cp-table-footer-styles__footer--dA0iE"
};
export default ___CSS_LOADER_EXPORT___;
