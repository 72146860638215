import React from "react";
import { func, string } from "prop-types";
import { CpIcon } from "@components";
import { a } from "kremling";

CpPill.propTypes = {
  text: string,
  onRemove: func,
};

export function CpPill(props) {
  const { className, disabled, teamMember, onRemove, text } = props;

  return (
    <div
      className={a("cp-pill cp-ellipsis")
        .a(className)
        .m("cp-pr-8", !onRemove)
        .m(`cp-pill-green`, teamMember)
        .m("cp-pill--disabled", disabled)}
    >
      <span>{text}</span>
      {onRemove && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onRemove();
          }}
          type="button"
          disabled={disabled}
        >
          <CpIcon
            name="close-small"
            fill={`${
              teamMember
                ? "var(--cp-color-pill-team-text)"
                : "var(--cps-color-pill-text)"
            }`}
          />
        </button>
      )}
    </div>
  );
}
