import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CpButton, CpCard } from "@components";
import { useForm } from "react-hook-form";
import { SortController } from "../common/sort/sort-controller.component";
import { conditionOpts } from "./conditional-select-filter.helper";
import { ConditionController } from "./condition-controller.component";
import { isEmpty } from "lodash";
import { FilterFooter } from "../common/filter-footer.component";
import { catchError } from "auto-trace";

export function ConditionalSelectFilter({
  columnSchema,
  onClose,
  filterControl,
  getSelectionOptions,
  getSavedFilterValues,
  hideConditionOptions,
  hideSecondaryCondition,
}) {
  const maxConditions = hideSecondaryCondition ? 1 : 2;
  const filterData = filterControl.filters[columnSchema.id]?.data;
  const [extraConditions, setExtraConditions] = useState(
    () => filterData?.slice(1).map((data, i) => `conditions.${i + 1}`) || [],
  );
  const visibleConditionOpts = useMemo(() => {
    if (isEmpty(hideConditionOptions)) {
      return conditionOpts;
    } else {
      return conditionOpts.filter((o) => !hideConditionOptions.includes(o.id));
    }
  }, [hideConditionOptions]);

  const getConditionValueById = useCallback(
    (conditionId) => {
      if (!conditionId) return null;
      return (
        visibleConditionOpts.find((c) => c.id === conditionId) || {
          id: conditionId,
          name: conditionId,
        }
      );
    },
    [visibleConditionOpts],
  );

  const [initialOptions, setInitialOptions] = useState(null);
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      sort: filterControl.sortData[columnSchema.id]?.direction,
      conditions: {
        // We only support 2 conditions atm, otherwise we would iterate over filterData
        0: {
          condition:
            getConditionValueById(filterData?.[0]?.condition) ||
            visibleConditionOpts[0],
          filterValue: filterData?.[0]?.filterValue,
        },
        1: {
          condition: getConditionValueById(filterData?.[1]?.condition),
          filterValue: filterData?.[1]?.filterValue,
          operator: filterData?.[1]?.operator,
        },
      },
    },
  });

  const filterValuesAreIds =
    filterData?.[0]?.filterValue?.length > 0 &&
    typeof filterData[0].filterValue[0] !== "object";
  useEffect(() => {
    // If an array of ids is passed instead of an id,name object then we need to map them to objects.
    // This happens because BE usually only stores ids in filter views and so FE doesn't know the names of the options.
    if (filterValuesAreIds && !!initialOptions) {
      const firstValue = filterData[0].filterValue;
      const secondValue = filterData[1]?.filterValue;
      const mapOption = (id) => {
        return initialOptions.find((opt) => opt.id === id) || { id, name: id };
      };
      reset(
        {
          conditions: {
            0: {
              condition: getConditionValueById(filterData?.[0]?.condition),
              filterValue: firstValue.map(mapOption),
            },
            ...(secondValue
              ? {
                  1: {
                    condition: getConditionValueById(
                      filterData?.[1]?.condition,
                    ),
                    filterValue: secondValue.map(mapOption),
                    operator: filterData?.[1]?.operator,
                  },
                }
              : {}),
          },
        },
        { keepDefaultValues: true },
      );
    }
  }, [
    initialOptions,
    filterValuesAreIds,
    filterData,
    reset,
    getConditionValueById,
  ]);

  function onSubmit(data) {
    const filterData = Object.keys(data.conditions)
      .map((i) => {
        const { filterValue, condition } = data.conditions[i];
        if (!condition || (isEmpty(filterValue) && condition.validateHasValues))
          return null;
        return {
          filterValue,
          condition: condition.id,
          ...(i !== "0" ? { operator: "and" } : {}),
        };
      })
      .filter((d) => !!d);
    filterControl.applyFilter(columnSchema.id, {
      filterData,
      sortDir: data.sort,
    });
    onClose();
  }

  function onClear() {
    reset({
      sort: null,
      conditions: {
        0: {
          condition: visibleConditionOpts[0],
        },
      },
    });
    setExtraConditions([]);
  }

  const filterControlRef = useRef();
  filterControlRef.current = filterControl;
  useEffect(() => {
    // Get the initial set of option objects to show in the select
    const getValues = async () => {
      // We need to support async and regular functions
      const result = await getSelectionOptions({
        search: "",
        filterControl: filterControlRef.current,
      });
      return result;
    };

    // Get the option objects for values saved on the filter
    const getSavedValues = async () => {
      if (!getSavedFilterValues) return Promise.resolve([]);
      const result = await getSavedFilterValues({
        filterValues: [
          ...(filterData?.[0]?.filterValue || []),
          ...(filterData?.[1]?.filterValue || []),
        ],
        filterControl: filterControlRef.current,
      });
      return result;
    };

    Promise.all([getValues(), getSavedValues()])
      .then(([values, savedValues]) => {
        if (!savedValues) {
          throw new Error("getSavedFilterValues must return an iterable");
        }
        setInitialOptions([...values, ...savedValues]);
      })
      .catch(catchError());
  }, [getSelectionOptions, getSavedFilterValues, filterData]);

  return (
    <CpCard>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CpCard.Body>
          {columnSchema.sort !== false && (
            <>
              <SortController
                control={control}
                dataType={columnSchema.dataType}
                ascLabel={columnSchema.sort.ascLabel}
                descLabel={columnSchema.sort.descLabel}
              />
              <div className="cp-divider" />
            </>
          )}
          <ConditionController
            conditionOptions={visibleConditionOpts}
            control={control}
            filterControlRef={filterControlRef}
            getSelectionOptions={getSelectionOptions}
            initialOptions={initialOptions || []}
            name="conditions.0"
          />
          {extraConditions.length > 0 && (
            <div className="flex flex-col cp-mt-16 cp-gap-16">
              {extraConditions.map((name) => (
                <ConditionController
                  conditionOptions={visibleConditionOpts}
                  control={control}
                  filterControlRef={filterControlRef}
                  getSelectionOptions={getSelectionOptions}
                  initialOptions={initialOptions || []}
                  key={name}
                  name={name}
                  onRemove={() =>
                    setExtraConditions((val) => val.filter((n) => n !== name))
                  }
                  secondaryCondition
                />
              ))}
            </div>
          )}
          {extraConditions.length + 1 < maxConditions && (
            <CpButton
              aria-label="Add condition"
              btnType="tertiary"
              className="cp-mt-16"
              icon="add-small"
              onClick={() => {
                if (extraConditions.length + 1 < maxConditions) {
                  setExtraConditions((val) => [
                    ...val,
                    "conditions." + (val.length + 1),
                  ]);
                }
              }}
              type="button"
            >
              Add condition
            </CpButton>
          )}
        </CpCard.Body>
        <FilterFooter onCancel={onClose} onClear={onClear} />
      </form>
    </CpCard>
  );
}
