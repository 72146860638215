import React from "react";
import color from "color";
import styles from "./colorpicker.styles.css";
import {
  lightness,
  saturation as defaultSaturation,
} from "./colorpicker.helper.js";

export default class ColorBar extends React.Component {
  render() {
    const [selectedHue, selectedSaturation] = this.props.color.hsl().color;
    const hues = this.props.monochromatic
      ? [
          selectedHue,
          selectedHue,
          selectedHue,
          selectedHue,
          selectedHue,
          selectedHue,
          selectedHue,
        ]
      : [0, 60, 120, 180, 240, 300, 360];
    const lightnessArr = this.props.monochromatic
      ? [
          100,
          (5 / 6) * 100,
          (4 / 6) * 100,
          (3 / 6) * 100,
          (2 / 6) * 100,
          (1 / 6) * 100,
          0,
        ]
      : [
          lightness,
          lightness,
          lightness,
          lightness,
          lightness,
          lightness,
          lightness,
        ];
    // Gray scale monochromatic mode requires the saturation always to be 0.
    const saturation =
      this.props.monochromatic && selectedSaturation === 0
        ? 0
        : defaultSaturation;

    const colorGrad = [
      `hsl(${hues[0]}, ${saturation}%, ${lightnessArr[0]}%) 0%`,
      `hsl(${hues[1]}, ${saturation}%, ${lightnessArr[1]}%) 16%`,
      `hsl(${hues[2]}, ${saturation}%, ${lightnessArr[2]}%) 33%`,
      `hsl(${hues[3]}, ${saturation}%, ${lightnessArr[3]}%) 50%`,
      `hsl(${hues[4]}, ${saturation}%, ${lightnessArr[4]}%) 66%`,
      `hsl(${hues[5]}, ${saturation}%, ${lightnessArr[5]}%) 83%`,
      `hsl(${hues[6]}, ${saturation}%, ${lightnessArr[6]}%) 100%`,
    ];

    return (
      <div
        style={{
          height: this.props.barHeight + "px",
          background: `linear-gradient(to right, ${colorGrad[0]}, ${colorGrad[1]}, ${colorGrad[2]}, ${colorGrad[3]}, ${colorGrad[4]}, ${colorGrad[5]}, ${colorGrad[6]})`,
        }}
        ref={(ref) => (this.bar = ref)}
        onClick={(e) => {
          const rect = this.bar.getBoundingClientRect();
          const hue = Math.floor(((e.clientX - rect.left) / rect.width) * 360);
          this.props.barClick(
            color(`hsl(${hue}, ${saturation}%, ${lightness}%)`),
          );
        }}
        className={`${styles.bar}`}
      ></div>
    );
  }
}
