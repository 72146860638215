import React, { useMemo } from "react";
import { a } from "kremling";
import { useHighlighter } from "@hooks";
import { ItemBase } from "./item-base";
import { CpIcon, CpNameChip } from "@components";

export function ItemMulti({ isSelected, item, value, onChange, searchValue }) {
  const isChecked = useMemo(() => {
    return value.findIndex((v) => v.id === item.id) > -1;
  }, [value, item]);

  const isDisabled = item.disabled;
  const onCheckBoxChange = () => {
    if (!isDisabled) {
      onChange(item, true);
    }
  };

  const name = useHighlighter(item.name, searchValue);
  const subName = useHighlighter(item.subName, searchValue);

  return (
    <ItemBase
      id={item.id}
      isSelected={isSelected}
      className={a("item-multi")
        .m("item-multi--selected", isSelected)
        .m("item-multi--disabled", isDisabled)}
      subHeader={item.subHeader}
      onClick={onCheckBoxChange}
    >
      <div className="item-multi__display-content">
        <div className="cp-flex-center">
          {item.icon && (
            <CpIcon
              className="cp-mr-8"
              name={item.icon}
              fill={item.iconColor || "var(--cp-color-app-icon)"}
            />
          )}
          {item.namechip && (
            <CpNameChip className="cp-mr-8" size="medium" {...item.namechip} />
          )}
          <div className="cp-ellipsis">
            {item.overline && (
              <div className="item-multi__display-sub cp-ellipsis">
                {item.overline}
              </div>
            )}
            <div className="item-multi__display">{name}</div>
            {item.subName && (
              <div className="item-multi__display-sub cp-ellipsis">
                {subName}
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <label className="cps-checkbox">
          <input
            tabIndex="-1"
            type="checkbox"
            checked={isChecked}
            disabled={isDisabled}
            readOnly
          />
          <span />
        </label>
      </div>
    </ItemBase>
  );
}
