export const canopyPhone = "1-855-616-7305";
export const licenseMap = {
  practiceManagement: "Practice Management",
  practiceManagementPro: "Practice Management Professional",
  firmManagement: "Firm Management - Workflow & Advanced Reporting",
  taxResolution: "Tax Resolution",
  irsNotices: "Notices",
  transcripts: "Transcripts & Notices",
  workflow: "Workflow",
  documentManagement: "Document Management",
  clientManagement: "Client Management",
  timeAndBilling: "Time & Billing",
  clientManagementReportingV1: "Client Management Pro",
  timeAndBillingReportingV1: "Time & Billing Pro",
  workflowReportingV1: "Workflow Pro",
  documentManagementReportingV1: "Document Management Pro",
};
export const pdfTronLicenseKey =
  "Canopy Tax Inc.:OEM:Canopy::B+:AMS(20260429):9467B3131F5794D0933352184F516F2F2292FC45F5F74D09426026DE5AF2B6F5C7";
