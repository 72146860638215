import React from "react";
import moment from "moment";
import { reverse } from "lodash";
import { defaultDateFormat } from "./cps-datepicker.helpers.js";
import Calendar from "./cps-calendar.component.js";

export class CprDateRangePicker extends React.Component {
  static defaultProps = {
    format: defaultDateFormat,
  };
  constructor(props) {
    super(props);

    const startDate = props.startDate
      ? moment(props.startDate, props.format)
      : null;
    const endDate = props.endDate ? moment(props.endDate, props.format) : null;

    this.state = {
      dateRange: [startDate, endDate],
    };
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate, format } = this.props;
    if (prevProps.startDate !== startDate || prevProps.endDate !== endDate) {
      this.setState(
        () => ({
          dateRange: [
            startDate ? moment(startDate, format) : null,
            endDate ? moment(endDate, format) : null,
          ],
        }),
        this.reorderDatesIfNeeded,
      );
    }
  }

  render() {
    const { startDate, endDate } = this.getDateRangeObject();
    return (
      <Calendar
        width={this.props.width}
        selectDate={this.dateSelected}
        date={startDate}
        endDate={endDate}
      />
    );
  }

  dateSelected = (selectedDate) => {
    const { dateRange } = this.state;
    this.setState((prevState) => {
      if (!dateRange[0] || (dateRange[0] && dateRange[1])) {
        // If the dateRange is empty, or if we have values for both start and end date, reset the array and set the new value as the start date
        return {
          dateRange: [selectedDate],
        };
      } else {
        // Otherwise, set the new value as the end date
        return {
          dateRange: [prevState.dateRange[0], selectedDate],
        };
      }
    }, this.reorderDatesIfNeeded);
  };

  reorderDatesIfNeeded = () => {
    const { startDate, endDate } = this.getDateRangeObject();

    if (startDate && startDate.isAfter(endDate)) {
      this.flipDates();
    } else {
      this.dispatchChangeEvent();
    }
  };

  flipDates = () => {
    this.setState(
      (prevState) => ({
        dateRange: reverse(prevState.dateRange),
      }),
      this.dispatchChangeEvent,
    );
  };

  dispatchChangeEvent = () => {
    const { startDate, endDate } = this.getDateRangeObject();
    const { format } = this.props;
    if (this.props.events) {
      setTimeout(() => {
        this.props.events.rangechange?.({
          detail: {
            startDate: startDate ? startDate.format(format) : "",
            endDate: endDate ? endDate.format(format) : "",
          },
        });
      });
    }
  };

  getDateRangeObject = () => {
    const [startDate, endDate] = this.state.dateRange;

    return { startDate, endDate };
  };
}
