import React from "react";
import {
  bool,
  func,
  node,
  object,
  oneOfType,
  string,
  number,
  oneOf,
} from "prop-types";
import { range } from "@prop-types";

import { DrawerContainerComponent } from "./drawer-container.component";
import { DrawerBody } from "./components/drawer-body.component";
import { forceRange } from "../cp-modal/modal.utils";
import { drawerWidthMax, drawerWidthMin } from "./drawer.utils";
import { CpModal } from "@components";

export function CpDrawer({
  children,
  styles,
  top = 0,
  bottom,
  width = 320,
  position = "right",
  ...props
}) {
  if (top !== 0 && bottom !== undefined) {
    console.warn(
      "Both 'top' and 'bottom' props were provided to the CpDrawer. Only one can be used; the 'top' value will be preferred.",
    );
  }
  // If both top and bottom are defined, prioritize top; otherwise, use bottom if defined, or default to top if only top is provided.
  const verticalPosition =
    !top && bottom !== undefined
      ? { bottom: `${bottom}px` }
      : { top: `${top}px` };

  return (
    <DrawerContainerComponent
      {...props}
      styles={{
        width: `${forceRange(width, drawerWidthMin, drawerWidthMax) / 10}rem`,
        maxHeight: `calc(100vh - ${top + 48}px)`,
        position: "absolute",
        right: "0",
        margin: "2.4rem",
        ...(position === "right" ? { right: "0" } : { left: "80px" }),
        ...verticalPosition,
        ...styles,
      }}
      position={position}
      animScale={0.92}
      visibleBackdrop={false}
    >
      {children}
    </DrawerContainerComponent>
  );
}

CpDrawer.Header = CpModal.Header;
CpDrawer.Body = DrawerBody;
CpDrawer.Footer = CpModal.Footer;

CpDrawer.propTypes = {
  /** Bottom position of drawer. */
  bottom: number,
  /** Content displayed in drawer. */
  children: node,
  /** Custom classes to apply to drawer. */
  className: oneOfType([object, string]),
  /** Callback called when `show` is set to false. */
  onClose: func.isRequired,
  /** Callback called after closing animation finishes. */
  onAfterClose: func,
  /** Callback called after opening animation finishes. */
  onAfterOpen: func,
  /** Show/hide the component. */
  show: bool.isRequired,
  /** Custom styles to pass to drawer. */
  styles: object,
  /** Top position of drawer. */
  top: number,
  /** Width of drawer (any number between `424` and `320`. */
  width: range(drawerWidthMin, drawerWidthMax),
  /** The horizontal position of the drawer. */
  position: oneOf(["left", "right"]),
};
