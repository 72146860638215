import React from "react";
import styles from "./cps-tab.styles.css";
import { navigateToUrl } from "single-spa";
import PropTypes from "prop-types";
import { CprIcon } from "../";

// Exported only for testing purposes. Shouldn't be used outside of canopy-styleguide project
export class CpsTab extends React.Component {
  static defaultProps = {
    name: "",
    tabIndex: 0,
    icon: "",
    activeTabIndex: 0,
    customClassName: "",
  };
  static propTypes = {
    /**
     * The name of the tab
     */
    name: PropTypes.string,
    /**
     * The index of the tab
     */
    tabIndex: PropTypes.number,
    /**
     * The legacy font based icon name to show an icon in the tab
     */
    icon: PropTypes.string,
    /**
     * The legacy CpsIcon name to show an icon in the tab
     */
    iconName: PropTypes.string,
    /**
     * The active tab index
     */
    activeTabIndex: PropTypes.number,
    /**
     * The custom class name
     */
    customClassName: PropTypes.string,
    /**
     * The href of the tab
     */
    href: PropTypes.string,
    /**
     * The events object contains a tabClicked callback
     */
    events: PropTypes.shape({
      tabclicked: PropTypes.func,
    }),
  };

  render() {
    return (
      <div
        className={`${styles.tabContainer} ${
          this.props.tabIndex === this.props.activeTabIndex
            ? styles.tabActive
            : ""
        } ${this.props.customClassName}`}
        onClick={this.handleClick}
        onKeyDown={this.handleKeyboardClick}
        tabIndex="0"
        role={this.props.href ? "link" : "button"}
        href={this.props.href}
      >
        {this.props.icon && (
          <i
            className={`cps-icon cps-icon-${this.props.icon} cps-margin-right-16`}
          />
        )}
        {this.props.iconName && (
          <CprIcon name={this.props.iconName} className="cps-margin-right-8" />
        )}
        {this.props.name}
      </div>
    );
  }
  handleKeyboardClick = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      this.handleClick();
    }
  };
  handleClick = () => {
    this.props.events?.tabclicked?.({
      detail: this.props.tabIndex,
    });
    if (this.props.href) {
      navigateToUrl(this.props.href);
    }
  };
}

export const CprTab = CpsTab;
