import React, { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import { CpSelectMultiPills } from "@components";
import { FilterCondition } from "../common/filter-condition.component";
import { useDebounce } from "../../../../hooks/use-debounce.hook";
import { isEmpty } from "lodash";

export function ConditionController({
  conditionOptions,
  control,
  filterControlRef,
  getSelectionOptions,
  initialOptions,
  name,
  onRemove,
  secondaryCondition,
}) {
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const debounceSearch = useDebounce(
    (value) => setDebouncedSearch(value),
    500,
    {},
    [],
  );

  const [selectOpen, setSelectOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(initialOptions);
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
    shouldUnregister: true,
  });

  function onSearch(val) {
    setSearch(val);
    debounceSearch(val.trim());
  }

  const clearDisabledInput = (condition, value) => {
    return !condition?.validateHasValues ? undefined : value?.filterValue;
  };

  useEffect(() => {
    if (!selectOpen || isEmpty(debouncedSearch)) {
      setOptions(initialOptions);
      return;
    }

    const getOptions = async () => {
      // We need to support async and regular functions
      const result = await getSelectionOptions({
        search: debouncedSearch,
        filterControl: filterControlRef.current,
      });
      return result;
    };
    setIsLoading(true);
    getOptions()
      .then((result) => {
        setOptions(result);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    getSelectionOptions,
    debouncedSearch,
    selectOpen,
    initialOptions,
    filterControlRef,
  ]);

  return (
    <div>
      {secondaryCondition && <div className="cp-mb-8 cp-wt-semibold">And</div>}
      <FilterCondition
        options={conditionOptions}
        onChange={(condition) =>
          onChange({
            ...value,
            condition,
            filterValue: clearDisabledInput(condition, value),
          })
        }
        onRemove={onRemove}
        value={value?.condition}
      />
      <div>Select values</div>
      <CpSelectMultiPills
        autoFocus
        control={control}
        data={options}
        disabled={!value?.condition?.validateHasValues}
        loading={isLoading}
        placeholder="Select values"
        searchOnChange={onSearch}
        searchValue={search}
        onOpen={() => setSelectOpen(true)}
        onClose={() => setSelectOpen(false)}
        transformData={(item) => {
          return {
            ...item,
            id: item.id || item,
            name: item.name || item,
          };
        }}
        value={value?.filterValue || []}
        onChange={(filterValue) => onChange({ ...value, filterValue })}
      />
    </div>
  );
}
