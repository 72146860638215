import React, { forwardRef } from "react";
import { any, bool, object, string } from "prop-types";
import { CpInput } from "@components";

import { mergeRefs } from "@helpers";
import { useController } from "react-hook-form";
import { CpInputFieldEmail } from "./cp-input-field-email.component";
import { CpInputFieldUrl } from "./cp-input-field-url.component";
import { CpInputFieldPhoneNumber } from "./cp-input-field-phone-number.component";

export const CpInputField = forwardRef(function CpInputField(props, ref) {
  const {
    fieldName,
    control,
    controllerOptions,
    required,
    rules = {},
    ...cpInputProps
  } = props;
  let mRules = {};
  if (required) {
    mRules.required = "Field is required";
  }
  const mergedRules = { ...mRules, ...rules };

  const {
    field: { value = "", onChange, onBlur, ref: fieldRef },
    fieldState: { error },
  } = useController({
    name: fieldName,
    control,
    rules: mergedRules,
    ...controllerOptions,
  });

  return (
    <CpInput
      value={value}
      onChange={onChange}
      onBlur={(e) => {
        if (value) {
          onChange(value.trim());
        }
        onBlur(e);
      }}
      error={error?.message}
      required={required}
      ref={mergeRefs(ref, fieldRef)}
      {...cpInputProps}
    />
  );
});

CpInputField.propTypes = {
  control: any.isRequired,
  controllerOptions: object,
  fieldName: string.isRequired,
  rules: object,
  required: bool,
};

CpInputField.Email = CpInputFieldEmail;
CpInputField.Url = CpInputFieldUrl;
CpInputField.PhoneNumber = CpInputFieldPhoneNumber;
