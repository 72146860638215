// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cp-tabs-dynamic-styles__cptabs--Xxr0p {\n  overflow: auto;\n  scrollbar-width: none; /* Firefox */\n  height: 4rem;\n}\n\n.cp-tabs-dynamic-styles__cptabs--Xxr0p::-webkit-scrollbar {\n  display: none; /* Safari and Chrome */\n}\n\n.cp-tabs-dynamic-styles__innerList--qEaIF {\n  width: 100%;\n  position: relative;\n}\n\n.cp-tabs-dynamic-styles__virtualContainer--Cx94U {\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n", "",{"version":3,"sources":["webpack://src/components/cp-tabs-dynamic/cp-tabs-dynamic.styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,qBAAqB,EAAE,YAAY;EACnC,YAAY;AACd;;AAEA;EACE,aAAa,EAAE,sBAAsB;AACvC;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;AACT","sourcesContent":[".cptabs {\n  overflow: auto;\n  scrollbar-width: none; /* Firefox */\n  height: 4rem;\n}\n\n.cptabs::-webkit-scrollbar {\n  display: none; /* Safari and Chrome */\n}\n\n.innerList {\n  width: 100%;\n  position: relative;\n}\n\n.virtualContainer {\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cptabs": "cp-tabs-dynamic-styles__cptabs--Xxr0p",
	"innerList": "cp-tabs-dynamic-styles__innerList--qEaIF",
	"virtualContainer": "cp-tabs-dynamic-styles__virtualContainer--Cx94U"
};
export default ___CSS_LOADER_EXPORT___;
