// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sort-styles__sortContainer--i6L3F > .sort-styles__row--NG-3Z {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  height: 2.4rem;\n}\n.sort-styles__sortContainer--i6L3F > .sort-styles__row__selected--M-miv {\n  color: var(--cp-color-button-icon-active-text);\n}\n", "",{"version":3,"sources":["webpack://src/components/cp-table/filters/common/sort/sort.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,cAAc;AAChB;AACA;EACE,8CAA8C;AAChD","sourcesContent":[".sortContainer > .row {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  height: 2.4rem;\n}\n.sortContainer > .row__selected {\n  color: var(--cp-color-button-icon-active-text);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sortContainer": "sort-styles__sortContainer--i6L3F",
	"row": "sort-styles__row--NG-3Z",
	"row__selected": "sort-styles__row__selected--M-miv"
};
export default ___CSS_LOADER_EXPORT___;
