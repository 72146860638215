// @jsx React.createElement
import React from "react";
import { string, func, bool } from "prop-types";
import { isEqual, partial, unionWith } from "lodash";
import styles from "./cps-timezone.styles.css";
let momentTest = require("moment"); // eslint-disable-line
let moment;
if (momentTest.version !== "FAKE.FAKE.FAKE") {
  moment = require("moment-timezone/builds/moment-timezone-with-data-2012-2022.min.js");
}

const rowHeight = 36;
const momentTimezones =
  moment &&
  moment.tz.names().map((timezone) => ({ name: timezone, value: timezone }));
const emptyTimezone = { name: "", value: "" };

const initialTimezones = [
  { name: "Pacific Time", value: "America/Los_Angeles" },
  { name: "Mountain Time", value: "America/Denver" },
  { name: "Central Time", value: "America/Chicago" },
  { name: "Eastern Time", value: "America/New_York" },
  { name: "Arizona Time", value: "America/Phoenix" },
  { name: "Alaska Time", value: "America/Anchorage" },
  { name: "Hawaii Time", value: "Pacific/Honolulu" },
  { name: "Atlantic Time", value: "America/Halifax" },
  { name: "Newfoundland Time", value: "America/St_Johns" },
];

export class CprTimezone extends React.Component {
  static propTypes = {
    timezone: string,
    timezoneUpdated: func.isRequired,
    className: string,
    disabled: bool,
  };

  static defaultProps = {
    timezone: "",
    className: "",
    disabled: false,
  };

  constructor(props) {
    super(props);

    const initialTimezone = { name: props.timezone, value: props.timezone };

    this.state = {
      popupOpen: false,
      inputValue: this.props.timezone,
      selectedItem: initialTimezone,
      filteredTimezones: this.getFilteredTimezones(initialTimezone),
      activeMenuIndex: 0,
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutsideDialog, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutsideDialog, true);
  }

  handleClickOutsideDialog = (evt) => {
    if (
      this.clickOutsideBoundary &&
      !this.clickOutsideBoundary.contains(evt.target)
    ) {
      this.resetInputValue();
      this.closePopup();
    }
  };

  resetInputValue = () => {
    this.setState((prevState) => ({
      inputValue: prevState.selectedItem.name,
      filteredTimezones: this.getFilteredTimezones(prevState.selectedItem),
    }));
  };

  scrollDropdownToTop = () => {
    this.dropdownEl.scrollTop = 0;
  };

  openPopup = () => {
    this.scrollDropdownToTop();

    this.setState({
      activeMenuIndex: 0,
      popupOpen: true,
    });
  };

  closePopup = () => {
    this.setState({
      popupOpen: false,
    });
  };

  selectInputText = () => {
    this.inputEl.select();
  };

  optionSelected = (timezone) => {
    this.setState(
      {
        inputValue: timezone.name,
        selectedItem: timezone,
        filteredTimezones: this.getFilteredTimezones(timezone),
      },
      () => {
        this.selectInputText();
        this.closePopup();
      },
    );

    this.props.timezoneUpdated(timezone);
  };

  getFilteredTimezones = (timezone) =>
    unionWith([timezone], initialTimezones, isEqual).filter(
      (thisTimezone) => !isEqual(thisTimezone, emptyTimezone),
    );

  handleInputChange = (evt) => {
    this.openPopup();

    const theValue = evt.target.value;

    this.setState({
      filteredTimezones: theValue
        ? this.filterAllTimezones(theValue)
        : initialTimezones,
      inputValue: theValue,
    });
  };

  filterAllTimezones = (timezoneName) => {
    const allTimezones = [...initialTimezones, ...momentTimezones];

    return allTimezones.filter((timezone) =>
      timezone.name.toLowerCase().includes(timezoneName.toLowerCase()),
    );
  };

  scrollDropdownToItemIndex = (index) => {
    const minIndex = Math.min(this.state.filteredTimezones.length - 1, index);
    const maxIndex = Math.max(0, minIndex);
    const paddingAbove = 8;
    const pixelsAboveToCenter = rowHeight * 4 + paddingAbove;
    const liEl = this.dropdownEl.querySelector(
      `li[data-item-index="${maxIndex}"]`,
    );

    this.dropdownEl.scrollTop = Math.max(
      0,
      liEl.offsetTop - pixelsAboveToCenter,
    );
  };

  handleKeyDown = (evt) => {
    if (this.state.popupOpen) {
      if (evt.code === "Enter" || evt.keyCode === 13) {
        const activeItem = this.dropdownEl.querySelector(
          `li[data-item-index="${this.state.activeMenuIndex}"]`,
        );
        if (!activeItem || !activeItem.dataset) {
          //dont do anything if there are no results
          return;
        }

        this.optionSelected({
          name: activeItem.dataset.name,
          value: activeItem.dataset.value,
        });
        this.closePopup();
      }

      if (evt.code === "Tab" || evt.keyCode === 9) {
        this.resetInputValue();
        this.closePopup();
      }

      if (evt.code === "ArrowUp" || evt.keyCode === 38) {
        // arrow up
        this.setState(
          (prevState) => ({
            activeMenuIndex: Math.max(0, prevState.activeMenuIndex - 1),
          }),
          () => {
            this.scrollDropdownToItemIndex(this.state.activeMenuIndex);
          },
        );
      }

      if (evt.code === "ArrowDown" || evt.keyCode === 40) {
        // arrow down
        this.setState(
          (prevState) => ({
            activeMenuIndex: Math.min(
              prevState.activeMenuIndex + 1,
              this.state.filteredTimezones.length - 1,
            ),
          }),
          () => {
            this.scrollDropdownToItemIndex(this.state.activeMenuIndex);
          },
        );
      }
    }
  };

  render() {
    return (
      <span
        className={this.props.className}
        ref={(el) => {
          this.clickOutsideBoundary = el;
        }}
      >
        <input
          type="text"
          onChange={this.handleInputChange}
          value={this.state.inputValue}
          className={`cps-form-control`}
          onFocus={this.openPopup}
          onKeyDown={this.handleKeyDown}
          onClick={this.openPopup}
          ref={(el) => (this.inputEl = el)}
          disabled={this.props.disabled}
        />
        <div
          className={`cps-dropdown ${this.state.popupOpen ? "cps-open" : ""}`}
        >
          <ul
            className={`cps-dropdown-menu ${styles.dropdown}`}
            ref={(el) => (this.dropdownEl = el)}
          >
            {this.state.filteredTimezones.length === 0 ? (
              <li className={styles.dropdownEmptyState}>
                <div className={`${styles.dropdownEmptyHeader} cps-gray`}>
                  No result found
                </div>
                <div className={`${styles.dropdownEmptyBody} cps-gray2`}>
                  Please check spelling or try different keywords
                </div>
              </li>
            ) : (
              this.state.filteredTimezones.map((timezone, index) => (
                <li
                  key={timezone.name}
                  className={
                    index === this.state.activeMenuIndex ? "active" : ""
                  }
                  data-item-index={index}
                  data-name={timezone.name}
                  data-value={timezone.value}
                  onMouseOver={() => this.setState({ activeMenuIndex: index })}
                >
                  <a onClick={partial(this.optionSelected, timezone)}>
                    {timezone.name}
                  </a>
                </li>
              ))
            )}
          </ul>
        </div>
      </span>
    );
  }
}
